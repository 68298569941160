import axios from "../extension/axios";
import authService from "../services/authService";

const camerasApi = {
  getCameras: async () => {
    const request = axios
      .get(`${authService.getUnitUrl()}camera/credentials`)
      .then(function (response) {
        if (response == null) {
          console.log("No devices found!");
          return {
            camera_list: [],
            status: false,
            error: "No result returned by the unit!",
          };
        } else {
          const camera_list = response.data.map((camera) => {
            camera.action_types = camera.camera_action_types?.map(
              (actionType) => {
                return {
                  intent: actionType.intent_override,
                  originalIntent: actionType.intent_override,
                  name: actionType.action_type.name,
                  id: actionType.action_type.id,
                };
              }
            );

            return {
              ishealthy: camera.health,
              uuid: camera.id,
              ip: camera.ip,
              mac: camera.mac,
              name: camera.name,
              port: camera.port,
              role: camera.role,
              vendorName: camera.vendor_name,
              originalAction_types: camera.action_types,
              action_types: camera.action_types,
              additional_context: camera.additional_context
                ? camera.additional_context
                : "",
              loading: false,
            };
          });

          return { camera_list: camera_list, status: true };
        }
      })
      .catch(function (error) {
        console.log(error);
        const message =
          "There is a error getting the cameras, please see the browser console for more details!";
        return { camera_list: [], status: false, error: message };
      });

    return await request;
  },
  updateCamera: async (camera) => {
    const request = axios
      .put(`${authService.getUnitUrl()}camera/credentials/${camera.mac}`, {
        mac: camera.mac,
        name: camera.name,
        id: camera.uuid,
        role: camera.role,
        additional_context: camera.additional_context,
      })
      .then(function (response) {
        const camera = response.data;

        camera.action_types = camera.camera_action_types?.map((actionType) => {
          let result = actionType.action_type;
          result.intent = actionType.intent_override;
          result.originalIntent = actionType.intent_override;
          return result;
        });

        const result = {
          ishealthy: camera.health,
          uuid: camera.id,
          ip: camera.ip,
          mac: camera.mac,
          name: camera.name,
          port: camera.port,
          role: camera.role,
          url: camera.uri,
          vendorName: camera.vendor_name,
          originalAction_types: camera.action_types,
          action_types: camera.action_types,
          additional_context: camera.additional_context
            ? camera.additional_context
            : "",
          loading: false,
          camera_action_types: camera.camera_action_types,
        };

        return { result: result, status: true };
      })
      .catch(function (error) {
        console.log(error);
        const message =
          "There was an error, please see the browser console for more details!";
        return { status: false, error: message };
      });

    const result = await request;

    return result;
  },
  updateCameraActions: async (mac, action_types) => {
    const model = [
      ...new Set(
        action_types?.map((action) => {
          return {
            id: action.id,
            intent_override: action.intent.toLowerCase(),
          };
        })
      ),
    ];
    const request = axios
      .put(`${authService.getUnitUrl()}camera/action_types/${mac}`, model)
      .then(function (response) {
        return { result: response, status: true };
      })
      .catch(function (error) {
        console.log(error);
        const message =
          "There was an error saving the actions of the camera, please see the browser console for more details!";
        return { status: false, error: message };
      });

    const result = await request;

    return result;
  },
  addCameraActions: async (mac, action_types) => {
    const model = [
      ...new Set(
        action_types?.map((action) => {
          return {
            id: action.id,
            intent_override: action.intent.toLowerCase(),
          };
        })
      ),
    ];
    const request = axios
      .post(`${authService.getUnitUrl()}camera/action_types/${mac}`, model)
      .then(function (response) {
        return { result: response, status: true };
      })
      .catch(function (error) {
        console.log(error);
        const message =
          "There was an error saving the actions of the camera, please see the browser console for more details!";
        return { status: false, error: message };
      });

    const result = await request;

    return result;
  },
  deleteCameraActions: async (mac, action_types) => {
    const model = [
      ...new Set(
        action_types?.map((action) => {
          return {
            id: action.id,
            intent_override: action.intent.toLowerCase(),
          };
        })
      ),
    ];

    const request = axios
      .delete(`${authService.getUnitUrl()}camera/action_types/${mac}`, {
        data: model,
      })
      .then(function (response) {
        return { result: response, status: true };
      })
      .catch(function (error) {
        console.log(error);
        const message =
          "There was an error saving the actions of the camera, please see the browser console for more details!";
        return { status: false, error: message };
      });

    const result = await request;

    return result;
  },
  removeCamera: async (camera) => {
    const request = axios
      .delete(
        `${authService.getUnitUrl()}camera/credentials/${camera.mac}`,
        null
      )
      .then(function (response) {
        if (response == null) {
          return {
            status: false,
            error: "No result returned by the unit!",
          };
        } else {
          return { status: true };
        }
      })
      .catch(function (error) {
        console.log(error);
        const message =
          "There was an error, please see the browser console for more details!";
        return { status: false, error: message };
      });

    const result = await request;
    return result;
  },
  addCamera: async (camera) => {
    const request = axios
      .post(`${authService.getUnitUrl()}camera/credentials`, {
        ip: camera.ip,
        mac: camera.mac,
        name: camera.name,
        password: camera.password,
        port: camera.port,
        role: camera.role,
        username: camera.username,
        vendor_name: camera.vendor_name,
      })
      .then(function (response) {
        if (response == null) {
          return {
            camera_list: [],
            status: false,
            error: "No result returned by the unit!",
          };
        } else {
          const camera = response.data;

          camera.action_types = camera.camera_action_types?.map(
            (actionType) => {
              let result = actionType.action_type;
              result.intent = actionType.intent_override;
              result.originalIntent = actionType.intent_override;
              return result;
            }
          );

          const result = {
            ishealthy: camera.health,
            uuid: camera.id,
            ip: camera.ip,
            mac: camera.mac,
            name: camera.name,
            port: camera.port,
            role: camera.role,
            url: camera.uri,
            vendorName: camera.vendor_name,
            action_types: camera.action_types,
            originalAction_types: camera.action_types,
            additional_context: camera.additional_context
              ? camera.additional_context
              : "",
            loading: false,
            camera_action_types: camera.camera_action_types,
          };

          return { result, status: true };
        }
      })
      .catch(function (error) {
        console.log(error);
        const message =
          "There was an error, please see the browser console for more details!";
        return { camera_list: [], status: false, error: message };
      });

    return await request;
  },
  scanNetwork: async () => {
    const request = axios
      .get(`${authService.getUnitUrl()}camera/scan`)
      .then(function (response) {
        if (response == null) {
          return {
            scanCamera_list: [],
            status: false,
            error: "No Cameras found!",
          };
        } else {
          const result = response.data.map((camera) => {
            return {
              ...camera,
              status: null,
              username: "",
              password: "",
              name: "",
              loading: false,
              role: "",
              select: false,
            };
          });

          return { scanCamera_list: result, status: true };
        }
      })
      .catch(function (error) {
        console.error(error);
        const message =
          "There was an error, please see the browser console for more details!";
        return { scanCamera_list: [], status: false, error: message };
      });

    return await request;
  },
  restartServices: async () => {
    const response = axios
      .post(`${authService.getUnitUrl()}services/restart`, null)
      .then(function (response) {
        console.log("services/restart:", response.data);
        return;
      })
      .catch(function (error) {
        console.error(error);
      });

    return await response;
  },
};

export default camerasApi;
