import React, { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import moment from "moment";
import { Select } from "antd";
import {
  BarChart,
  Bar,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  Customized,
  Rectangle,
} from "recharts";

import analyticsApi from "../../api/analytics";
import {
  SelectTimePeriodOptions,
  getAPIParameters,
} from "../../utils/analyticsDateUtils";

export default function ActionsBarChart({ selectedDate }) {
  const actionTypes = useSelector((state) => state.lookup.actionTypes);
  const businessHours = useSelector(
    (state) => state.unit.unit.settings.businessHour
  );

  const [chartData, setChartData] = useState([]);
  const [timePeriod, setTimePeriod] = useState(null);
  const [selectedAction, setSelectedAction] = useState(null);

  const getAPIParametersCallback = useCallback(
    (period) => getAPIParameters(selectedDate)(period),
    [selectedDate]
  );

  useEffect(() => {
    const fetchData = async () => {
      if (timePeriod) {
        const params = getAPIParametersCallback(timePeriod);

        if (timePeriod === "Today" && businessHours) {
          let openingHour = moment(businessHours.opens, "HH:00:00").toDate();
          let closingHour = moment(businessHours.closes, "HH:00:00").toDate();

          params.startDate.setHours(openingHour.getHours());
          params.endDate.setHours(closingHour.getHours() + 1);
        }

        let response = await analyticsApi.getActionChartData(
          params.startDate,
          params.endDate,
          params.timePeriod
        );

        if (response.status) {
          setChartData(response.data);
        }
      }
    };

    fetchData();
  }, [selectedAction, timePeriod, getAPIParametersCallback, businessHours]);

  const handleTimePeriodChange = (timePeriod) => {
    setTimePeriod(timePeriod);
  };

  const handleSelectedActionChange = (actionID) => {
    setSelectedAction(actionID);
  };

  const EmptyStateMessage = () => (
    <g>
      <text
        x="50%"
        y="45%"
        textAnchor="middle"
        dominantBaseline="middle"
        fill="#B0B0B0"
        fontSize="18px"
        fontWeight={300}
      >
        Please select an action and time period.
      </text>
    </g>
  );

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      let data = payload[0].payload;

      return (
        <div className="text-left rounded-md shadow-md bg-neutral-600 pl-3 pr-8 py-1">
          <p className="text-guardis-300">{`${label}`}</p>
          <p className="text-neutral-100 font-medium">{`Action Count: ${data.count}`}</p>
          <p className="text-neutral-100 font-medium">{`Percentage: ${data.percentage}%`}</p>
        </div>
      );
    }

    return null;
  };

  const CustomBarShape = (props) => {
    const { x, y, width, height } = props;

    return (
      <g>
        <defs>
          <linearGradient
            id={"barGradient"}
            x1="0%"
            y1="0%"
            x2="100%"
            y2="100%"
          >
            <stop offset="25%" stopColor="#4ade80" />
            <stop offset="100%" stopColor="#22c55e" />
          </linearGradient>
        </defs>
        <Rectangle
          x={x}
          y={y}
          width={width}
          height={height}
          fill="url(#barGradient)"
          radius={[width / 3, width / 3, 0, 0]}
        />
      </g>
    );
  };

  return (
    <div className="card flex flex-col flex-1 gap-4 px-8 py-6">
      <h2>Actions Percentage</h2>
      <div className="h-fit flex flex-1 flex-row gap-4">
        <div className="h-fit flex flex-row flex-wrap items-center gap-2">
          <h3 className="w-14">Action</h3>
          <Select
            className="w-full min-w-60"
            fieldNames={{ label: "name", value: "id" }}
            placeholder="Select Action"
            value={selectedAction}
            options={actionTypes}
            showSearch
            allowClear={false}
            onChange={handleSelectedActionChange}
          />
        </div>
        <div className="h-fit flex flex-row flex-wrap items-center gap-2">
          <h3 className="w-14">Period</h3>
          <Select
            className="w-full min-w-60"
            placeholder="Select Time Period"
            value={timePeriod}
            options={SelectTimePeriodOptions}
            onChange={handleTimePeriodChange}
          />
        </div>
      </div>

      <ResponsiveContainer width="100%" height={400}>
        <BarChart
          data={selectedAction && timePeriod ? chartData[selectedAction] : null}
        >
          {!selectedAction && !timePeriod && (
            <Customized component={<EmptyStateMessage />} />
          )}
          {selectedAction && timePeriod && (
            <Tooltip cursor={{ fill: "#f1f8f3" }} content={<CustomTooltip />} />
          )}
          <CartesianGrid
            stroke="#B0B0B0"
            strokeDasharray="4"
            strokeWidth={1}
            vertical={false}
          />
          <XAxis dataKey="label" />
          <YAxis
            dataKey="percentage"
            stroke="#B0B0B0"
            strokeDasharray="4"
            domain={[0, 100]}
            width={1}
            tick={false}
            tickMargin={0}
            tickLine={false}
          />

          <YAxis
            yAxisId="right-y-axis-actions-bar"
            orientation="right"
            dataKey="percentage"
            stroke="#B0B0B0"
            strokeDasharray="4"
            domain={[0, 100]}
            width={1}
            tick={false}
            tickMargin={0}
            tickLine={false}
          />

          <Bar
            dataKey="percentage"
            maxBarSize={50}
            shape={<CustomBarShape />}
            label={{
              formatter: (value) => Number(value.toFixed(0)) + "%",
              position: "top",
              fill: "#72c490",
              fontWeight: "600",
            }}
          />
        </BarChart>
      </ResponsiveContainer>
    </div>
  );
}
