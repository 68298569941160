import axios from "axios";

const authApi = {
  loginUser: async (email, password) => {
    const request = axios
      .post(`${process.env.REACT_APP_AWS_COGNITO_URL}/login`, {
        email: email,
        password,
      })
      .then(function (response) {
        if (response == null) {
          throw new Error("Failed to authenticate");
        }
        const data = response.data;
        return data;
      })
      .catch(function (error) {
        const newError = error.response != null?  {message : error.response.data}: error;
        console.log(newError);
        throw newError;
      });

    return await request;
  },
  createNewUser: async (user) => {
    var response = axios
      .post(`${process.env.REACT_APP_AWS_COGNITO_URL}/register`, user)
      .then(function (response) {
        if (response == null) {
          throw new Error("Failed to authenticate");
        }
        const data = response.data;
        return data;
      })
      .catch(function (error) {
        const newError = error.response != null?  {message : error.response.data}: error;
        console.log(newError);
        throw newError;
      });
    return await response;
  },
};

export default authApi;
