import { createSlice } from "@reduxjs/toolkit";

const errorSlice = createSlice({
  name: "error",
  initialState: {
    errorMessage: "",
    openErrorModal: false,
  },
  reducers: {
    setErrorMessage(state, action) {
      state.errorMessage = action.payload;
    },
    openModal(state) {
      state.openErrorModal = true;
    },
    closeModal(state) {
      state.openErrorModal = false;
      state.errorMessage = "";
    },
  },
});

export const errorAction = errorSlice.actions;
export default errorSlice;
