import React, { useRef,useState } from "react";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import DisabledByDefaultIcon from "@mui/icons-material/DisabledByDefault";
import { green, red } from "@mui/material/colors";
import { useDispatch, useSelector } from "react-redux";
import {
  addCamera,
  setLoadingInScanCamera,
  setSelectScanCamera,
  setSelectScanCameraAll
} from "../../store/camera/cameraAction";
import ClipLoader from "react-spinners/ClipLoader";
import CameraCredentialsModal from "../CameraCredentialsModal";
import { openModal, setErrorMessage } from "../../store/error/errorAction";
import InfoOutlined from "@mui/icons-material/InfoRounded";
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import InfoSharpIcon from "@mui/icons-material/InfoSharp";

export default function ScanResult() {
  const dispatch = useDispatch();
  const [popoverMessage, setPopoverMessage] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const scanCameraList = useSelector((state) => state.camera.scanCameraList);
  const cameraCredentialsModal = useRef();
  const selectScanCameraAll = useSelector(
    (state) => state.camera.selectScanCameraAll
  );

  const handleSelectAllCheckboxChange = (checked) => {
    scanCameraList.forEach((camera, index) => {
      dispatch(setSelectScanCamera(index, checked));
    });

    dispatch(setSelectScanCameraAll(checked));
  };

  const handleCameraCheckboxChange = async (checked, index) => {
    dispatch(setSelectScanCamera(index, checked));
  };

  const handleCredentialsClick = () => {
    const isAnyItemSelected = scanCameraList.some((item) => item.select);

    if (isAnyItemSelected) {
      cameraCredentialsModal.current.openModal();
    } else {
      dispatch(setErrorMessage("At least one camera need to be selected"));
      dispatch(openModal());
    }
  };

  const handleAddNewCameraCallback = (result) => {
    if (result.isOk) {
      scanCameraList.forEach((scanCamera, index) => {
        if (scanCamera.select && !scanCamera.status) {

          const camera = {...scanCamera};
          camera.password = result.password;
          camera.username = result.username;
         
          if (camera.name === "") {
            camera.name = "Generic";
          }

          dispatch(setLoadingInScanCamera(index, true));

          dispatch(addCamera(camera, true)).then((reult) => {
            dispatch(setLoadingInScanCamera(index, false));            
          });
        }
      });
    }
  };

  const handlePopoverOpen = (event, message) => {
    setPopoverMessage(message);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = (timeToBeDisplay = 0) => {
    setTimeout(() => {
      setAnchorEl(null);
    }, timeToBeDisplay);
  };

  return (
    <React.Fragment>
      <div className="w-[100%]  mt-2 ml-4  pb-6 mb-4">
        <div className="flex flex-row mt-4 font-bold">
          <div className="w-[50%] flex justify-end font-semibold text-navy">
            <h2>Cameras found:</h2>
          </div>
          <div className="w-[50%] flex justify-end">
            <button
              onClick={handleCredentialsClick}
              type="button"
              id="addCredentials"
              className="py-2 px-2 bg-gai-green rounded text-white font-semibold "
            >
              Add Camera
            </button>
          </div>
        </div>
        <div className="flex flex-row mt-4 text-white font-bold  border-black  bg-navy">
          <div className="w-[5%] flex justify-center">
            <input
              type="checkbox"
              className="pt-2"
              checked={selectScanCameraAll}
              onChange={(event) => {
                handleSelectAllCheckboxChange(event.target.checked);
              }}
            ></input>
          </div>
          <div className="w-[15%] font-semibold  py-2 px-1 text-center">
            IP
            <span className="pl-1">
              <InfoOutlined
                sx={{ fontSize: 15 }}
                className="bg-navy rounded-xl mb-1"
                onMouseEnter={(e) =>
                  handlePopoverOpen(e, "IP address of the camera!")
                }
                onMouseLeave={() => handlePopoverClose(5000)}
              />
            </span>
            </div>
          <div className="w-[10%] font-semibold  py-2 px-1 text-center">
            Port
            <span className="pl-1">
              <InfoOutlined
                sx={{ fontSize: 15 }}
                className="bg-navy rounded-xl mb-1"
                onMouseEnter={(e) =>
                  handlePopoverOpen(e, "IP address of the camera!")
                }
                onMouseLeave={() => handlePopoverClose(5000)}
              />
            </span>
          </div>
          <div className="w-[15%] font-semibold  py-2 px-1 text-center">
            Mac Address
            <span className="pl-1">
              <InfoOutlined
                sx={{ fontSize: 15 }}
                className="bg-navy rounded-xl mb-1"
                onMouseEnter={(e) =>
                  handlePopoverOpen(e, "Mac Address of the camera!")
                }
                onMouseLeave={() => handlePopoverClose(5000)}
              />
            </span>
          </div>
          <div className="w-[45%] font-semibold  py-2 px-1 text-center">
            Vendor
            <span className="pl-1">
              <InfoOutlined
                sx={{ fontSize: 15 }}
                className="bg-navy rounded-xl mb-1"
                onMouseEnter={(e) =>
                  handlePopoverOpen(e, "the brand of the camera!")
                }
                onMouseLeave={() => handlePopoverClose(5000)}
              />
            </span>
          </div>
          <div className="w-[15%] font-semibold  py-2 px-1 flex justify-center">
            Status
          </div>
        </div>
        <div className="border">
          {scanCameraList?.map((camera, i) => {
            return (
              <div
                className={`${
                  i % 2 === 0 ? "bg-gray-200" : "bg-white"
                } flex flex-row   border-none border-black `}
                key={i}
              >
                <div className="w-[5%] flex justify-center">
                  {!camera.status && (
                    <input
                      type="checkbox"
                      checked={camera.select}
                      onChange={(event) => {
                        handleCameraCheckboxChange(event.target.checked, i);
                      }}
                    ></input>
                  )}
                </div>
                <div className="w-[15%]  py-1 px-1 text-center">
                  {camera.ip}
                </div>
                <div className="w-[10%]  py-1 px-1 text-center">
                  {camera.port}
                </div>
                <div className="w-[15%]  py-1 px-1 text-center">
                  {camera.mac}
                </div>
                <div className="w-[45%]  py-1 px-1 text-center">
                  {camera.vendor_name}
                </div>

                <div className="w-[15%]  py-1 px-1 flex justify-center">
                  {camera.status != null && !camera.loading ? (
                    <span className="pl-4">
                      {!camera.status ? (
                        <DisabledByDefaultIcon sx={{ color: red[500] }} />
                      ) : (
                        <CheckCircleIcon color="success" />
                      )}
                    </span>
                  ) : camera.loading ? (
                    <span className="px-5 w-[17%]">
                      <ClipLoader
                        color={"#30ac64"}
                        loading={camera.loading}
                        size={20}
                        aria-label="Loading Spinner"
                      />
                    </span>
                  ) : (
                    <strong style={{ color: green[700] }}>New!</strong>
                  )}
                </div>
                <CameraCredentialsModal
                  ref={cameraCredentialsModal}
                  AddNewCamera={handleAddNewCameraCallback}
                ></CameraCredentialsModal>
              </div>
            );
          })}
        </div>
      </div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => handlePopoverClose(0)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Typography sx={{ p: 0.5, fontSize: 11 }}>
          <InfoSharpIcon
            sx={{ fontSize: 15, color: "#30ac64" }}
          ></InfoSharpIcon>
          {popoverMessage}
        </Typography>
      </Popover>
    </React.Fragment>
  );
}
