import { createSlice } from "@reduxjs/toolkit";

const notificationSlice = createSlice({
  name: "notification",
  initialState: {
    unreadCount: 0,
    allNotifications: [],
    notifications: [],
    totalOfNotification: 0,
    eventsToLoadedForPage: 1000,
    currentEventsLoded: 0,
    showLoadingNotification: false,
    camera_List: [],
    loadingAllBookmarks: false,
    allBookmarks: [],
  },
  reducers: {
    setCameraList(state, action) {
      const camera_List = action.payload.map((camera) => {
        return { mac: camera.mac, name: camera.name };
      });

      state.camera_List = camera_List;
    },
    setSelectedNotification(state, action) {
      state.notifications.forEach((notification) => {
        if (notification.clip_id !== action.payload) {
          notification.selectedEvent = false;
        } else {
          notification.selectedEvent = true;
        }
      });
    },
    setNotifications(state, action) {
      const notificationList = updateCameraNameInNotifications(
        action.payload,
        state.camera_List
      );

      if (notificationList) {
        state.totalOfNotification = notificationList.length;
        state.allNotifications = notificationList;

        if (notificationList.length >= state.eventsToLoadedForPage) {
          state.notifications = notificationList.slice(
            0,
            state.eventsToLoadedForPage
          );
          state.currentEventsLoded = state.eventsToLoadedForPage;
        } else {
          state.notifications = notificationList;
        }
      } else {
        state.notifications = [];
      }
    },
    getNextNotificatios(state) {
      let nextEnd = state.currentEventsLoded + state.eventsToLoadedForPage;

      if (nextEnd >= state.allNotifications.length) {
        nextEnd = state.allNotifications.length;
      }

      const nextEvents = state.allNotifications.slice(
        state.currentEventsLoded,
        nextEnd
      );

      state.currentEventsLoded = nextEnd;
      state.notifications = [...state.notifications, ...nextEvents];
    },
    loadMoreNotificatios(state, action) {
      const notificationsList = action.payload;

      let notificationsListDifference = state.allNotifications.filter(
        (event) => {
          return !notificationsList.find(
            (not) => not.clip_id === event.clip_id
          );
        }
      );

      if (notificationsListDifference.length) {
        state.allNotifications = [
          ...state.allNotifications,
          ...notificationsListDifference,
        ];

        if (notificationsListDifference.length > state.eventsToLoadedForPage) {
          notificationsListDifference = notificationsListDifference.slice(
            state.eventsToLoadedForPage
          );
        }

        state.notifications = [
          ...state.notifications,
          ...notificationsListDifference,
        ];
      }
    },
    setShowLoadingNotification(state, action) {
      state.showLoadingNotification = action.payload;
    },
    deleteNotification(state, action) {
      state.allNotifications = state.allNotifications.filter(
        (n) => n.clip_id !== action.payload
      );

      state.notifications = state.notifications.filter(
        (n) => n.clip_id !== action.payload
      );
    },
    updateNotifcationStatus(state, action) {
      const notification = action.payload;
      state.notifications.forEach((not) => {
        if (not.clip_id === notification.clip_id) {
          not.feedback_description = notification.feedback_description;
          not.user_feedback = action.payload.user_feedback;
          not.selectedEvent = false;
          not.actionEvents = notification.action_events;
        }
      });
    },
    clearNotifications(state) {
      state.allNotifications = [];
      state.notifications = [];
      state.totalOfNotification = 0;
      state.currentEventsLoded = 0;
    },
    updateBookmark(state, action) {
      state.notifications.forEach((notification) => {
        if (notification.clip_id === action.payload.clip_id) {
          notification.bookmark = action.payload.isBookmark;
        }
      });

      state.allBookmarks.forEach((bookmark) => {
        if (bookmark.clip_id === action.payload.clip_id) {
          bookmark.bookmark = action.payload.isBookmark;
        }
      });
    },
    setLoadingAllBookmarks(state, action) {
      state.loadingAllBookmarks = action.payload;
    },
    setAllBookmarks(state, action) {
      state.allBookmarks = action.payload;
    },
    clearAllBookmarks: (state) => {
      state.bookmarks = [];
    }   
  },
});

const updateCameraNameInNotifications = (notifications, cameraList) => {
  let notification_list = notifications.map((notification) => {
    let cameraname = "Generic";

    const camera = cameraList.find((c) => c.mac === notification.camera_id);
    cameraname = camera ? camera.name : cameraname;

    return { ...notification, cameraname };
  });

  return notification_list;
};

export const notificationAction = notificationSlice.actions;
export default notificationSlice;
