import axios from "../extension/axios";
import authService from "../services/authService";

const unitApi = {
  getUnit: async () => {
    const request = axios
      .get(`${authService.getUnitUrl()}settings`)
      .then(function (response) {
        const result = {
          editMode: false,
          loading: false,
          id: response.data.id,
          settings: response.data.settings,
        };
        return { result, status: true };
      })
      .catch(function (error) {
        console.log(error);
        const message =
          "There is a error getting unit settings, please see the browser console for more details!";
        return { status: false, error: message };
      });

    return await request;
  },
  updateUnitSettings: async (settings) => {
    const request = axios
      .put(`${authService.getUnitUrl()}settings`, {
        settings,
      })
      .then(function (response) {
        const result = {
          editMode: false,
          loading: false,
          id: response.data.id,
          settings: response.data.settings,
        };
        return { result, status: true };
      })
      .catch(function (error) {
        console.log(error);
        const message =
          "There was an error, please see the browser console for more details!";
        return { status: false, error: message };
      });

    const result = await request;

    return result;
  },
  setUnitLocation: async (settings) => {
    const request = axios
      .post(`${authService.getUnitUrl()}settings`, {
        settings,
      })
      .then(function (response) {
        const result = {
          editMode: false,
          loading: false,
          id: response.data.id,
          settings: response.data.settings,
        };
        return { result, status: true };
      })
      .catch(function (error) {
        console.log(error);
        const message =
          "There was an error, please see the browser console for more details!";
        return { status: false, error: message };
      });

    const result = await request;

    return result;
  },
};

export default unitApi;
