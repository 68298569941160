import { useDispatch, useSelector } from "react-redux";
import {
  setShowAlertMessage
} from "../../store/alert/alertAction";
import React, { useEffect, useState, useRef } from "react";

export default function AlertMessageBanner() {
  const spanRef = useRef(null);
  const dispatch = useDispatch();
  const [color, setColor] = useState({});
  const alertTitle = useSelector((state) => state.alert.alertTitle);
  const showAlertMessage = useSelector((state) => state.alert.showAlertMessage);
  const alertMessage = useSelector((state) => state.alert.alertMessage);
  const alertType = useSelector((state) => state.alert.alertType);

  useEffect(() => {
    switch (alertType) {
      case "danger":
        setColor("red");
        break;
      case "success":
        setColor("green");
        break;
      case "warning":
        setColor("yellow");
        break;
      case "info":
        setColor("blue");
        break;
      default:
        setColor("neutral");
        break;
    }
  }, [alertType]);

  const handleClose = () => {
    dispatch(setShowAlertMessage(false));
  };

  return (
    <div>
      {showAlertMessage && (
        <div
          className={`bg-${color}-100 border border-${color}-400 text-${color}-700 px-4 py-3 rounded relative m-1`}
          role="alert"
        >
          <strong className="font-bold">{alertTitle}</strong>
          <span
            ref={spanRef}
            className="block sm:inline ml-2"
            dangerouslySetInnerHTML={{ __html: alertMessage }}
          ></span>
          <span
            className="absolute top-0 bottom-0 right-0 px-4 py-3"
            onClick={handleClose}
          >
            <svg
              className={`fill-current h-6 w-6 text-${color}-500`}
              role="button"
              xmlns="http://www.w3.org/2000/svg"
              viewBox="0 0 20 20"
            >
              <title>Close</title>
              <path d="M14.348 14.849a1.2 1.2 0 0 1-1.697 0L10 11.819l-2.651 3.029a1.2 1.2 0 1 1-1.697-1.697l2.758-3.15-2.759-3.152a1.2 1.2 0 1 1 1.697-1.697L10 8.183l2.651-3.031a1.2 1.2 0 1 1 1.697 1.697l-2.758 3.152 2.758 3.15a1.2 1.2 0 0 1 0 1.698z" />
            </svg>
          </span>
        </div>
      )}
    </div>
  );
}
