import React, { useEffect, useCallback, useState } from "react";
import { useDispatch } from "react-redux";
import { Routes, Route, useLocation, Navigate, Outlet } from "react-router-dom";
import { ConfigProvider, theme } from "antd";

import { Navbar, MobileNavbar } from "./components/Navbar";
import Home from "./pages";
import Setup from "./pages/setup";
import Live from "./pages/live";
import Playback from "./pages/playback";
import Events from "./pages/events";
import Analytics from "./pages/analytics";
import LogIn from "./pages/log-in";
import SignOut from "./pages/signout";
import Bookmarks from "./pages/bookmarks";
import NotFound from "./pages/notFound";

import authService from "./services/authService";
import useWindowDimensions from "./utils/useWindowDimensions";
import ErrorMessageModal from "./components/ErrorMessageModal";
import AlertMessageModal from "./components/AlertMessageModal";
import AlertMessageBanner from "./components/AlertMessageBanner";
import IPCameraTroubleshootingGuide from "./components/IPCameraTroubleshootingGuide";

import "./App.css";

export default function App() {
  const { width } = useWindowDimensions();
  const [darkMode, setDarkMode] = useState(false);
  const windowQuery = window.matchMedia("(prefers-color-scheme:dark)");

  const antDesignTheme = {
    algorithm: darkMode ? theme.darkAlgorithm : theme.defaultAlgorithm,
    token: {
      fontFamily: "Figtree",
      sizeUnit: 5,
      colorPrimary: "#2dab64",
      colorInfo: "#2dab64",
    },
    components: {
      Anchor: {
        lineWidthBold: 4,
        linkPaddingBlock: 14,
      },
      Divider: {
        margin: 0,
        marginLG: 0,
      },
      Form: {
        itemMarginBottom: 16,
      },

      Popover: {
        sizePopupArrow: 20,
      },
      Segmented: {
        controlHeightLG: 48,
      },
      Table: {
        headerBorderRadius: 0,
        borderRadius: 0,
      },
    },
  };

  const changeTheme = useCallback((event) => {
    // change Ant Design color scheme
    setDarkMode(event.matches ? true : false);

    // change browswer color scheme
    document.documentElement.setAttribute(
      "data-color-scheme",
      event.matches ? "dark" : "light"
    );
  }, []);

  useEffect(() => {
    windowQuery.addEventListener("change", changeTheme);
    return () => {
      windowQuery.removeEventListener("change", changeTheme);
    };
  }, [windowQuery, changeTheme]);

  useEffect(() => {
    setDarkMode(windowQuery.matches ? true : false);
  }, [windowQuery]);

  if (width >= 768)
    return (
      <ConfigProvider theme={antDesignTheme}>
        <AlertMessageBanner />
        <Routes>
          <Route
            path="/"
            element={
              <div className="w-screen h-screen bg-app">
                <div className="app-left">
                  <Navbar />
                </div>
                <div className="app-right">
                  <Outlet />
                </div>
              </div>
            }
          >
            <Route
              path="/"
              exact
              element={
                <RequireAuth>
                  <Analytics />
                </RequireAuth>
              }
            />
            <Route
              path="/cameraHelp"
              element={
                <RequireAuth>
                  <IPCameraTroubleshootingGuide />
                </RequireAuth>
              }
            />
            <Route
              path="/home"
              exact
              element={
                <RequireAuth>
                  <Home />
                </RequireAuth>
              }
            />
            <Route
              path="/setup"
              element={
                <RequireAuth>
                  <Setup />
                </RequireAuth>
              }
            />
            <Route
              path="/live"
              element={
                <RequireAuth>
                  <Live />
                </RequireAuth>
              }
            />
            <Route
              path="/playback"
              element={
                <RequireAuth>
                  <Playback />
                </RequireAuth>
              }
            />
            <Route
              path="/events"
              element={
                <RequireAuth>
                  <Events />
                </RequireAuth>
              }
            />
            <Route
              path="/bookmarks"
              element={
                <RequireAuth>
                  <Bookmarks />
                </RequireAuth>
              }
            />
            <Route path="*" element={<NotFound />} />
          </Route>

          <Route path="/log-in" element={<LogIn />} />
          <Route path="/sign-out" element={<SignOut />} />
        </Routes>
        <ErrorMessageModal Title={"Oops! Something Went Wrong!"} />
        <AlertMessageModal />
      </ConfigProvider>
    );

  return (
    <div className="flex flex-col h-screen bg-app">
      <ConfigProvider theme={antDesignTheme}>
        <main className="w-full grow">
          <Routes>
            <Route
              path="/"
              element={
                <RequireAuth>
                  <Analytics />
                </RequireAuth>
              }
            />
            <Route
              path="/analytics"
              element={
                <RequireAuth>
                  <Analytics />
                </RequireAuth>
              }
            />
            <Route
              path="/home"
              exact
              element={
                <RequireAuth>
                  <Home />
                </RequireAuth>
              }
            />
            <Route
              path="/setup"
              element={
                <RequireAuth>
                  <Setup />
                </RequireAuth>
              }
            />
            <Route
              path="/live"
              element={
                <RequireAuth>
                  <Live />
                </RequireAuth>
              }
            />
            <Route
              path="/playback"
              element={
                <RequireAuth>
                  <Playback />
                </RequireAuth>
              }
            />
            <Route
              path="/events"
              element={
                <RequireAuth>
                  <Events />
                </RequireAuth>
              }
            />
            <Route
              path="/bookmarks"
              element={
                <RequireAuth>
                  <Bookmarks />
                </RequireAuth>
              }
            />
            <Route path="/log-in" element={<LogIn />} />
            <Route path="/sign-out" element={<SignOut />} />
            <Route path="*" element={<NotFound />} />
          </Routes>
        </main>
        <ErrorMessageModal Title={"Oops! Something Went Wrong!"} />
        <AlertMessageModal />
        <MobileNavbar />
      </ConfigProvider>
    </div>
  );
}

const RequireAuth = ({ children }) => {
  const dispatch = useDispatch();
  let isUserAuthenticated = authService.isUserAuthenticated();
  let location = useLocation();

  const resetState = useCallback(() => {
    dispatch({ type: "RESET" }); //calls the root reducer with RESET as action!
  }, [dispatch]);

  if (!isUserAuthenticated) {
    resetState();
    // Redirect them to the /login page, but save the current location they were
    // trying to go to when they were redirected. This allows us to send them
    // along to that page after they login, which is a nicer user experience
    // than dropping them off on the home page.

    return <Navigate to="/log-in" state={{ from: location }} replace />;
  }

  return children;
};
