import React, {
  useRef,
  forwardRef,
  useImperativeHandle,
  useState,
} from "react";
import ReactDOM from "react-dom/client";
import moment from "moment";
import { Timeline, DataSet } from "vis-timeline/standalone";
import "vis-timeline/styles/vis-timeline-graph2d.css";
import IconButton from "@mui/material/IconButton";
import PlayCircleOutlineIcon from "@mui/icons-material/PlayCircleOutline";

const ItemTemplate = ({ item, onButtonClick }) => (
  <React.Fragment>
    <IconButton sx={{ color: "#1f2937" }} onClick={() => onButtonClick(item)}>
      <PlayCircleOutlineIcon fontSize="small"></PlayCircleOutlineIcon>
    </IconButton>
  </React.Fragment>
);

const TimeLine = forwardRef((props, ref) => {
  const timelineRef = useRef(null);
  const timelineInstance = useRef(null);
  const [isTodayVideo, setIsTodayVideo] = useState(true);
  const [endVideo, setEndVideo] = useState();
  const [hiddenPeriods, setHiddenPeriods] = useState([]);

  useImperativeHandle(ref, () => ({
    setCurrentTime,
    setPeriods,
  }));

  const createTimeline = (dateTime, periodsWithoutVideo, data = []) => {
    if (timelineInstance.current) {
      timelineInstance.current.destroy();
    }

    if (dateTime.getHours() > 23) {
      dateTime = dateTime.setHours(dateTime.getHours() + 1);
    }

    const startMoment = new Date(
      moment(dateTime).format("YYYY-MM-DD 00:00:00")
    );

    const endMoment = new Date(moment(dateTime).format("YYYY-MM-DD HH:59:59"));

    setEndVideo(endMoment);

    const options = {
      width: "100%",
      height: "100%",
      min: startMoment,
      max: endMoment,
      end: endMoment,
      start: startMoment,
      showCurrentTime: false,
      showMajorLabels: false,
      stack: false,
      hiddenDates: periodsWithoutVideo,
      zoomMin: 60 * 10000,
      selectable: false,
      type: "point",
      template: (item, element) => {
        const root = ReactDOM.createRoot(element);
        root.render(
          <ItemTemplate
            item={item}
            onButtonClick={(event) => props.handleSelectedEven(event)}
          />
        );
      },
    };

    const timeline = new Timeline(
      timelineRef.current,
      new DataSet(data),
      options
    );

    timeline.setWindow(startMoment, endMoment);
    const customTimeId = timeline.addCustomTime(
      new Date(startMoment),
      "startTime"
    );

    timeline.on("doubleClick", (event) => {
      if (event.time !== null) {
        const selectedTime = parseTimeLineValueToMinutes(event.time);
        props.handleCurentTimeChange(selectedTime);
      }
    });

    timeline.on("timechanged", (event) => {
      if (event.id === customTimeId) {
        const selectedTime = parseTimeLineValueToMinutes(event.time);
        props.handleCurentTimeChange(selectedTime);
      }
    });

    timelineInstance.current = timeline;
  };

  const parseTimeLineValueToMinutes = (time) => {
    const hours = time.getHours();
    const minutes = time.getMinutes();

    return hours * 60 + minutes;
  };

  const setPeriods = (periods, date, data) => {
    const currentDate = new Date();
    let selectedDate = new Date(date);

    const todayVideo =
      selectedDate.toDateString() === currentDate.toDateString();

    setIsTodayVideo(todayVideo);

    if (todayVideo) {
      periods.push({
        start: moment(Date.now()).format("YYYY-MM-DD HH:mm:ss"),
        end: moment(endVideo).format("YYYY-MM-DD HH:mm:ss"),
        code: "LastHour",
      });
    } else {
      selectedDate = new Date(
        moment(selectedDate).format("YYYY-MM-DD 23:59:59")
      );
    }

    setHiddenPeriods(periods);
    createTimeline(selectedDate, periods, data);
  };

  const setCurrentTime = (secondsTime) => {
    if (timelineInstance.current) {
      const milliseconds = secondsTime * 1000;
      const dateTime = new Date(endVideo);

      dateTime.setHours(0, 0, 0, 0);
      dateTime.setTime(dateTime.getTime() + milliseconds);
      timelineInstance.current.setCustomTime(dateTime, "startTime");
      timelineInstance.current.setCustomTimeTitle(
        moment(dateTime).format("HH:mm:ss"),
        "startTime"
      );

      if (isTodayVideo) {
        updateLastTime();
      }
    }
  };

  const updateLastTime = () => {
    const lastTime = hiddenPeriods.filter((date) => {
      return date.code === "LastHour";
    });

    if (lastTime.length > 0) {
      const previousStart = new Date(lastTime[0].start);
      const startDate = new Date(
        moment(Date.now()).format("YYYY-MM-DD HH:mm:ss")
      );

      const differenceInMs = Math.abs(previousStart - startDate);
      const differenceInMinutes = differenceInMs / (1000 * 60);

      if (differenceInMinutes > 1) {
        startDate.setMinutes(startDate.getMinutes() + 2);
        lastTime[0].start = startDate;
      }
    }
  };

  return <div className="timeLine-control my-1" ref={timelineRef} />;
});

export default React.memo(TimeLine);
