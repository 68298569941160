import React, { useState, useRef } from "react";
import NotificationPlayer from "../components/NotificationPlayer";
import EventList from "../components/EventList";
import ThumbUpIcon from "@mui/icons-material/ThumbUp";
import ThumbDownIcon from "@mui/icons-material/ThumbDown";
import UserFeedbackModal from "../components/UserFeedbackModal";
import Popover from "@mui/material/Popover";
import Typography from "@mui/material/Typography";
import DeleteForeverIcon from "@mui/icons-material/DeleteForever";
import WarningMessageModal from "../components/PrevWarningMessageModal";
import { useDispatch } from "react-redux";
import {
  deleteNotification,
  saveUserFeedback,
} from "../store/notification/notificationAction";

export default function Events() {
  const dispatch = useDispatch();
  const [currNoti, setCurrNoti] = useState(null);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [warningMessage, setWarningMessage] = useState("");
  const userFeedbackModal = useRef();
  const warningMessageModal = useRef();
  const eventListControl = useRef();
  const open = Boolean(anchorEl);
  const id = open ? "simple-popover" : undefined;

  const setMainNotification = (notification) => {
    setCurrNoti(notification);
  };

  const openFeedbackModal = (currNoti) => {
    if (userFeedbackModal.current) {
      userFeedbackModal.current.openModal(currNoti);
    }
  };

  const handleSaveFeedbackCallback = async (
    result,
    actions,
    feedbackDescription
  ) => {
    if (result) {
      const actionEvents = actions.map((action) => {
        return { action_type_id: action.id };
      });

      let notice = { ...currNoti };

      notice.actionEvents = actionEvents;
      notice.feedback_description = feedbackDescription;
      dispatch(saveUserFeedback(notice, false)).then((result) => {
        if (result.status) {
          const nextNotification =
            eventListControl.current.getNextNotification();
          setMainNotification(nextNotification);
        }
      });
    }
  };

  const handleSaveUserFeedbackClick = async (e, originalNtification) => {
    if (e) {
      setAnchorEl(e.currentTarget);
      const notification = { ...originalNtification };
      notification.actionEvents = [];
      dispatch(saveUserFeedback(notification, true)).then((result) => {
        if (result.status) {
          const nextNotification =
            eventListControl.current.getNextNotification();
          setMainNotification(nextNotification);
        }
      });

      setTimeout(() => {
        setAnchorEl(null);
      }, 900);
    }
  };

  const warningModalResult = (result) => {
    if (result) {
      dispatch(deleteNotification(currNoti.clip_id)).then((result) => {
        if (result.status) {
          const nextNotification =
            eventListControl.current.getNextNotification();
          setMainNotification(nextNotification);
        }
      });
    }
  };

  const openWarningModal = (message) => {
    setWarningMessage(message);
    if (warningMessageModal.current) {
      warningMessageModal.current.openModal();
    }
  };

  const handleRemoveNotificationkClick = (event) => {
    openWarningModal("Are you sure you want to delete this notification?");
  };

  return (
    <div className="h-full flex flex-col xl:flex-row px-2 py-4">
      <div className="xl:grow pr-2 flex flex-col sticky top-0 z-10 py-1 md:py-0">
        {!currNoti ? (
          <div className="flex items-center justify-center pt-8 mt-8">
            <h1 className="text-2xl md:text-3xl">
              The notification is coming soon!
            </h1>
          </div>
        ) : (
          <div className="w-5/6 self-center">
            <NotificationPlayer notification={currNoti} />
            {currNoti?.user_feedback ?? (
              <div className="flex mt-2 space-x-4 space-y-2 justify-center">
                <button
                  type="button"
                  className="px-8 py-2 mt-2 bg-stone-600 rounded-lg text-white font-semibold"
                  onClick={(e) => handleRemoveNotificationkClick(e, currNoti)}
                >
                  <DeleteForeverIcon />
                </button>
                <button
                  type="button"
                  className="px-8 py-2 bg-stone-600 rounded-lg text-white font-semibold"
                  onClick={(e) => handleSaveUserFeedbackClick(e, currNoti)}
                >
                  <ThumbUpIcon />
                </button>
                <Popover
                  id={id}
                  open={open}
                  anchorEl={anchorEl}
                  aria-haspopup="true"
                  anchorOrigin={{
                    vertical: "bottom",
                    horizontal: "left",
                  }}
                  transformOrigin={{
                    vertical: "top",
                    horizontal: "left",
                  }}
                >
                  <Typography sx={{ p: 1 }}>Done!</Typography>
                </Popover>
                <button
                  type="button"
                  className="px-8 py-2 bg-stone-600 rounded-lg text-white font-semibold"
                  onClick={() => openFeedbackModal(currNoti)}
                >
                  <ThumbDownIcon />
                </button>
              </div>
            )}
          </div>
        )}
      </div>
      <EventList
        ref={eventListControl}
        handleNotificationClick={setMainNotification}
        setMainNotification={setMainNotification}
      />
      <UserFeedbackModal
        ref={userFeedbackModal}
        SaveFeedbackCallback={handleSaveFeedbackCallback}
      />
      <WarningMessageModal
        ref={warningMessageModal}
        Title={"Caution!"}
        Message={warningMessage}
        WarningResultCallback={warningModalResult}
      />
    </div>
  );
}
