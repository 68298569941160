import { cameraAction } from "./camera";
import cameraApi from "../../api/camera";
import { errorAction } from "../error/error";
import { notificationAction } from "../notification/notification";
import { setAlertMessage, setShowAlertMessage } from "../alert/alertAction";

export const getCameras = () => {
  return async (dispatch) => {
    try {
      let respose = await cameraApi.getCameras();

      if (respose.status) {
        dispatch(cameraAction.replaceCameras(respose.camera_list));
        dispatch(notificationAction.setCameraList(respose.camera_list));
      } else {
        respose = await cameraApi.getCameras();

        if (respose.status) {
          dispatch(cameraAction.replaceCameras(respose.camera_list));
          dispatch(notificationAction.setCameraList(respose.camera_list));
        } else {
          dispatch(errorAction.setErrorMessage(respose.error));
          dispatch(errorAction.openModal());
        }
      }
      if (respose.status) {
        let brokenCameras = [];

        respose.camera_list.forEach((camera) => {
          if (!camera.ishealthy) {
            brokenCameras.push(camera.name);
          }
        });

        if (brokenCameras.length) {
          let alertMessage = "Something seriously bad happened with the";
          alertMessage +=
            brokenCameras.length <= 1
              ? ` camera : ${brokenCameras[0]}.`
              : ` cameras: ${brokenCameras.join(", ")}.`;

          alertMessage +=
            " Please click <a id='myLink' href='#/cameraHelp' ><strong>here</strong></a> to see instruction about how to fix.";

          dispatch(setAlertMessage("Holy smokes!", alertMessage, "danger"));
          dispatch(setShowAlertMessage(true));
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateCamera = (camera) => {
  return async (dispatch) => {
    try {
      let cameraApiRespose = await cameraApi.updateCamera(camera);

      if (cameraApiRespose.status) {
        let respose;

        const newAction_types = camera.action_types?.filter(
          (action) => !action.originalIntent
        );

        if (newAction_types.length) {
          respose = await cameraApi.addCameraActions(
            camera.mac,
            newAction_types
          );
        }

        if (!respose || respose.status) {
          const updateAction_types = camera.action_types?.filter(
            (action) =>
              action.originalIntent && action.originalIntent !== action.intent
          );
          if (updateAction_types.length) {
            respose = await cameraApi.updateCameraActions(
              camera.mac,
              updateAction_types
            );
          }
        }

        if (!respose || respose.status) {
          const removeActions = camera.originalAction_types?.filter(
            (action) =>
              !camera.action_types.some(
                (originalAction) => action.id === originalAction.id
              )
          );
          if (removeActions.length) {
            respose = await cameraApi.deleteCameraActions(
              camera.mac,
              removeActions
            );
          }
        }

        if (!respose || respose.status) {
          cameraApiRespose.result.action_types = camera.action_types.map(
            (action) => {
              let newAction = { ...action };
              newAction.originalIntent = action.intent;
              return newAction;
            }
          );

          cameraApiRespose.result.originalAction_types =
            cameraApiRespose.result.action_types;
        } else {
          dispatch(errorAction.setErrorMessage(respose.error));
          dispatch(errorAction.openModal());
        }

        dispatch(cameraAction.updateCamera(cameraApiRespose.result));
      } else {
        dispatch(errorAction.setErrorMessage(cameraApiRespose.error));
        dispatch(errorAction.openModal());
      }

      return cameraApiRespose.status;
    } catch (err) {
      console.log(err);
    }
  };
};

export const removeCamera = (camera) => {
  return async (dispatch) => {
    try {
      const respose = await cameraApi.removeCamera(camera);
      if (respose.status) {
        dispatch(cameraAction.removeCameras(camera));
      } else {
        dispatch(errorAction.setErrorMessage(respose.error));
        dispatch(errorAction.openModal());
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const setCameraHealthy = (mac, ishealthy) => {
  return (dispatch) => {
    try {
      dispatch(cameraAction.setCameraHealthy({ mac, ishealthy }));
    } catch (err) {
      console.log(err);
    }
  };
};

export const addCamera = (camera, isFromScanList = false) => {
  return async (dispatch) => {
    try {
      let result = false;

      const respose = await cameraApi.addCamera(camera);

      if (isFromScanList) {
        dispatch(
          cameraAction.setScanCameraStatus({
            mac: camera.mac,
            status: respose.status,
          })
        );
      }
      if (respose.status) {
        dispatch(cameraAction.addCamera(respose.result));
        if (!isFromScanList) {
          cameraApi.restartServices();
        }
        result = true;
      } else {
        dispatch(errorAction.setErrorMessage(respose.error));
        dispatch(errorAction.openModal());
      }
      return result;
    } catch (err) {
      console.log(err);
    }
  };
};

export const scanNetwork = () => {
  return async (dispatch) => {
    try {
      const respose = await cameraApi.scanNetwork();

      if (respose.status) {
        dispatch(cameraAction.setScanCamera(respose.scanCamera_list));
      } else {
        dispatch(errorAction.setErrorMessage(respose.error));
        dispatch(errorAction.openModal());
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const resetScanCamera = () => {
  return async (dispatch) => {
    try {
      dispatch(cameraAction.setScanCamera(null));
    } catch (err) {
      console.log(err);
    }
  };
};

export const setScanCameraUsername = (mac, username) => {
  return (dispatch) => {
    try {
      dispatch(cameraAction.setScanCameraUsername({ mac, username }));
    } catch (err) {
      console.log(err);
    }
  };
};

export const setSelectScanCamera = (index, value) => {
  return (dispatch) => {
    try {
      dispatch(cameraAction.setSelectScanCamera({ index, value }));
    } catch (err) {
      console.log(err);
    }
  };
};

export const setSelectScanCameraAll = (value) => {
  return (dispatch) => {
    try {
      dispatch(cameraAction.setSelectScanCameraAll({ value }));
    } catch (err) {
      console.log(err);
    }
  };
};

export const removeScanCamera = (mac) => {
  return (dispatch) => {
    try {
      dispatch(cameraAction.removeScanCamera(mac));
    } catch (err) {
      console.log(err);
    }
  };
};

export const restartServices = () => {
  return async (dispatch) => {
    try {
      await cameraApi.restartServices();
    } catch (err) {
      console.log(err);
    }
  };
};

export const setLoadingInScanCamera = (index, value) => {
  return (dispatch) => {
    try {
      dispatch(cameraAction.setLoadingInScanCamera({ index, value }));
    } catch (err) {
      console.log(err);
    }
  };
};

export const setLoadingInCameraByMac = (mac, value) => {
  return (dispatch) => {
    try {
      dispatch(cameraAction.setLoadingInCameraByMac({ mac, value }));
    } catch (err) {
      console.log(err);
    }
  };
};
