import React, {
  Fragment,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import CancelIcon from "@mui/icons-material/CancelOutlined";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline";
import {
  openModal as openShowErrorModal,
  setErrorMessage,
} from "../../store/error/errorAction";
import { useDispatch } from "react-redux";

const DateRangeSelectorModal = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());
  const cancelButtonRef = useRef(null);
  const dialogRef = useRef(null);

  const SaveModal = () => {
    if (new Date(startDate) > new Date(endDate)) {
      showErrorModal("Start date cannot be greater than end date");
    } else {
      const result = {
        isOk: true,
        endDate: endDate,
        startDate: startDate,
      };

      props.SelectedRange(result);
    }
  };

  const closeModal = (close) => {
    if (close) {
      setIsOpen(false);
    }
  };

  const openModal = () => {
    setIsOpen(true);
  };

  useImperativeHandle(ref, () => ({
    openModal,
  }));

  const showErrorModal = (message) => {
    dispatch(setErrorMessage(message));
    dispatch(openShowErrorModal());
  };

  return (
    <React.Fragment>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
          ref={dialogRef}
          as="div"
          className="relative z-10 your-dialog-content-class"
          initialFocus={cancelButtonRef}
          onClose={() => closeModal(false)}
          static={false}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white rounded-lg">
                   
                    <div className="grid  justify-items-center">
                      <div className=" mx-2 my-2 space-y-1">
                        <div className="flex mt-3">
                          <div className="py-2 rounded mr-2">
                            <DatePicker
                              className="text-sm timePicker-sm"
                              label="Start Date"
                              value={startDate}
                              onChange={(newStart) => setStartDate(newStart)}
                              disableFuture={true}
                            />
                          </div>
                          <div className="py-2 rounded ml-4">
                            <DatePicker
                              label="End Date"
                              className="text-sm timePicker-sm"
                              value={endDate}
                              onChange={(newEnd) => setEndDate(newEnd)}
                              disableFuture={true}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50  py-1 mb-1 sm:flex sm:flex-row-reverse">
                      <button
                        type="button"
                        className=" inline-flex w-full rounded-md  text-white bg-gai-green  px-2 py-2 text-sm font-semibold shadow-sm ring-1 ring-gray-300 hover:bg-emerald-600 sm:w-auto mr-2 border border-gai-green"
                        onClick={() => SaveModal()}
                      >
                        <CheckCircleOutlineIcon
                          sx={{ fontSize: 20 }}
                        ></CheckCircleOutlineIcon>
                        &nbsp;Select
                      </button>
                      &nbsp;
                      <button
                        type="button"
                        className="inline-flex w-full rounded-md bg-white px-2 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-gray-300 hover:bg-gray-200 sm:w-auto mr-2 border border-red-500"
                        onClick={() => closeModal(true)}
                        ref={cancelButtonRef}
                      >
                        <CancelIcon
                          className="text-red-600"
                          sx={{ fontSize: 20 }}
                        ></CancelIcon>
                        &nbsp;Cancel
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </React.Fragment>
  );
});

export default DateRangeSelectorModal;
