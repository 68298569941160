import { configureStore } from "@reduxjs/toolkit";
import storage from "redux-persist/lib/storage";
import { combineReducers } from "redux";
import { persistStore, persistReducer } from "redux-persist";
import { thunk } from "redux-thunk";
import cameraSlice from "./camera/camera";
import errorSlice from "./error/error";
import unitSlice from "./unit/unit";
import notificationSlice from "./notification/notification";
import lookupSlice from "./lookup/lookup";
import alertSlice from "./alert/alert";

//Create Reducers
const reducers = combineReducers({
  camera: cameraSlice.reducer,
  error: errorSlice.reducer,
  notification: notificationSlice.reducer,
  unit: unitSlice.reducer,
  lookup: lookupSlice.reducer,
  alert: alertSlice.reducer,
});

// configure the persist
const persistConfig = {
  key: "root",
  storage,
  whitelist: ["unit", "camera", "lookup", "alert"],
};

// create root reducer that allow us to clean the store
const rootReducer = (state, action) => {
  if (action.type === "RESET") {
    state = {};
  }
  return reducers(state, action);
};

// persist the reducer
const persistedReducer = persistReducer(persistConfig, rootReducer);

// configure the store
const store = configureStore({
  reducer: persistedReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      immutableCheck: { warnAfter: 128 },
      serializableCheck: false,
    }).concat(thunk),
});

const persistor = persistStore(store);

export { store, persistor };
