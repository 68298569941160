import React, { useState, useEffect } from "react";
import ReactPlayer from "react-player";
import { useSelector } from "react-redux";
import authService from "../../services/authService";

const NotificationPlayer = (props) => {
  const intentList = useSelector((state) => state.lookup.intentList);
  const [currVidUrl, setCurrVidUrl] = useState(null);
  const [currNoti, setCurrNoti] = useState(null);

  useEffect(() => {
    setNotification(props.notification);
  });

  const setNotification = (notification) => {
    if (notification) {
      const streamUrl = `${authService.getUnitUrl()}video_captions/get_video/${
        notification.clip_id
      }`;
      setCurrVidUrl(streamUrl);
    }

    setCurrNoti(notification);
  };

  const getIntentLabelColor = (value) => {
    const inten = intentList.find((option) => option.value === value);
    return inten ? inten.color : "#FFFFFF";
  };

  const getIntentLabel = (value) => {
    const inten = intentList.find((option) => option.value === value);
    return inten ? inten.label : value;
  };

  return (
    <div>
      <div className="flex justify-between p-2 flex-wrap border-t-2 border-x-2 rounded-t-md rounded-x-md border-stone-500 bg-stone-200 text-stone-800">
        <p>
          <strong>Camera:</strong>&nbsp;{currNoti?.cameraname}
        </p>
        <p>
          <strong>Actions Count:</strong>&nbsp;
          {currNoti?.totalOfActionsDetected}
        </p>
        <span className="flex mt-0-important">
          <strong>Intent:</strong>&nbsp;
          <span className="flex">
            {currNoti?.intentDetected?.split(", ").map((item, index, array) => {
              const label =
                index < array.length - 1
                  ? `${getIntentLabel(item)},`
                  : getIntentLabel(item);
              return (
                <p key={index} style={{ color: getIntentLabelColor(item) }}>
                  {label}&nbsp;
                </p>
              );
            })}
          </span>
        </span>
        <p>
          <strong>Date:</strong>&nbsp;{currNoti?.sent_date}
        </p>
      </div>

      <div className="flex items-center justify-center">
        <ReactPlayer url={currVidUrl} width="100%" height="auto" controls />
      </div>

      <div className="flex justify-center p-2 mb-1 flex-wrap border-b-2 border-x-2 rounded-b-md rounded-x-md border-stone-500 bg-stone-200 text-stone-800">
        <p>
          <strong>Actions Detected:&nbsp;</strong>
          {currNoti?.actionsDetected}
        </p>
      </div>

      <div className="flex items-center mb-2 justify-center text-stone-800">
        <strong>Actions Description:</strong>
      </div>

      <div className="flex items-center mb-2 justify-center">
        <textarea
          className="rounded text-justify border border-green px-2 py-2 responsive-textarea"
          value={currNoti?.description}
          
          readOnly={true}
        />
      </div>
    </div>
  );
};

export default NotificationPlayer;
