import ReactPlayer from "react-player";
import React, { createRef } from "react";

const LiveVideoPlayer = React.memo((props) => {
  const currentVideoPlayer = createRef();
  return (
    <>
      <ReactPlayer
        className={props.className}
        id={props.playerId}
        ref={currentVideoPlayer}
        url={props.videoUrrl}
        width="100%"
        height="auto"
        playing={true}
        preload="auto"
        autoPlay={true}
        muted={true}
        loop={false}
        config={{
          file: {
            hlsOptions: {
              maxBufferLength: 20, // or 15 or 20 based on tests
              maxMaxBufferLength: 30,
              maxBufferSize: 60 * 1000 * 1000,
              maxBufferHole: 2.5,
              highBufferWatchdogPeriod: 10,
              maxFragLookUpTolerance: 2.5,
              enableWorker: true,
              lowLatencyMode: true,
              progressive: true,
              testBandwidth: true,
              liveDurationInfinity: true,
              autoStartLoad: true,
              backBufferLength: Infinity,
              frontBufferFlushThreshold: Infinity,
              nudgeOffset: 0.1,
              nudgeMaxRetry: 10,
              preferManagedMediaSource: true,
              enableSoftwareAES: true,
              capLevelToPlayerSize: true,
              fragLoadingMaxRetry: 10,
              manifestLoadingMaxRetry: 10,
              levelLoadingMaxRetry: 10,
              appendErrorMaxRetry: 10,
              startLevel: -1,
            },
          },
        }}
        onBuffer={props.onBuffer}
        onBufferEnd={props.onBufferEnd}
        onProgress={(progress) => {
          const playedProgressSeconds = progress.playedSeconds;
          const videoDurationSecond = currentVideoPlayer.current.getDuration();
          const realtimeDelay = videoDurationSecond - playedProgressSeconds;

          if (playedProgressSeconds > 0 && realtimeDelay >= 40) {
            const timeBehindRealTime = 20;
            console.log(
              `There was a delay in live video of: ${Math.round(
                realtimeDelay / 60
              )} minutes, in camera:${
                props.cameraName
              } , it was automatically adjusted to the last ${timeBehindRealTime} seconds`
            );
            const newPlayTime = videoDurationSecond - timeBehindRealTime;
            currentVideoPlayer.current.seekTo(newPlayTime);
          }
        }}
        onError={(...args) => {
          try {
            var error = JSON.stringify(args[0]);

            if (error === '"hlsError"') {
              error = `${error}: { reason:${JSON.stringify(
                args[1].reason
              )}, details:${JSON.stringify(
                args[1].details
              )}, type:${JSON.stringify(args[1].type)}, fatal:${JSON.stringify(
                args[1].fatal
              )}, url:${JSON.stringify(args[1].url)}}`;
            }
            console.log(
              `Camera:${props.cameraName},There is a error with the video: ${error}`
            );
          } catch {
            console.log(
              `Camera:${
                props.cameraName
              },There is a error with the video: ${JSON.stringify(args)}`
            );
          }
        }}
      />
      <input type="hidden" value={props.videoUrrl} />
    </>
  );
});

export default LiveVideoPlayer;
