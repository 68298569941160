import { createSlice } from "@reduxjs/toolkit";

const alertSlice = createSlice({
  name: "alert",
  initialState: {
    showAlertMessage: false,
    alertTitle: '',
    alertMessage:'',
    alertType : '',
    alertModalMessage: "",
    alertModalTitle: "",
    showAlertModal: false,
  },
  reducers: {
    setShowAlertMessage(state, action) {
      state.showAlertMessage = action.payload;
    },
    setAlertMessage(state, action) {
      state.alertTitle = action.payload.title;
      state.alertMessage = action.payload.message;
      state.alertType = action.payload.type;
    },
    setAlertModalMessage(state, action) {
      state.alertModalMessage = action.payload.message;
      state.alertModalTitle = action.payload.title;
    },    
    openAlertModal(state) {
      state.showAlertModal = true;
    },
    closeAlertModal(state) {
      state.showAlertModal = false;
      state.alertModalMessage = "";
    },
  },
});

export const alertAction = alertSlice.actions;
export default alertSlice;
