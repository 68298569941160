import React from "react";
import "./index.css";

const IPCameraTroubleshootingGuide = () => {
  return (
    <div className="troubleshooting-guide">
      <h1><strong>IP Camera Troubleshooting Guide</strong></h1>
      <p>
        This guide is designed to help you troubleshoot common issues with your
        IP camera. Follow the steps outlined below to diagnose and resolve
        problems related to power, connectivity, configuration, and technical
        errors.
      </p>

      <div className="content">
        <section>
          <h2>
            <i className="fas fa-plug"></i> Checking the Power
          </h2>

          <h3>Step 1: Verify Power Source</h3>
          <ul>
            <li>Ensure the camera is connected to a working power outlet.</li>
            <li>Check for any loose connections in the power cable.</li>
            <li>
              If using a PoE (Power over Ethernet) switch, ensure it is
              functioning correctly.
            </li>
          </ul>

          <h3>Step 2: Power Indicator Lights</h3>
          <ul>
            <li>Check if the power indicator lights on the camera are on.</li>
            <li>
              If there are no lights, try a different power source or adapter.
            </li>
          </ul>
        </section>

        <section>
          <h2>
            <i className="fas fa-network-wired"></i> Checking Connectivity
          </h2>

          <h3>Step 1: Ethernet Cable</h3>
          <ul>
            <li>
              Ensure the Ethernet cable is securely connected to both the camera
              and the router/switch.
            </li>
            <li>
              Replace the Ethernet cable with a known working one to rule out
              cable issues.
            </li>
          </ul>

          <h3>Step 2: Wi-Fi Connection</h3>
          <ul>
            <li>
              Ensure the camera is within the range of your Wi-Fi network.
            </li>
            <li>
              Verify the Wi-Fi credentials (SSID and password) are correctly
              entered in the camera settings.
            </li>
            <li>Restart your router and the camera.</li>
          </ul>

          <h3>Step 3: Network Configuration</h3>
          <ul>
            <li>
              Check the IP address configuration of the camera. Ensure it is
              within the same subnet as your network.
            </li>
            <li>
              Ensure there are no IP address conflicts with other devices on the
              network.
            </li>
          </ul>
        </section>

        <section>
          <h2>
            <i className="fas fa-cogs"></i> Checking Camera Configuration
          </h2>

          <h3>Step 1: Accessing Camera Settings</h3>
          <ul>
            <li>
              Use the camera's software or web interface to access the settings.
            </li>
            <li>Ensure the camera firmware is up to date.</li>
          </ul>

          <h3>Step 2: Video Settings</h3>
          <ul>
            <li>
              Check the video resolution and frame rate settings. Ensure they
              are compatible with your NVR system.
            </li>
            <li>Verify that the correct video encoding format is selected.</li>
          </ul>

          <h3>Step 3: User Permissions</h3>
          <ul>
            <li>
              Ensure you have the correct user permissions to access the camera
              and its features.
            </li>
          </ul>
        </section>

        <section>
          <h2>
            <i className="fas fa-exclamation-triangle"></i> Technical Issues
          </h2>

          <h3>Step 1: Internal Storage</h3>
          <ul>
            <li>
              Check if the camera's internal storage is full. If so, delete
              unnecessary files or increase storage capacity.
            </li>
            <li>
              Ensure that the camera's storage settings are correctly configured
              to manage space automatically.
            </li>
          </ul>

          <h3>Step 2: Firmware Update Issues</h3>
          <ul>
            <li>
              If a firmware update failed, reset the camera to factory settings
              and attempt the update again.
            </li>
            <li>
              Ensure the firmware file is compatible with your camera model.
            </li>
          </ul>

          <h3>Step 3: Camera Reset</h3>
          <ul>
            <li>
              Perform a soft reset of the camera by power cycling it (turning it
              off and on).
            </li>
            <li>
              If issues persist, perform a hard reset by following the
              manufacturer's instructions for resetting the camera to factory
              settings.
            </li>
          </ul>

          <h3>Step 4: Hardware Issues</h3>
          <ul>
            <li>Inspect the camera for any physical damage or wear.</li>
            <li>Ensure the lens is clean and free from obstructions.</li>
            <li>
              If the camera is exposed to harsh weather, ensure it is properly
              weatherproofed.
            </li>
          </ul>
        </section>

        <section>
          <h2>
            <i className="fas fa-tools"></i> Advanced Troubleshooting
          </h2>

          <h3>Step 1: Logs and Diagnostics</h3>
          <ul>
            <li>Check the camera’s logs for any error messages or warnings.</li>
            <li>
              Use diagnostic tools provided by the camera manufacturer to
              identify issues.
            </li>
          </ul>

          <h3>Step 2: Contact Support</h3>
          <ul>
            <li>
              If you are unable to resolve the issue, contact the camera
              manufacturer’s support team for assistance.
            </li>
            <li>
              Provide them with detailed information about the problem and the
              steps you have already taken.
            </li>
          </ul>
        </section>
      </div>
    </div>
  );
};

export default IPCameraTroubleshootingGuide;
