import { errorAction } from "./error";

export const closeModal = () => {
  return async (dispatch) => {
    try {
      dispatch(errorAction.closeModal());
    } catch (err) {
      console.log(err);
    }
  };
};

export const openModal = (errorMessage) => {
  return async (dispatch) => {
    try {
      dispatch(errorAction.openModal());
    } catch (err) {
      console.log(err);
    }
  };
};

export const setErrorMessage = (errorMessage) => {
  return async (dispatch) => {
    try {
      dispatch(errorAction.setErrorMessage(errorMessage));
    } catch (err) {
      console.log(err);
    }
  };
};
