import { alertAction } from "./alert";

export const setShowAlertMessage = (value) => {
  return (dispatch) => {
    try {
      dispatch(alertAction.setShowAlertMessage(value));
    } catch (err) {
      console.log(err);
    }
  };
};

export const setAlertMessage = (title, message, type) => {
  return (dispatch) => {
    try {
      dispatch(alertAction.setAlertMessage({ title, message, type }));
    } catch (err) {
      console.log(err);
    }
  };
};

export const closeAlertModal = () => {
  return async (dispatch) => {
    try {
      dispatch(alertAction.closeAlertModal());
    } catch (err) {
      console.log(err);
    }
  };
};

export const openAlertModal = () => {
  return async (dispatch) => {
    try {
      dispatch(alertAction.openAlertModal());
    } catch (err) {
      console.log(err);
    }
  };
};

export const setAlertModalMessage = (message, title = "" ) => {
  return async (dispatch) => {
    try {
      dispatch(alertAction.setAlertModalMessage({message, title}));
    } catch (err) {
      console.log(err);
    }
  };
};
