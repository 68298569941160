import { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";

import { Modal, Form, Input, Select, Spin } from "antd";
import CircleIcon from "@mui/icons-material/Circle";

import LiveVideoPlayer from "../LivePlayer";
import authService from "../../services/authService";
import { updateCamera } from "../../store/camera/cameraAction";

export default function EditCameraModal({ camera = {}, open, onOk, onCancel }) {
  const dispatch = useDispatch();
  const actionTypes = useSelector((state) => state.lookup.actionTypes);
  const cameraRoles = useSelector((state) => state.lookup.cameraRoles);
  const intentList = useSelector((state) => state.lookup.intentList);

  const [form] = Form.useForm();
  const navigate = useNavigate();

  const [modalWidth, setModalWidth] = useState("50%");
  const [previousCamera, setPreviousCamera] = useState(camera);
  const [currentCamera, setCurrentCamera] = useState(camera);
  const [videoLoading, setVideoLoading] = useState(false);

  useEffect(() => {
    const updateCurrentCamera = () => {
      setPreviousCamera(camera);
      setCurrentCamera(camera);
    };

    updateCurrentCamera();
  }, [camera]);

  useEffect(() => {
    const updateModalWidth = () => {
      const windowWidth = window.innerWidth;

      if (windowWidth >= 1920) {
        // bigger desktop
        setModalWidth("40%");
      } else if (windowWidth >= 1374) {
        // desktop
        setModalWidth("50%");
      } else if (windowWidth >= 768) {
        // tablets
        setModalWidth("60%");
      } else {
        // mobile
        setModalWidth("98%");
      }
    };

    updateModalWidth();
    window.addEventListener("resize", updateModalWidth);
    return () => window.removeEventListener("resize", updateModalWidth);
  });

  const handleOnOk = async () => {
    form.submit();

    try {
      await form.validateFields();
      dispatch(updateCamera(currentCamera));
      onOk();
    } catch (err) {}
  };

  const handleOnCancel = () => {
    onCancel();
    navigate("/setup");
  };

  const getIntentLabelColor = (value) => {
    const intent = intentList.find((option) => option.value === value);
    return intent ? intent.color : "#FFFFFF";
  };

  const handleChangeCameraName = (name) => {
    let updatedCamera = { ...currentCamera };
    updatedCamera.name = name;
    setCurrentCamera(updatedCamera);
  };

  const handleChangeCameraRole = (role) => {
    let updatedCamera = { ...currentCamera };
    updatedCamera.role = role;
    setCurrentCamera(updatedCamera);
  };

  const handleChangeAdditionalContext = (additionalContext) => {
    let updatedCamera = { ...currentCamera };
    updatedCamera.additional_context = additionalContext;
    setCurrentCamera(updatedCamera);
  };

  const handleChangeCameraActions = (actions) => {
    let updatedCamera = { ...currentCamera };
    updatedCamera.action_types = actionTypes.filter((actionType) =>
      actions.includes(actionType.id)
    );

    setCurrentCamera(updatedCamera);
    form.setFieldValue("actionTypesIntent", updatedCamera.action_types);
  };

  const handleChangeActionTypeIntent = (actionType, newIntent) => {
    let updatedCamera = { ...currentCamera };

    const updatedActionTypesIntent = updatedCamera.action_types.map(
      (currentAction) => {
        if (currentAction.name === actionType.name) {
          let updateAction = { ...currentAction };
          updateAction.intent = newIntent;

          return updateAction;
        }
        return currentAction;
      }
    );

    updatedCamera.action_types = updatedActionTypesIntent;
    setCurrentCamera(updatedCamera);
  };

  if (Object.keys(currentCamera).length === 0) {
    return null;
  }

  return (
    <Modal
      id="EditCameraModal"
      key="EditCameraModal"
      wrapClassName="EditCameraModal"
      classNames={{
        mask: "modal-mask",
        header: "modal-header",
        content:
          "modal-content !bg-gradient-to-b !from-guardis-10 !to-white dark:!from-guardis-950 dark:!via-neutral-950 dark:!to-neutral-950",
        body: "border-y dark:border-neutral-700 h-[50vh] max-h-[600px]",
        footer: "modal-footer",
      }}
      open={open}
      onOk={handleOnOk}
      onCancel={handleOnCancel}
      title={<h2 className="modal-title">Edit Camera</h2>}
      okText="Save"
      okButtonProps={{
        block: true,
        className: "m-0",
        form: "EditCameraForm",
        key: "submit",
        htmlType: "submit",
      }}
      cancelButtonProps={{ block: true }}
      destroyOnClose
      centered
      keyboard
      width={modalWidth}
    >
      <div className="h-full flex flex-row">
        <Form
          id="EditCameraForm"
          form={form}
          preserve={false}
          className="w-full flex flex-col px-10 pb-64 gap-4 overflow-y-scroll"
          initialValues={{
            cameraName: previousCamera.name,
            cameraRole: previousCamera.role,
            additionalContext: previousCamera.additional_context,
            actionTypes: previousCamera.action_types.map(
              (actionType) => actionType.id
            ),
            actionTypesIntent: previousCamera.action_types,
          }}
        >
          <div id="cameraName" className="flex flex-col gap-1 pt-4">
            <h3>Name</h3>
            <Form.Item
              hasFeedback
              name="cameraName"
              rules={[
                {
                  required: true,
                  message: "Camera name is required.",
                },
              ]}
            >
              <Input
                allowClear
                variant="filled"
                placeholder="Enter Name for Camera"
                onChange={(e) => handleChangeCameraName(e.target.value)}
              />
            </Form.Item>
          </div>

          <div id="cameraRole" className="flex flex-col gap-1">
            <h3>Role</h3>
            <Form.Item
              hasFeedback
              name="cameraRole"
              rules={[
                {
                  required: true,
                  message: "Role is required.",
                },
              ]}
            >
              <Select
                allowClear
                variant="filled"
                placeholder="Select Role for Camera"
                options={cameraRoles}
                onChange={handleChangeCameraRole}
              />
            </Form.Item>
          </div>

          <div id="additionalContext" className="flex flex-col gap-1">
            <h3>Additional Context</h3>
            <Form.Item
              hasFeedback
              name="additionalContext"
              rules={[
                {
                  required: true,
                  message: "Additional context is required.",
                },
              ]}
            >
              <Input.TextArea
                allowClear
                variant="filled"
                cols={50}
                rows={3}
                maxLength={100}
                placeholder="Enter Additional Context for Camera"
                onChange={(e) => handleChangeAdditionalContext(e.target.value)}
              />
            </Form.Item>
          </div>

          <div id="actionTypes" className="space-y-2">
            <h3>Actions</h3>

            <div id="selectActionTypes" className="flex flex-col gap-3">
              <h4>Select Action Types</h4>
              <Form.Item
                hasFeedback
                name="actionTypes"
                rules={[
                  {
                    required: true,
                    message: "At least one action must be selected.",
                    type: "array",
                  },
                ]}
              >
                <Select
                  allowClear
                  variant="filled"
                  placeholder="Select Actions"
                  mode="multiple"
                  fieldNames={{ label: "name", value: "id" }}
                  options={actionTypes}
                  onChange={handleChangeCameraActions}
                />
              </Form.Item>
            </div>

            <div id="selectIntents" className="flex flex-col gap-3">
              <h4>Select Intent of Action Types</h4>

              <Form.List name="actionTypesIntent">
                {(fields) => (
                  <div className="bg-black/[0.04] dark:bg-white/[0.08] rounded-md">
                    {fields.map((field, i) => {
                      const actionType = currentCamera.action_types[i];

                      return (
                        <div
                          key={field.key}
                          className="flex flex-row justify-between px-4 py-2 border-b border-neutral-200 dark:border-neutral-800 last:border-b-0"
                        >
                          <p className="w-32 font-medium">{actionType.name}</p>
                          <Form.Item
                            {...field}
                            className="flex-1 max-w-64"
                            name={[field.name, "intent"]}
                            initialValue={actionType.intent}
                            rules={[
                              {
                                required: true,
                                message: "Select an intent.",
                              },
                            ]}
                          >
                            <Select
                              variant="filled"
                              placeholder="Select an Intent"
                              options={intentList}
                              onChange={(value) =>
                                handleChangeActionTypeIntent(actionType, value)
                              }
                              optionRender={(option) => {
                                const fontColor = getIntentLabelColor(
                                  option.value
                                );

                                return (
                                  <div className="flex items-center gap-2">
                                    <CircleIcon
                                      className="text-[8px]"
                                      sx={{ color: fontColor }}
                                    />
                                    <div className="font-normal">
                                      {option.label}
                                    </div>
                                  </div>
                                );
                              }}
                            />
                          </Form.Item>
                        </div>
                      );
                    })}
                  </div>
                )}
              </Form.List>
            </div>
          </div>

          <div className="flex flex-col gap-3">
            <h3 id="liveFeed">Live Feed</h3>
            <Spin spinning={videoLoading} size="large">
              <LiveVideoPlayer
                className="border rounded-md overflow-hidden shadow-lg"
                playerId={`player_${currentCamera.mac}`}
                videoUrrl={`${authService.getUnitUrl()}media/live/${
                  currentCamera.mac
                }/output.m3u8`}
                cameraName={currentCamera.name}
                onBuffer={() => setVideoLoading(true)}
                onBufferEnd={() => setVideoLoading(false)}
              />
            </Spin>
          </div>
        </Form>
      </div>
    </Modal>
  );
}
