import { createSlice } from "@reduxjs/toolkit";

const unitSlice = createSlice({
  name: "unit",
  initialState: {
    unit: {
      editMode: false,
      loading: false,
      id: 0,
      settings: {
        businessHour: {
          closes: null,
          opens: null,
        },
        location: "",
      },
    },
    locationList: [
      { text: "Retail Store", id: "Retail Store" },
      { text: "Convenience Store", id: "Convenience Store" },
      { text: "Gas Station", id: "Gas Station" },
      { text: "Administrative Office", id: "Administrative Office" },
      { text: "Pharmacy", id: "Pharmacy" },
      { text: "Restaurant", id: "Restaurant" },
      { text: "Liquor Store", id: "Liquor Store" },
    ],
  },
  reducers: {
    setUnitLocation(state, action) {
      state.unit.unitLocation = action.payload;
    },
    setUnit(state, action) {
      if (action.payload.settings) {
        state.unit = action.payload;
      }
    },
    setLoadingInUnit(state, action) {
      state.unit.loading = action.payload;
    },
    setUnitInEditMode(state, action) {
      state.unit.editMode = action.payload;
    },
  },
});

export const unitAction = unitSlice.actions;
export default unitSlice;
