import React, {
  Fragment,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import CancelIcon from "@mui/icons-material/CancelOutlined";
import NotificationPlayer from "../NotificationPlayer";

const NotificationModal = forwardRef((props, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [notification, setNotification] = useState(false);
  const cancelButtonRef = useRef(null);
  const dialogRef = useRef(null);

  useImperativeHandle(ref, () => ({
    openModal,
  }));

  const closeModal = () => {
    setIsOpen(false);
  };

  const openModal = (currentNotification) => {
    setIsOpen(true);
    setNotification(currentNotification);
  };

  return (
    <React.Fragment>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
          ref={dialogRef}
          as="div"
          className="relative z-10 your-dialog-content-class"
          initialFocus={cancelButtonRef}
          onClose={closeModal}
          static={true}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-1 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform rounded-lg bg-white text-left shadow-xl transition-all  max-w-xl">
                  <div className="bg-white rounded-b-lg rounded-t-lg">
                    <div className="sm:flex sm:items-start sm:p-1 sm:pb-1  bg-light-navy rounded-t-lg ">
                      <div className="w-[50%]">
                        <Dialog.Title
                          as="h3"
                          className="pl-2 pt-0 text-base font-semibold leading-6 text-white"
                        >
                          Notification
                        </Dialog.Title>
                      </div>
                      <div className="w-[50%] text-white flex justify-end px-2">
                        <button
                          onClick={() => closeModal(false)}
                          className="font-semibold text-md hover:text-gray-200  "
                        >
                          X
                        </button>
                      </div>
                    </div>
                    <div className="modalPlayer xl:grow mt-1 ml-3 mr-3 flex flex-col sticky top-0 z-10">
                      <NotificationPlayer notification={notification}></NotificationPlayer>                     
                    </div>
                    <div className="bg-gray-50 px-1 py-1 sm:flex sm:flex-row-reverse">                     
                      <button
                        type="button"
                        className="inline-flex w-full rounded-md bg-white px-2 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-gray-300 hover:bg-gray-200 sm:w-auto mr-2 border border-red-500"
                        onClick={() => closeModal(false)}
                        ref={cancelButtonRef}
                      >
                        <CancelIcon
                          className="text-red-600"
                          sx={{ fontSize: 20 }}
                        ></CancelIcon>
                        &nbsp;Close
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </React.Fragment>
  );
});

export default NotificationModal;
