import React, { useState, useRef } from "react";
import { useSelector } from "react-redux";
import EditNoteIcon from "@mui/icons-material/EditNote";
import InfoOutlined from "@mui/icons-material/InfoRounded";
import Typography from "@mui/material/Typography";
import Popover from "@mui/material/Popover";
import InfoSharpIcon from "@mui/icons-material/InfoSharp";
import PrevEditCameraModal from "../PrevEditCameraModal";

export default function CameaList() {
  const [popoverMessage, setPopoverMessage] = useState(false);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const scanCameraList = useSelector(
    (state) => state.camera.scanCameraList
  ).filter((camera) => camera.status);
  const cameraList = useSelector((state) => state.camera.cameraList).filter(
    (camera) =>
      scanCameraList.some((scanCamera) => camera.mac === scanCamera.mac)
  );
  const editCameraModal = useRef();

  const handleEditMode = (camera) => {
    if (editCameraModal.current) {
      editCameraModal.current.openModal(camera);
    }
  };

  const handlePopoverOpen = (event, message) => {
    setPopoverMessage(message);
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = (timeToBeDisplay = 0) => {
    setTimeout(() => {
      setAnchorEl(null);
    }, timeToBeDisplay);
  };

  return (
    <React.Fragment>
      <div className="w-[100%]  mt-2 ml-4  pb-6 mb-4">
        <div className="flex flex-row mt-2 font-bold justify-center ">
          <h2>List Of Cameras:</h2>
        </div>
        <div className="flex flex-row mt-2 text-white font-bold  border-black bg-navy text-xs">
          <div className="w-[15%] font-semibold  py-2 px-1 text-center">
            Name
            <span className="pl-1">
              <InfoOutlined
                sx={{ fontSize: 15 }}
                className="bg-navy rounded-xl mb-1"
                onMouseEnter={(e) =>
                  handlePopoverOpen(e, "Name of the camera!")
                }
                onMouseLeave={() => handlePopoverClose(5000)}
              />
            </span>
          </div>
          <div className="w-[35%] font-semibold  py-2 px-1 text-center">
            Actions
            <span className="pl-1">
              <InfoOutlined
                sx={{ fontSize: 15 }}
                className="bg-navy rounded-xl mb-1"
                onMouseEnter={(e) =>
                  handlePopoverOpen(e, "Actions detected by the camera!")
                }
                onMouseLeave={() => handlePopoverClose(5000)}
              />
            </span>
          </div>
          <div className="w-[25%] font-semibold  py-2 px-1 text-center">
            Intent
            <span className="pl-1">
              <InfoOutlined
                sx={{ fontSize: 15 }}
                className="bg-navy rounded-xl mb-1"
                onMouseEnter={(e) =>
                  handlePopoverOpen(e, "Intent of the each action!")
                }
                onMouseLeave={() => handlePopoverClose(5000)}
              />
            </span>
          </div>
          <div className="w-[35%] font-semibold  py-2 px-1 text-center">
            Additional context
            <span className="pl-1">
              <InfoOutlined
                sx={{ fontSize: 15 }}
                className="bg-navy rounded-xl mb-1"
                onMouseEnter={(e) =>
                  handlePopoverOpen(e, "Additional context of the camera!")
                }
                onMouseLeave={() => handlePopoverClose(5000)}
              />
            </span>
          </div>
          <div className="w-[15%] font-semibold  py-2 px-1 text-center">
            Role
            <span className="pl-1">
              <InfoOutlined
                sx={{ fontSize: 15 }}
                className="bg-navy rounded-xl mb-1"
                onMouseEnter={(e) =>
                  handlePopoverOpen(e, "Role of the camera!")
                }
                onMouseLeave={() => handlePopoverClose(5000)}
              />
            </span>
          </div>
          <div className="w-[15%] font-semibold  py-2 px-1 text-center">
            IP
            <span className="pl-1">
              <InfoOutlined
                sx={{ fontSize: 15 }}
                className="bg-navy rounded-xl mb-1"
                onMouseEnter={(e) =>
                  handlePopoverOpen(e, "IP address of the camera!")
                }
                onMouseLeave={() => handlePopoverClose(5000)}
              />
            </span>
          </div>
          <div className="w-[10%] font-semibold  py-2 px-1 text-center">
            Port
            <span className="pl-1">
              <InfoOutlined
                sx={{ fontSize: 15 }}
                className="bg-navy rounded-xl mb-1"
                onMouseEnter={(e) => handlePopoverOpen(e, "Port of the camera")}
                onMouseLeave={() => handlePopoverClose(5000)}
              />
            </span>
          </div>
          <div className="w-[20%] font-semibold  py-2 px-1 text-center">
            Mac Address
            <span className="pl-1">
              <InfoOutlined
                sx={{ fontSize: 15 }}
                className="bg-navy rounded-xl mb-1"
                onMouseEnter={(e) =>
                  handlePopoverOpen(e, "Mac Address of the camera!")
                }
                onMouseLeave={() => handlePopoverClose(5000)}
              />
            </span>
          </div>
          <div className="w-[40%] font-semibold  py-2 px-1 text-center">
            Vendor
            <span className="pl-1">
              <InfoOutlined
                sx={{ fontSize: 15 }}
                className="bg-navy rounded-xl mb-1"
                onMouseEnter={(e) =>
                  handlePopoverOpen(e, "the brand of the camera!")
                }
                onMouseLeave={() => handlePopoverClose(5000)}
              />
            </span>
          </div>
          <div className="w-[10%] font-semibold  py-2 px-1 flex justify-center"></div>
        </div>
        <div className="border text-sm">
          {cameraList?.map((camera, i) => {
            return (
              <div
                className={`${
                  i % 2 === 0 ? "bg-gray-200" : "bg-white"
                } flex flex-row   border-none border-black `}
                key={i}
              >
                <div className="w-[15%]  py-2 px-1 text-start">
                  <span>{camera.name}</span>
                </div>
                <div className="w-[35%]  py-2 px-1 text-start">
                  {camera.action_types &&
                    [
                      ...new Set(
                        camera?.action_types?.map((action) => action?.name)
                      ),
                    ].join(", ")}
                </div>
                <div className="w-[25%]  py-2 px-1 text-center">
                  <p className="capitalize ">
                    {camera.action_types &&
                      [
                        ...new Set(
                          camera?.action_types?.map((action) => action?.intent)
                        ),
                      ].join(", ")}
                  </p>
                </div>
                <div className="w-[35%]  py-2 px-1 text-center">
                  {camera.additional_context}
                </div>
                <div className="w-[15%]  py-2 px-1 text-center">
                  <span>{camera.role}</span>
                </div>
                <div className="w-[15%]  py-2 px-1 text-center">
                  {camera.ip}
                </div>
                <div className="w-[10%]  py-2 px-1 text-center">
                  {camera.port}
                </div>
                <div className="w-[20%]  py-2 px-1 text-center">
                  {camera.mac}
                </div>
                <div className="w-[40%]  py-2 px-1 text-center">
                  {camera.vendorName}
                </div>
                <div className="w-[10%] py-6 px-1 grid place-items-center relative">
                  <button
                    className="bg-transparent hover:bg-light-navy font-semibold my-1 py-1 px-1 border border-current hover:border-transparent rounded-lg shadow-sm ring-1 ring-gray-300 absolute top-0"
                    onClick={() => handleEditMode(camera)}
                  >
                    <EditNoteIcon sx={{ fontSize: 25, color: "#26272f" }} />
                  </button>
                </div>
              </div>
            );
          })}
        </div>
      </div>
      <Popover
        open={open}
        anchorEl={anchorEl}
        onClose={() => handlePopoverClose(0)}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Typography sx={{ p: 0.5, fontSize: 11 }}>
          <InfoSharpIcon
            sx={{ fontSize: 15, color: "#30ac64" }}
          ></InfoSharpIcon>
          {popoverMessage}
        </Typography>
      </Popover>
      <PrevEditCameraModal ref={editCameraModal} />
    </React.Fragment>
  );
}
