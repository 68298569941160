import React, { useEffect, useRef, useState } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";

import moment from "moment";
import FunnelGraph from "funnel-graph-js";
import "funnel-graph-js/dist/css/main.min.css";
import "funnel-graph-js/dist/css/theme.min.css";
import { Select, Segmented } from "antd";

import analyticsApi from "../../api/analytics";
import { openModal, setErrorMessage } from "../../store/error/errorAction";

export default function SalesFunnel() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const cameraList = useSelector((state) => state.camera.cameraList);
  const [cameras, setCameras] = useState([]);
  const graphRef = useRef(null);
  const firstTime = useRef(true);
  const [
    overallConversionRateCurrentWeek,
    setOverallConversionRateCurrentWeek,
  ] = useState(0);
  const [
    overallConversionRatePreviusWeek,
    setOverallConversionRatePreviusWeek,
  ] = useState(0);
  const [showCamerasList, setShowCamerasList] = useState(false);
  const [width, setWidth] = useState(
    document.getElementById("funnelContainer")?.clientWidth
  );

  useEffect(() => {
    const createChart = async () => {
      const data = await generateSalesFunnelChartByWeeksModel();
      const options = getFunnelGraphOptions(data);
      const funnel = new FunnelGraph(options);
      graphRef.current = funnel;
      const funnelContainerHtml = document.getElementById("funnelContainer");

      if (graphRef.current && funnelContainerHtml) {
        graphRef.current.draw();
      }
    };

    if (firstTime.current) {
      window.addEventListener("resize", handleResize);
      createChart();
      firstTime.current = false;
    }

    setTimeout(() => {
      var duplicateLabels = document.getElementsByClassName(
        "svg-funnel-js__subLabels"
      );

      if (duplicateLabels.length > 1) {
        duplicateLabels[1].classList.add(
          "svg-funnel-js__subLabels_hiden_duplpicate"
        );
      }
    }, 600);
  });

  const generateSalesFunnelChartByWeeksModel = async () => {
    let endDate = new Date();
    let startDate = new Date(endDate);

    startDate = startDate.setDate(endDate.getDate() - 7);

    const currentWeekCameraActios =
      await analyticsApi.getfunnelSaleChartDataForAllCameras(
        startDate,
        endDate
      );

    let previusEndDate = new Date(endDate);
    previusEndDate.setDate(endDate.getDate() - 7);

    const previusStartDate = new Date(startDate);
    previusStartDate.setDate(new Date(startDate).getDate() - 7);

    const previousWeekCameraActios =
      await analyticsApi.getfunnelSaleChartDataForAllCameras(
        previusStartDate,
        previusEndDate
      );

    if (!currentWeekCameraActios.status || !previousWeekCameraActios.status) {
      const errorMessage = !currentWeekCameraActios.status
        ? currentWeekCameraActios.error
        : previousWeekCameraActios.error;
      openErrorModal(errorMessage);
    }

    const data = generateChartModelForAllCameras(
      currentWeekCameraActios.result,
      previousWeekCameraActios.result
    );

    return data;
  };

  const openErrorModal = (message) => {
    dispatch(setErrorMessage(message));
    dispatch(openModal());
  };

  const generateChartByCamerasModel = (cameraActios) => {
    let cameraNames = [];
    let colors = [];
    let opportunityList = [];
    let engagedList = [];
    let salesList = [];

    cameraActios?.forEach((cameraActio) => {
      const cameraInfo = cameraList.filter(
        (camera) => cameraActio.camera_mac === camera.mac
      )[0];

      cameraNames.push(cameraInfo.name);

      colors.push(getRandomColor());
      opportunityList.push(cameraActio.opportunity);
      engagedList.push(cameraActio.engage);
      salesList.push(cameraActio.sales);
    });

    let values = [];
    values.push(opportunityList);
    values.push(engagedList);
    values.push(salesList);

    const data = {
      labels: ["Opportunity", "Engaged", "Sales"],
      subLabels: cameraNames,
      colors: colors,
      values: values,
    };

    return data;
  };

  const handleResize = () => {
    const funnelContainer = document.getElementById("funnelContainer");

    if (funnelContainer) {
      setWidth(funnelContainer.clientWidth);
      graphRef?.current?.updateWidth(funnelContainer.clientWidth);
    }
  };

  const getFunnelGraphOptions = (data) => {
    const result = {
      container: "#funnelContainer",
      gradientDirection: "vertical",
      data,
      displayPercent: true,
      direction: "horizontal",
      width: width,
      height: 150,
      subLabelValue: "raw",
    };

    return result;
  };

  const getBasicFilterModel = () => {
    let model = {};
    const endDate = new Date();
    const startDate = new Date().setDate(endDate.getDate() - 7);

    model.intent = "analytical";
    model.action_type_ids = [];
    model.start_time = moment(startDate).format("YYYY-MM-DD 00:00:00");
    model.end_time = moment(endDate).format("YYYY-MM-DD 23:59:59");

    return model;
  };

  const gotoEventPage = (event, category) => {
    event.preventDefault();
    let model = getBasicFilterModel();

    switch (category) {
      case "Opportunity":
        model.action_type_ids = [14, 7, 9];
        break;
      case "Engaged":
        model.action_type_ids = [2];
        break;
      case "Sales":
        model.action_type_ids = [10];
        break;
      default:
        model.action_type_ids = [9, 2, 10, 14, 7];
        break;
    }

    navigate("/events", {
      state: {
        filterModel: model,
      },
    });
  };

  const getRandomColor = () => {
    // Generate random values for red, green, and blue components
    const red = Math.floor(Math.random() * 256)
      .toString(16)
      .padStart(2, "0"); // Random integer between 0 and 255
    const green = Math.floor(Math.random() * 256)
      .toString(16)
      .padStart(2, "0");
    const blue = Math.floor(Math.random() * 256)
      .toString(16)
      .padStart(2, "0");

    // Construct the color string in hexadecimal format
    const color = `#${red}${green}${blue}`;

    return [color];
  };

  const generateChartModelForAllCameras = (
    currentWeekCameraActios,
    previousWeekCameraActios
  ) => {
    let values = [];
    values.push([
      currentWeekCameraActios.opportunity,
      previousWeekCameraActios.opportunity,
    ]);
    values.push([
      currentWeekCameraActios.engaged,
      previousWeekCameraActios.engaged,
    ]);
    values.push([
      currentWeekCameraActios.sales,
      previousWeekCameraActios.sales,
    ]);

    const data = {
      labels: ["Opportunity", "Engaged", "Sales"],
      subLabels: ["Current Week", "Previous Week"],
      colors: ["#0b4f24", "#30ac64"],
      values: values,
    };

    setOverallConversionRateCurrentWeek(
      (
        (currentWeekCameraActios.sales / currentWeekCameraActios.opportunity) *
        100
      ).toFixed(2)
    );
    setOverallConversionRatePreviusWeek(
      (
        (previousWeekCameraActios.sales /
          previousWeekCameraActios.opportunity) *
        100
      ).toFixed(2)
    );

    return data;
  };

  const updateChartByCameras = async (dataModel) => {
    const cameraActios = await analyticsApi.getfunnelSaleChartDataByCameras(
      dataModel.ListOfCameraMac
    );

    if (cameraActios.status) {
      const data = generateChartByCamerasModel(cameraActios.result);
      const options = getFunnelGraphOptions(data);

      graphRef.current.update(options);

      setTimeout(() => {
        graphRef.current.update(options);
      }, 6);
    } else {
      openErrorModal(cameraActios.error);
    }
  };

  const updateChartByWeeks = async (dataModel) => {
    const data = await generateSalesFunnelChartByWeeksModel();
    const options = getFunnelGraphOptions(data);

    graphRef.current.update(options);

    setTimeout(() => {
      graphRef.current.update(options);
    }, 6);
  };

  const handleNotificationTypeSelectChange = (cameraIDs) => {
    const selectedCameras = cameraList.filter((camera) =>
      cameraIDs.includes(camera.uuid)
    );

    setCameras(selectedCameras);
    updateChartByCameras({
      ListOfCameraNames: selectedCameras.map((camera) => camera.name),
      ListOfCameraMac: selectedCameras.map((camera) => camera.mac),
    });
  };

  const setChartByCameras = () => {
    updateChartByCameras([]);
    setShowCamerasList(true);
  };

  const setChartByWeek = () => {
    updateChartByWeeks();
    setShowCamerasList(false);
  };

  return (
    <div className="w-full card grid grid-cols-8">
      <div className="col-span-8 space-y-3 mx-6 my-4 text-center">
        <h2>Sales Analytics</h2>
        <Segmented
          value={!showCamerasList ? "Weekly Comparison" : "By Cameras"}
          options={["Weekly Comparison", "By Cameras"]}
          onChange={(value) =>
            value === "Weekly Comparison"
              ? setChartByWeek()
              : setChartByCameras()
          }
          block
          size="large"
        />
      </div>

      <div className="col-span-8 mx-6 mb-8">
        {showCamerasList && (
          <Select
            className="w-full"
            placeholder="Select a Camera"
            value={cameras.map((camera) => camera.uuid)}
            options={cameraList}
            fieldNames={{ label: "name", value: "uuid" }}
            onChange={(selectCamera) => {
              handleNotificationTypeSelectChange(selectCamera);
            }}
            mode="multiple"
            showSearch
            size="large"
            variant="filled"
          />
        )}
      </div>

      <div className="col-span-8 py-2">
        <div className="flex">
          <div
            id="funnelContainer"
            className="flex w-full space-x-4 grow"
          ></div>
        </div>
      </div>

      {!showCamerasList && (
        <div className="mt-4 col-span-8">
          <div className="flex justify-center">
            <span className="flex space-x-4 text-sm mx-2 text-guardis-900 dark:text-guardis-400">
              Previous week overall conversion rate:&nbsp;
              <strong>{overallConversionRatePreviusWeek}%</strong>
            </span>
            <span className="flex space-x-4 text-sm mx-2 text-guardis dark:text-guardis-100">
              Current week overall conversion rate:&nbsp;
              <strong>{overallConversionRateCurrentWeek}%</strong>
            </span>
          </div>
        </div>
      )}

      <div className="col-span-8 mt-2 mb-8">
        <div className="flex justify-center">
          <ul className="flex space-x-4 list-disc text-neutral-600 dark:text-neutral-300">
            <li className="pr-4">
              <a href="/#" onClick={(e) => gotoEventPage(e, "")}>
                All
              </a>
            </li>
            <li className="pr-4">
              <a href="/#" onClick={(e) => gotoEventPage(e, "Opportunity")}>
                Opportunity
              </a>
            </li>
            <li className="pr-4">
              <a href="/#" onClick={(e) => gotoEventPage(e, "Engaged")}>
                Engaged
              </a>
            </li>
            <li>
              <a href="/#" onClick={(e) => gotoEventPage(e, "Sales")}>
                Sales
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}
