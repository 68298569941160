import React, { useEffect, useState, useRef } from "react";
import { PieChart, Pie, Cell, ResponsiveContainer } from "recharts";
import analyticsApi from "../../api/analytics";
import { useSelector, useDispatch } from "react-redux";
import VideoCameraFrontOutlinedIcon from "@mui/icons-material/VideoCameraFrontOutlined";
import { Select } from "antd";
import CalendarMonthIcon from "@mui/icons-material/CalendarMonth";
import { openModal, setErrorMessage } from "../../store/error/errorAction";
import { useNavigate } from "react-router-dom";
import moment from "moment";
import DateRangeSelectorModal from "../DateRangeSelector";
import Switch from "@mui/material/Switch";

const COLORS = ["#ff0000", "#FFBB28"];
const CameraActivitySummary = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const cameraList = useSelector((state) => state.camera.cameraList);
  const [camerasActivitySummaryData, setcamerasActivitySummaryData] = useState(
    []
  );
  const [activitySummaryByCamera, setActivitySummaryByCamera] = useState([]);
  const [camera, setCamera] = useState([]);
  const firstTime = useRef(true);
  const [selectedTab, setSelectedTab] = useState("week");
  const dateRangeSelectorModal = useRef();
  const [checked, setChecked] = useState(true);

  useEffect(() => {
    const loadData = async (selectedCamera) => {
      const startDate = new Date();
      startDate.setDate(startDate.getDate() - 7);

      await getaAtivitySummaryData(startDate, selectedCamera);
    };

    if (firstTime.current && cameraList.length > 0) {
      if (cameraList.length > 0) {
        const selectedCamera = cameraList[0];
        setCamera(selectedCamera);
        loadData(selectedCamera);
        firstTime.current = false;
      }
    }
  }, [cameraList]);

  const showErrorModal = (message) => {
    dispatch(setErrorMessage(message));
    dispatch(openModal());
  };

  const getaAtivitySummaryData = async (
    startDate,
    selectedCamera,
    endDate = null
  ) => {
    const data = await analyticsApi.getActivitySummaryData(startDate, endDate);
    if (data.status) {
      setcamerasActivitySummaryData(data.result);

      const activitySummaryByCamera = data.result.find(
        (data) => data.cameraMac === selectedCamera.mac
      );

      setActivitySummaryByCamera(activitySummaryByCamera);
    } else {
      showErrorModal(data.error);
    }
  };

  const onFrequencyChange = (selectedFrequency) => {
    const startDate = getTimePeriod(selectedFrequency);
    getaAtivitySummaryData(startDate, camera);
  };

  const getTimePeriod = (selectedFrequency) => {
    const dateNow = new Date();
    let startDate;

    switch (selectedFrequency) {
      case "week":
        startDate = new Date();
        startDate.setDate(dateNow.getDate() - 7);
        break;
      case "fifteen":
        startDate = new Date();
        startDate.setDate(dateNow.getDate() - 14);
        break;
      case "thirty":
        startDate = new Date();
        startDate.setMonth(dateNow.getMonth() - 1);
        break;
      default:
        throw new Error("Filter not implemented!");
    }

    return startDate;
  };

  const handleCameraChange = (uuid) => {
    const cameraData = cameraList.find((camera) => camera.uuid === uuid);
    setCamera(cameraData);

    const activitySummaryByCamera = camerasActivitySummaryData.find(
      (data) => data.cameraMac === cameraData.mac
    );

    setActivitySummaryByCamera(activitySummaryByCamera);
  };

  const renderCustomizedLabel = ({
    cx,
    cy,
    midAngle,
    innerRadius,
    outerRadius,
    percent,
    index,
  }) => {
    if (index === 1) {
      return "";
    }
    const RADIAN = Math.PI / 180;
    const radius = innerRadius + (outerRadius - innerRadius) * 2;
    const x = cx + radius * Math.cos(-midAngle * RADIAN);
    const y = cy + radius * Math.sin(-midAngle * RADIAN);

    return (
      <text
        x={x}
        y={y}
        fill="#16a34a"
        textAnchor={x > cx ? "start" : "end"}
        dominantBaseline="central"
      >
        {`${(percent * 100).toFixed(1)}%`}
      </text>
    );
  };

  const handleTabClick = (e, tab) => {
    e.preventDefault();
    setSelectedTab(tab);

    onFrequencyChange(tab);
  };

  const getFilterModel = (selectedFrequency) => {
    const startDate = getTimePeriod(selectedFrequency);
    const endDate = new Date();

    const model = {
      start_time: moment(startDate).format("YYYY-MM-DD HH:00:00"),
      end_time: moment(endDate).format("YYYY-MM-DD 11:59:59"),
      camera_id: camera.mac,
    };

    return model;
  };

  const handleClick = (e, action_type_id) => {
    e.preventDefault();
    const model = getFilterModel(selectedTab);
    model.action_type_ids = [action_type_id];

    navigate("/events", {
      state: {
        filterModel: model,
      },
    });
  };

  const handleCustomClick = (e, tab) => {
    e.preventDefault();
    setSelectedTab(tab);
    if (dateRangeSelectorModal.current) {
      dateRangeSelectorModal.current.openModal();
    }
  };

  const selectedRange = (result) => {
    if (result.isOk) {
      getaAtivitySummaryData(result.startDate, camera, result.endDate);
    }
  };

  const handleSwitchChange = (event) => {
    setChecked(event.target.checked);
  };

  return (
    <div className="card min-w-96 flex flex-1 flex-col pt-6 pb-10">
      <h2 className="px-3 mb-2">Camera Activity Summary</h2>
      <div className="grid grid-cols-12">
        <div className="col-span-12 text-sm text-nowrap">
          <ul className="flex border-b border-neutral-200 dark:border-neutral-400 text-neutral-400">
            <li
              className={`ml-2 mr-1 ${
                selectedTab === "week"
                  ? "border-l border-t border-r rounded-t border-neutral-200 dark:border-neutral-400"
                  : ""
              }`}
            >
              <a
                href="/#"
                onClick={(e) => handleTabClick(e, "week")}
                className={`inline-block py-2 px-2 font-semibold ${
                  selectedTab === "week"
                    ? "text-guardis-600 dark:text-guardis-300"
                    : "hover:text-guardis-800 dark:hover:text-guardis-400"
                }`}
              >
                Last 7 Days
              </a>
            </li>
            <li
              className={`mr-1 ${
                selectedTab === "fifteen"
                  ? "border-l border-t border-r rounded-t border-neutral-200 dark:border-neutral-400"
                  : ""
              }`}
            >
              <a
                href="/#"
                onClick={(e) => handleTabClick(e, "fifteen")}
                className={`inline-block py-2 px-2 font-semibold ${
                  selectedTab === "fifteen"
                    ? "text-guardis-600 dark:text-guardis-300"
                    : "hover:text-guardis-800 dark:hover:text-guardis-400"
                }`}
              >
                Last 15 Days
              </a>
            </li>
            <li
              className={`mr-1 ${
                selectedTab === "thirty"
                  ? "border-l border-t border-r rounded-t border-neutral-200 dark:border-neutral-400"
                  : ""
              }`}
            >
              <a
                href="/#"
                onClick={(e) => handleTabClick(e, "thirty")}
                className={`inline-block py-2 px-2 font-semibold ${
                  selectedTab === "thirty"
                    ? "text-guardis-600 dark:text-guardis-300"
                    : "hover:text-guardis-800 dark:hover:text-guardis-400"
                }`}
              >
                Last 30 Days
              </a>
            </li>
            <li
              className={`mr-1 ${
                selectedTab === "custom"
                  ? "border-l border-t border-r rounded-t border-neutral-200 dark:border-neutral-400"
                  : ""
              }`}
            >
              <a
                href="/#"
                onClick={(e) => handleCustomClick(e, "custom")}
                className={`inline-block py-2 px-2 font-semibold ${
                  selectedTab === "custom"
                    ? "text-guardis-600 dark:text-guardis-300"
                    : "hover:text-guardis-800 dark:hover:text-guardis-400"
                }`}
              >
                <CalendarMonthIcon
                  fontSize="small"
                  className="mb-1 mr-1"
                ></CalendarMonthIcon>
                Custom
              </a>
            </li>
          </ul>
        </div>
        <div className="col-span-12 text-start mt-2 mb-1">
          <div className="flex p-3 mr-2">
            <Select
              className="grow"
              fieldNames={{ label: "name", value: "uuid" }}
              placeholder={"Select a Camera"}
              onChange={(selectCamera) => {
                handleCameraChange(selectCamera);
              }}
              options={cameraList}
              value={camera.uuid}
              showSearch
            />
          </div>
        </div>
        {camera?.action_types
          ?.filter(
            (actionType) =>
              (checked && actionType.intent === "monitoring") || !checked
          )
          .sort((a, b) => {
            if (a.name && b.name) {
              return a.name.localeCompare(b.name);
            }
            return a;
          })
          .map((actionType, index) => {
            const activity = activitySummaryByCamera?.actions?.find(
              (activity) => activity.id === actionType.id
            );

            const total = activitySummaryByCamera?.actions?.reduce(
              (accumulator, currentValue) => accumulator + currentValue.value,
              0
            );

            const activityValue = activity?.value ? activity.value : 0;
            const pieChartData = [
              { name: actionType.name, value: activityValue },
              { name: "Others", value: total - activityValue },
            ];

            return (
              <React.Fragment key={`section-${index}`}>
                <div className="col-span-1 text-center text-[#ff7f00] mt-1">
                  {index === 0 && (
                    <VideoCameraFrontOutlinedIcon></VideoCameraFrontOutlinedIcon>
                  )}
                </div>
                <div className="col-span-3 text-start font-semibold text-sm mt-2">
                  {index === 0 && <span>{camera.name}</span>}
                </div>
                <div className="col-span-8 text-start font-semibold text-sm ">
                  {index === 0 && (
                    <React.Fragment>
                      <p className="font-semibold text-sm text-[#0a638b]">
                        <Switch
                          checked={checked}
                          onChange={handleSwitchChange}
                        />
                        {`${checked ? "Monitoring" : "All"}`}
                      </p>
                    </React.Fragment>
                  )}
                </div>
                <div className="col-span-4 text-end pt-1 text-nowrap text-sm font-semibold text-slate-500 inline-block mt-2">
                  {actionType.name} :&nbsp;
                </div>
                <div className="col-span-2 text-start pt-1 text-nowrap text-sm text-green-600 font-bold inline-block mt-2">
                  {activityValue === 0 ? (
                    <p>{activityValue}</p>
                  ) : (
                    <a href="/#" onClick={(e) => handleClick(e, actionType.id)}>
                      {activityValue}
                    </a>
                  )}
                </div>
                <div className="col-span-4 text-center text-nowrap text-sm text-green-600 font-bold">
                  <div className="w-full h-12">
                    <ResponsiveContainer>
                      <PieChart>
                        <Pie
                          data={pieChartData}
                          cx="60%"
                          cy="65%"
                          innerRadius={10}
                          outerRadius={17}
                          fill="#8884d8"
                          paddingAngle={30}
                          dataKey="value"
                          labelLine={false}
                          label={renderCustomizedLabel}
                        >
                          {pieChartData.map((entry, index) => (
                            <Cell
                              key={`cell-${index}`}
                              fill={COLORS[index % COLORS.length]}
                            />
                          ))}
                        </Pie>
                      </PieChart>
                    </ResponsiveContainer>
                  </div>
                </div>
              </React.Fragment>
            );
          })}
      </div>
      <DateRangeSelectorModal
        SelectedRange={selectedRange}
        ref={dateRangeSelectorModal}
      ></DateRangeSelectorModal>
    </div>
  );
};

export default CameraActivitySummary;
