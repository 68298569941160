import axios from "../extension/axios";
import authService from "../services/authService";
import moment from "moment";

const analyticsApi = {
  getfunnelSaleChartDataForAllCameras: async (startDate, endDate) => {
    const model = {
      start_time: moment(startDate).format("YYYY-MM-DD 00:00:00"),
      end_time: moment(endDate).format("YYYY-MM-DD 23:59:59"),
      time_period: "day",
      event_types: [9, 2, 10, 14, 7],
    };

    const request = axios
      .post(`${authService.getUnitUrl()}analytics/period`, model)
      .then(function (response) {
        const data = {};

        response.data.forEach((days) => {
          Object.entries(days).forEach(([key, value]) => {
            data[key] = (data[key] || 0) + value;
          });
        });

        const result = {
          opportunity: data[14] + data[7] + data[9],
          engaged: data[2],
          sales: data[10],
        };

        return { result, status: true };
      })
      .catch(function (error) {
        console.log(error);
        const message =
          "There is an error getting funnel sale chart data, please see the browser console for more details!";
        return { result: [], status: false, error: message };
      });

    return await request;
  },
  getfunnelSaleChartDataByCameras: async (cameramacList) => {
    const endDate = new Date();
    const startDate = new Date().setDate(endDate.getDate() - 7);
    const model = {
      start_time: moment(startDate).format("YYYY-MM-DD 00:00:00"),
      end_time: moment(endDate).format("YYYY-MM-DD 23:59:59"),
      time_period: "day",
    };

    const request = axios
      .post(`${authService.getUnitUrl()}analytics/camera/period`, model)
      .then(function (response) {
        let result = [];
        let cameraActionByDay = [];
        const funnelSaleActionTypes = [9, 2, 10, 14, 7];

        response.data.forEach((days) => {
          Object.entries(days).forEach(([key, value]) => {
            let model = { cameraMac: key, actions: [] };
            Object.entries(value).forEach(([key, value]) => {
              if (
                funnelSaleActionTypes.some(
                  (actionTypesId) => actionTypesId === parseInt(key)
                )
              ) {
                model.actions.push({ id: key, count: value });
              }
            });
            if (model.actions.length > 0) {
              cameraActionByDay.push(model);
            }
          });
        });

        if (cameramacList?.length > 0) {
          cameraActionByDay = cameraActionByDay.filter((camera) =>
            cameramacList.some((mac) => mac === camera.cameraMac)
          );
        }

        const actionsByCamera = Object.groupBy(
          cameraActionByDay,
          (camera) => camera.cameraMac
        );

        Object.entries(actionsByCamera).forEach(([key, values]) => {
          const data = values.reduce((acc, item) => {
            item.actions.forEach((action) => {
              const { id, count } = action;
              if (!acc[id]) {
                acc[id] = 0;
              }
              acc[id] += count;
            });
            return acc;
          }, {});

          const engage = data[2] ? data[2] : 0;
          const opportunity =
            (data[14] ? data[14] : 0) +
            (data[7] ? data[7] : 0) +
            (data[9] ? data[9] : 0);

          result.push({
            camera_mac: key,
            opportunity: opportunity,
            engage: engage,
            sales: data[10] ? data[10] : 0,
          });
        });

        return { result, status: true };
      })
      .catch(function (error) {
        console.log(error);
        const message =
          "There is an error getting funnel sale chart data, please see the browser console for more details!";
        return { status: false, error: message };
      });

    return await request;
  },
  getBadgesGraphData: async (startDate, endDate) => {
    const model = {
      start_time: moment(startDate).format("YYYY-MM-DD HH:mm:ss"),
      end_time: moment(endDate).format("YYYY-MM-DD HH:mm:ss"),
      time_period: "hour",
    };

    const request = axios
      .post(`${authService.getUnitUrl()}analytics/period`, model)
      .then(function (response) {
        const result = [];

        // Process each hour's events
        response.data.forEach((hourlyEvents, hour) => {
          const enterStoreCount = hourlyEvents[7] || 0;
          const leaveStoreCount = hourlyEvents[8] || 0;
          const itemPickingCount = hourlyEvents[9] || 0;
          const payCheckoutCount = hourlyEvents[10] || 0;
          const customerBrowsingCount = hourlyEvents[14] || 0;
          const footTraffic = customerBrowsingCount;
          const itemSelectionRate = itemPickingCount;
          const salesRate =
            footTraffic === 0 ? 0 : payCheckoutCount / footTraffic;

          result.push({
            hour,
            "Enter Store": enterStoreCount,
            "Leave Store": leaveStoreCount,
            "Item Picking": itemPickingCount,
            "Pay/Checkout": payCheckoutCount,
            "Customer Browsing": customerBrowsingCount,
            "Foot Traffic": footTraffic,
            "Item Selection Rate": itemSelectionRate,
            "Sales Rate": salesRate,
          });
        });

        return { result, status: true };
      })
      .catch(function (error) {
        console.log(error);
        const message =
          "There is an error getting badges chart data, please see the browser console for more details!";
        return { status: false, error: message };
      });

    return await request;
  },
  getActionChartData: async (startDate, endDate = new Date(), timePeriod) => {
    const requestData = {
      start_time: moment(startDate).format("YYYY-MM-DD HH:mm:ss"),
      end_time: moment(endDate).format("YYYY-MM-DD HH:mm:ss"),
      time_period: timePeriod,
    };

    let response;

    try {
      response = await axios.post(
        `${authService.getUnitUrl()}analytics/period`,
        requestData
      );
    } catch (error) {
      console.log(error);
      const message = "There is an error getting activity chart data.";
      return { status: false, error: message };
    }

    // create dict to store each action's data
    let data = {};
    for (let i = 0; i <= 18; i++) {
      data[i] = [];
    }

    response.data.forEach((events, i) => {
      const totalActions = Object.values(events).reduce(
        (sum, value) => sum + value,
        0
      );

      let date = moment(startDate);
      let timeLabel;

      switch (timePeriod) {
        case "week":
          date.add(i, "weeks");
          timeLabel = getWeekRangeLabel(date, moment(endDate));
          break;
        case "day":
          date.add(i, "days");
          timeLabel = date.format("dddd, MMMM D");
          break;
        case "month":
          date.add(i, "months");
          timeLabel = date.format("MMMM");
          break;
        case "hour":
          date.add(i, "hours");
          timeLabel = date.format("hA");
          break;
        default:
          break;
      }

      for (let key = 0; key <= 18; key++) {
        if (key in events) {
          let count = events[key];
          data[key].push({
            label: timeLabel,
            count,
            percentage:
              count > 0 ? Number(((count / totalActions) * 100).toFixed(2)) : 0,
          });
        }
      }
    });

    return { status: true, data };
  },
  getActivitySummaryData: async (startDate, endDate = null) => {
    endDate = endDate == null ? new Date() : endDate;
    const model = {
      start_time: moment(startDate).format("YYYY-MM-DD 00:00:00"),
      end_time: moment(endDate).format("YYYY-MM-DD 23:59:59"),
      time_period: "day",
    };

    const request = axios
      .post(`${authService.getUnitUrl()}analytics/camera/period`, model)
      .then(function (response) {
        let result = [];
        let cameraActionByDay = [];

        response.data.forEach((days) => {
          Object.entries(days).forEach(([key, value]) => {
            let model = { cameraMac: key, actions: [] };
            Object.entries(value).forEach(([key, value]) => {
              model.actions.push({ id: key, count: value });
            });

            if (model.actions.length > 0) {
              cameraActionByDay.push(model);
            }
          });
        });

        const actionsByCamera = Object.groupBy(
          cameraActionByDay,
          (camera) => camera.cameraMac
        );

        Object.entries(actionsByCamera).forEach(([key, values]) => {
          const data = values.reduce((acc, item) => {
            item.actions.forEach((action) => {
              const { id, count } = action;

              if (!acc[id]) {
                acc[id] = { id: parseInt(id), value: 0 };
              }
              acc[id].value += count;
            });
            return acc;
          }, {});

          let actions = [];
          Object.keys(data).forEach((key) => {
            actions.push(data[key]);
          });

          result.push({
            cameraMac: key,
            actions: actions,
          });
        });

        return { result, status: true };
      })
      .catch(function (error) {
        console.log(error);
        const message =
          "There is a error getting activity summary chart data, please see the browser console for more details!";
        return { status: false, error: message };
      });

    return await request;
  },
  getMostActiveHours: async (startDate, endDate) => {
    const requestData = {
      start_time: moment(startDate).format("YYYY-MM-DD HH:mm:ss"),
      end_time: moment(endDate).format("YYYY-MM-DD HH:mm:ss"),
      time_period: "hour",
    };

    let response;

    try {
      response = await axios.post(
        `${authService.getUnitUrl()}analytics/period`,
        requestData
      );
    } catch (error) {
      console.log(error);
      const message = "There was an error getting active hours data.";
      return { status: false, error: message };
    }

    // find most active hour for each day
    const hoursInDay = 24;
    const hoursCount = response.data.length;
    const daysCount = Math.ceil(hoursCount / hoursInDay);

    let mostActiveHoursData = Array.from({ length: daysCount }, () => ({
      date: null,
      actions: 0,
    }));

    let totalEvents = response.data.map((events) =>
      Object.values(events).reduce((a, b) => a + b, 0)
    );

    totalEvents.forEach((actionsDetected, hour) => {
      let day = Math.floor(hour / hoursInDay);
      let currentDate = new Date(startDate);
      currentDate.setHours(startDate.getHours() + hour);

      if (
        mostActiveHoursData[day].date == null ||
        actionsDetected > mostActiveHoursData[day].actions
      ) {
        mostActiveHoursData[day].date = currentDate;
        mostActiveHoursData[day].actions = actionsDetected;
      }
    });

    return { status: true, data: mostActiveHoursData };
  },
  getPeakHours: async (startDate, endDate) => {
    const requestData = {
      start_time: moment(startDate).format("YYYY-MM-DD HH:mm:ss"),
      end_time: moment(endDate).format("YYYY-MM-DD HH:mm:ss"),
      time_period: "hour",
      event_types: [7, 8],
    };

    let response;

    try {
      response = await axios.post(
        `${authService.getUnitUrl()}analytics/period`,
        requestData
      );
    } catch (error) {
      console.log(error);
      const message = "There was an error getting peak hours data.";
      return { status: false, error: message };
    }

    let footTrafficData = {
      sundays: {},
      mondays: {},
      tuesdays: {},
      wednesdays: {},
      thursdays: {},
      fridays: {},
      saturdays: {},
    };

    const weekdayKeys = {
      0: "sundays",
      1: "mondays",
      2: "tuesdays",
      3: "wednesdays",
      4: "thursdays",
      5: "fridays",
      6: "saturdays",
    };

    response.data.forEach((events, h) => {
      let date = new Date(startDate);
      date.setHours(startDate.getHours() + h);

      let enterStoreCount = events[7] || 0;
      let leaveStoreCount = events[8] || 0;
      let footTraffic = Math.max(enterStoreCount, leaveStoreCount);

      let weekdayNumber = date.getDay();
      let weekday = weekdayKeys[weekdayNumber];
      let hour = moment(date).format("hA");

      if (hour in footTrafficData[weekday]) {
        footTrafficData[weekday][hour]["sum"] += footTraffic;
        footTrafficData[weekday][hour]["count"] += 1;
      } else {
        footTrafficData[weekday][hour] = { sum: footTraffic, count: 1 };
      }
    });

    let peakHoursData = {};
    let earliestHour = null;
    let latestHour = null;
    let highestFootTraffic = null;

    for (const [weekday, weekdayData] of Object.entries(footTrafficData)) {
      let sortedData = [];

      const date = new Date();
      date.setHours(0, 0, 0, 0);
      const hoursInDay = 24;

      for (let h = 0; h < hoursInDay; h++) {
        date.setHours(h);
        const hour = moment(date).format("hA");

        if (hour in weekdayData && weekdayData[hour].sum !== 0) {
          const num = weekdayData[hour].sum / weekdayData[hour].count;
          const averageFootTraffic = Number(num.toFixed(2));

          if (!earliestHour || date < earliestHour) {
            earliestHour = date;
          }

          if (!latestHour || date < latestHour) {
            latestHour = date;
          }

          if (!highestFootTraffic || averageFootTraffic > highestFootTraffic) {
            highestFootTraffic = Math.ceil(averageFootTraffic);
          }

          sortedData.push({
            hour,
            averageFootTraffic,
          });
        } else {
          sortedData.push({ hour });
        }
      }

      peakHoursData[weekday] = sortedData;
    }

    peakHoursData["earliestHour"] = earliestHour;
    peakHoursData["latestHour"] = latestHour;
    peakHoursData["highestFootTraffic"] = highestFootTraffic;

    return { status: true, data: peakHoursData };
  },
  getActionsChange: async (startDate, endDate, eventTypes, timePeriod) => {
    const requestData = {
      start_time: moment(startDate).format("YYYY-MM-DD HH:mm:ss"),
      end_time: moment(endDate).format("YYYY-MM-DD HH:mm:ss"),
      time_period: timePeriod,
      event_types: eventTypes,
    };

    let response;

    try {
      response = await axios.post(
        `${authService.getUnitUrl()}analytics/period`,
        requestData
      );
    } catch (error) {
      console.log(error);
      const message = "There was an error getting actions change data.";
      return { status: false, error: message };
    }

    const actionsChangeData = [];

    const getInitialValue = (index) => {
      return response.data[0] && response.data[0][index] !== undefined
        ? response.data[0][index]
        : null;
    };

    const initialActivityAfterHours = getInitialValue(1);
    const initialBagging = getInitialValue(2);
    const initialPocketing = getInitialValue(3);
    const initialCashTheft = getInitialValue(4);
    const initialMishandlingDocuments = getInitialValue(5);
    const initialShoplift = getInitialValue(6);
    const initialEnterStore = getInitialValue(7);
    const initialLeaveStore = getInitialValue(8);
    const initialItemPicking = getInitialValue(9);
    const initialPayCheckout = getInitialValue(10);
    const initialCheckDocumentHandling = getInitialValue(11);
    const initialMoneyHandling = getInitialValue(12);
    const initialPhoneEngagement = getInitialValue(13);
    const initialCustomerBrowsing = getInitialValue(14);
    const initialIdle = getInitialValue(15);
    const initialNoAction = getInitialValue(16);
    const initialNormal = getInitialValue(17);
    const initialUndecided = getInitialValue(18);

    response.data.forEach((events, t) => {
      let date = moment(startDate);
      let timeLabel;

      switch (timePeriod) {
        case "hour":
          date.add(t, "hours");
          timeLabel = date.format("hA");
          break;
        case "day":
          date.add(t, "days");
          timeLabel = date.format("dddd, MMMM D");
          break;
        case "week":
          date.add(t, "weeks");
          timeLabel = getWeekRangeLabel(date, moment(endDate));
          break;
        case "month":
        default:
          date.add(t, "months");
          timeLabel = date.format("MMMM");
          break;
      }

      const calculatePercentage = (initialValue, currentValue) => {
        if (initialValue === null) {
          return null;
        }

        if (initialValue === 0 && initialValue === currentValue) {
          return 0;
        }

        if (initialValue === 0 && initialValue < currentValue) {
          return 100;
        }

        return Number(
          (((currentValue - initialValue) / initialValue) * 100).toFixed(2)
        );
      };

      let percentageData = {
        time: timeLabel,
      };

      const addIfNotNull = (key, initialValue, currentValue) => {
        const value = calculatePercentage(initialValue, currentValue);
        if (value !== null) {
          percentageData[key] = value;
        }
      };

      addIfNotNull("activityAfterHours", initialActivityAfterHours, events[1]);
      addIfNotNull("bagging", initialBagging, events[2]);
      addIfNotNull("pocketing", initialPocketing, events[3]);
      addIfNotNull("cashTheft", initialCashTheft, events[4]);
      addIfNotNull(
        "mishandlingDocuments",
        initialMishandlingDocuments,
        events[5]
      );
      addIfNotNull("shoplift", initialShoplift, events[6]);
      addIfNotNull("enterStore", initialEnterStore, events[7]);
      addIfNotNull("leaveStore", initialLeaveStore, events[8]);
      addIfNotNull("itemPicking", initialItemPicking, events[9]);
      addIfNotNull("payCheckout", initialPayCheckout, events[10]);
      addIfNotNull(
        "checkDocumentHandling",
        initialCheckDocumentHandling,
        events[11]
      );
      addIfNotNull("moneyHandling", initialMoneyHandling, events[12]);
      addIfNotNull("phoneEngagement", initialPhoneEngagement, events[13]);
      addIfNotNull("customerBrowsing", initialCustomerBrowsing, events[14]);
      addIfNotNull("idle", initialIdle, events[15]);
      addIfNotNull("noAction", initialNoAction, events[16]);
      addIfNotNull("normal", initialNormal, events[17]);
      addIfNotNull("undecided", initialUndecided, events[18]);

      actionsChangeData.push(percentageData);
    });

    return { status: true, data: actionsChangeData };
  },
  getAverageActionsDetected: async (startDate, endDate) => {
    const requestData = {
      start_time: moment(startDate).format("YYYY-MM-DD HH:mm:ss"),
      end_time: moment(endDate).format("YYYY-MM-DD HH:mm:ss"),
      time_period: "day",
    };

    let response;

    try {
      response = await axios.post(
        `${authService.getUnitUrl()}analytics/period`,
        requestData
      );
    } catch (error) {
      console.log(error);
      const message = "There was an error getting scatter plot data.";
      return { status: false, error: message };
    }

    // Initialize scatterData with all month-weekday combinations
    let scatterData = [];

    const months = [
      "January",
      "February",
      "March",
      "April",
      "May",
      "June",
      "July",
      "August",
      "September",
      "October",
      "November",
      "December",
    ];

    const weekdays = [
      "Sunday",
      "Monday",
      "Tuesday",
      "Wednesday",
      "Thursday",
      "Friday",
      "Saturday",
    ];

    months.forEach((month) => {
      weekdays.forEach((weekday) => {
        scatterData.push({
          month,
          weekday,
          averageActionsDetected: 0,
          totalActionsDetected: 0,
          count: 0,
        });
      });
    });

    // Calculate average actions detected
    let totalEvents = response.data.map((events) =>
      Object.values(events).reduce((a, b) => a + b, 0)
    );

    totalEvents.forEach((actionsDetected, dayIndex) => {
      let date = moment(startDate).add(dayIndex, "days");
      let month = date.format("MMMM");
      let weekday = date.format("dddd");

      // Find the corresponding entry in scatterData and update it
      let dataPoint = scatterData.find(
        (point) => point.month === month && point.weekday === weekday
      );

      if (dataPoint) {
        dataPoint.totalActionsDetected += actionsDetected;
        dataPoint.count += 1;

        let average = dataPoint.totalActionsDetected / dataPoint.count;
        dataPoint.averageActionsDetected = average;
      }
    });

    // Find maximum actions detected
    let maxAverageActionsDetected = 0;

    scatterData.map((dataPoint) => {
      if (dataPoint.averageActionsDetected > maxAverageActionsDetected) {
        maxAverageActionsDetected = dataPoint.averageActionsDetected;
      }

      return {
        month: dataPoint.month,
        weekday: dataPoint.weekday,
        averageActionsDetected: dataPoint.averageActionsDetected,
      };
    });

    // Sort the data first by month, then by weekday
    const monthOrder = {
      January: 1,
      February: 2,
      March: 3,
      April: 4,
      May: 5,
      June: 6,
      July: 7,
      August: 8,
      September: 9,
      October: 10,
      Nov: 11,
      Dec: 12,
    };
    const weekdayOrder = {
      Sunday: 0,
      Monday: 1,
      Tuesday: 2,
      Wednesday: 3,
      Thursday: 4,
      Friday: 5,
      Saturday: 6,
    };

    scatterData.sort((a, b) => {
      if (monthOrder[a.month] !== monthOrder[b.month]) {
        return monthOrder[a.month] - monthOrder[b.month];
      }
      return weekdayOrder[a.weekday] - weekdayOrder[b.weekday];
    });

    return {
      status: true,
      data: {
        scatterPoints: scatterData,
        maxAverageActionsDetected,
      },
    };
  },
};

const getWeekRangeLabel = (startDate, endDate) => {
  let firstDayInWeek = startDate.clone();
  let lastDayInWeek = startDate.clone().endOf("week").add(1, "days");

  const firstDayInMonth = startDate.clone().startOf("month");
  const lastDayForRange = endDate.clone();

  firstDayInWeek = moment.max(firstDayInWeek, firstDayInMonth);
  lastDayInWeek = moment.min(lastDayInWeek, lastDayForRange);

  return `${firstDayInWeek.format("MMMM D")} - ${lastDayInWeek.format(
    "MMMM D"
  )}`;
};

export default analyticsApi;
