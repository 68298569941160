import React, { useState, useEffect, useCallback } from "react";
import VideoList from "../components/VideoList";
import "react-datepicker/dist/react-datepicker.css";
import { useLocation, useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import LiveVideoPlayer from "../components/LivePlayer";
import authService from "../services/authService";

import { RightOutlined } from "@ant-design/icons";

export default function Live() {
  const dispatch = useDispatch();
  let cameraList = useSelector((state) => state.camera.cameraList);
  const navigate = useNavigate();
  const [currCamera, setCurrCamera] = useState(null);
  const { state } = useLocation();

  const setMainVideo = useCallback(
    (camera) => {
      setCurrCamera(camera);
    },
    [setCurrCamera]
  );

  useEffect(() => {
    if (cameraList.length > 0 && currCamera === null) {
      if (state) {
        const camera = cameraList.find((option) => option.mac === state.mac);
        setMainVideo(camera);
      } else {
        setMainVideo(cameraList[0]);
      }
    }
  }, [navigate, cameraList, dispatch, state, currCamera, setMainVideo]);

  const createLiveVideoUrl = (macOfCamera) => {
    const url = `${authService.getUnitUrl()}media/live/${macOfCamera}/output.m3u8`;
    return url;
  };

  return (
    <div className="page-container flex flex-col">
      <h1 className="page-header mb-6">Live</h1>

      <div className="h-5/6 flex flex-1 flex-row bg-white dark:bg-neutral-900 border dark:border-neutral-700">
        <div className="w-1/2 flex flex-1 flex-col gap-6 p-6">
          <div className="flex flex-row gap-2">
            <h2 className="text-xl text-neutral-400 dark:text-neutral-500">
              Selected Camera
            </h2>
            <RightOutlined className="text-neutral-300 dark:text-neutral-500" />
            <h2 className="text-xl"> {currCamera?.name}</h2>
          </div>
          <LiveVideoPlayer
            className="shadow-lg"
            playerId={`player_${currCamera?.mac}`}
            videoUrrl={createLiveVideoUrl(currCamera?.mac)}
            cameraName={currCamera?.name}
          />
        </div>

        <div className="w-72 border-l dark:border-neutral-700">
          <VideoList
            cameraList={cameraList}
            createVideoUrl={createLiveVideoUrl}
            setMainVideo={setMainVideo}
          />
        </div>
      </div>
    </div>
  );
}
