export const SelectTimePeriodOptions = [
  {
    label: "Current",
    options: [
      { value: "Today", label: "Today" },
      { value: "WeekToDate", label: "Week to Date" },
      { value: "MonthToDate", label: "Month to Date" },
      { value: "QuarterToDate", label: "Quarter to Date" },
      { value: "YearToDate", label: "Year to Date" },
    ],
  },
  {
    label: "Previous",
    options: [
      { value: "Yesterday", label: "Yesterday" },
      { value: "LastWeek", label: "Last Week" },
      { value: "LastMonth", label: "Last Month" },
      { value: "LastQuarter", label: "Last Quarter" },
      { value: "LastYear", label: "Last Year" },
    ],
  },
];

export const getAPIParameters =
  (selectedDate) =>
  (period, openingHour = null, closingHour = null) => {
    const today = new Date(selectedDate);
    today.setHours(0, 0, 0, 0);

    let startDate;
    let endDate;
    let timePeriod;
    let month;
    let startMonth;

    switch (period) {
      case "Today":
        timePeriod = "hour";

        startDate = new Date(today);

        if (openingHour) {
          startDate.setHours(openingHour, 0, 0, 0);
        }

        endDate = new Date(today);

        if (closingHour) {
          endDate.setHours(closingHour, 0, 0, 0);
        } else {
          endDate.setHours(23, 59, 59, 999);
        }

        break;

      case "WeekToDate":
        timePeriod = "day";

        startDate = new Date(today);
        startDate.setDate(today.getDate() - today.getDay());

        endDate = new Date(today);
        endDate.setHours(23, 59, 59, 999);
        break;

      case "MonthToDate":
        timePeriod = "week";

        startDate = new Date(today.getFullYear(), today.getMonth(), 1);

        endDate = new Date(today);
        endDate.setHours(23, 59, 59, 999);
        break;

      case "QuarterToDate":
        timePeriod = "month";

        month = today.getMonth();
        startMonth = month - (month % 3);
        startDate = new Date(today.getFullYear(), startMonth, 1);
        endDate = new Date(today);
        endDate.setHours(23, 59, 59, 999);
        break;

      case "YearToDate":
        timePeriod = "month";

        startDate = new Date(today.getFullYear(), 0, 1, 0, 0, 0, 0);
        endDate = new Date(today);
        endDate.setHours(23, 59, 59, 999);
        break;

      case "Yesterday":
        timePeriod = "hour";

        startDate = new Date(today);
        startDate.setDate(startDate.getDate() - 1);

        if (openingHour) {
          startDate.setHours(openingHour, 0, 0, 0);
        }

        endDate = new Date(startDate);

        if (closingHour) {
          endDate.setHours(closingHour, 0, 0, 0);
        } else {
          endDate.setHours(23, 59, 59, 999);
        }
        break;

      case "LastWeek":
        timePeriod = "day";

        startDate = new Date(today);
        startDate.setDate(today.getDate() - today.getDay() - 7);

        endDate = new Date(startDate);
        endDate.setDate(startDate.getDate() + 6);
        endDate.setHours(23, 59, 59, 999);
        break;

      case "LastMonth":
        timePeriod = "week";

        startDate = new Date(today.getFullYear(), today.getMonth() - 1, 1);
        endDate = new Date(
          startDate.getFullYear(),
          startDate.getMonth() + 1,
          0
        );
        break;

      case "LastQuarter":
        timePeriod = "month";

        month = today.getMonth();
        startMonth = month - (month % 3);
        startDate = new Date(today.getFullYear(), startMonth - 3, 1);
        endDate = new Date(
          new Date(startDate.getFullYear(), startDate.getMonth() + 3, 1)
        );
        endDate.setDate(endDate.getDate() - 1);
        break;

      case "LastYear":
        timePeriod = "month";

        startDate = new Date(today.getFullYear() - 1, 0, 1);
        endDate = new Date(today.getFullYear() - 1, 11, 31);
        break;

      default:
        throw new Error("Filter not implemented!");
    }

    return { startDate, timePeriod, endDate };
  };
