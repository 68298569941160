import { lookupAction } from "./lookup";
import notificationApi from "../../api/notification";
import { errorAction } from "../error/error";

export const getNotificationTypes = () => {
  return async (dispatch) => {
    try {
      const respose = await notificationApi.getNotificationTypes();
      if (respose.status) {
        dispatch(lookupAction.setActionTypes(respose.actionTypes));
      } else {
        const respose = await notificationApi.getNotificationTypes();
        if (respose.status) {
          dispatch(lookupAction.setActionTypes(respose.actionTypes));
        } else {
          dispatch(errorAction.setErrorMessage(respose.error));
          dispatch(errorAction.openModal());
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
};
