import { createSlice } from "@reduxjs/toolkit";

const cameraSlice = createSlice({
  name: "camera",
  initialState: {
    cameraList: [],
    scanCameraList: null,
    errorAdingCamera: false,   
    selectScanCameraAll: false,    
  },
  reducers: {
    replaceCameras(state, action) {
      state.cameraList = action.payload.sort((a, b) => {
        if (a.name && b.name) {
          return a.name.localeCompare(b.name);
        }
        return a;
      });
    },
    addCamera(state, action) {
      state.cameraList.push(action.payload);
    },
    removeCameras(state, action) {
      const cameraList = state.cameraList.filter(
        (c) => c.mac !== action.payload.mac
      );

      state.cameraList = cameraList;
    },
    getCameraName(state, action) {
      let result = "Generic";
      if (state.cameraList) {
        const camera = state.cameraList.find((c) => c.mac === action.payload);
        result = camera ? camera.name : result;
      }

      return result;
    },
    updateCamera(state, action) {
      if (state.cameraList) {
        const cameraList = state.cameraList.map((camera) => {
          if (camera.mac === action.payload.mac) {
            camera.name = action.payload.name;
            camera.role = action.payload.role;
            camera.additional_context = action.payload.additional_context;
            camera.action_types = action.payload.action_types;
          }

          return camera;
        });

        state.cameraList = cameraList;
      }
    },
    setCameraHealthy(state, action) {
      const camera = state.cameraList.find((c) => c.mac === action.payload.mac);
      camera.ishealthy = action.payload.ishealthy;
    },
    setScanCamera(state, action) {
      let newCameraList = null;
      if (action.payload) {
        newCameraList = action.payload.filter((scanCamera) => {
          return !state.cameraList.find(
            (camera) => camera.mac === scanCamera.mac
          );
        });
      }
      state.scanCameraList = newCameraList;
      state.selectScanCameraAll = false;
    },
    setScanCameraStatus(state, action) {
      const camera = state.scanCameraList.find(
        (c) => c.mac === action.payload.mac
      );
      camera.status = action.payload.status;
    },
    setScanCameraUsername(state, action) {
      let camera = state.scanCameraList.find(
        (camera) => camera.mac === action.payload.mac
      );
      camera.username = action.payload.username;
    },
    setLoadingInScanCamera(state, action) {
      state.scanCameraList[action.payload.index].loading = action.payload.value;
    },
    setScanCameraPassword(state, action) {
      let camera = state.scanCameraList.find(
        (camera) => camera.mac === action.payload.mac
      );
      camera.password = action.payload.password;
    },
    setSelectScanCamera(state, action) {
      state.scanCameraList[action.payload.index].select = action.payload.value;

      state.selectScanCameraAll = state.scanCameraList.every((camera) => {
        return camera.select;
      });
    },
    setSelectScanCameraAll(state, action) {
      state.selectScanCameraAll = action.payload.value;
    },
    resetScanCamera(state) {
      state.scanCameraList = null;
      state.selectScanCameraAll = false;
    },
    removeScanCamera(state, action) {
      const scanCameraList = state.scanCameraList.find(
        (c) => c.mac !== action.payload.mac
      );
      state.scanCameraList = scanCameraList;
    },
    setLoadingInCameraByMac(state, action) {
      let camera = state.cameraList.find(
        (camera) => camera.mac === action.payload.mac
      );

      if (camera) {
        camera.loading = action.payload.value;
      }
    },   
  },
});

export const cameraAction = cameraSlice.actions;
export default cameraSlice;
