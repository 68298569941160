import { createSlice } from "@reduxjs/toolkit";

const lookupSlice = createSlice({
  name: "lookup",
  initialState: {
    actionTypes: [],
    intentList: [
      { label: "Analytic", value: "analytical", color: "#30ac64" },
      { label: "Monitoring", value: "monitoring", color: "#FF0000" },
    ],
    cameraRoles: [
      { value: "Entrance/Exit", label: "Entrance/Exit" },
      { value: "Cashier", label: "Cashier" },
      { value: "Floor", label: "Floor" },
      { value: "Main", label: "Main" },
      { value: "Showroom", label: "Showroom" },
    ],
  },
  reducers: {
    setActionTypes(state, action) {
      state.actionTypes = action.payload;
    },
  },
});

export const lookupAction = lookupSlice.actions;
export default lookupSlice;
