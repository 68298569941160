// custom styling for react-select components
export const SelectCustomStyles = {
  control: (provided) => ({
    ...provided,
    cursor: "pointer",
  }),
  option: (provided, state) => ({
    ...provided,
    backgroundColor: state.isSelected ? "#86efac" : "#fff",
    color: "#000",
    cursor: "pointer",
    "&:hover": { backgroundColor: state.isSelected ? "#86efac" : "#dcfce7" },
  }),
  multiValue: (provided, { data }) => ({
    ...provided,
    borderRadius: "12px",
  }),
  multiValueLabel: (provided) => ({
    ...provided,
    fontSize: "14px",
    fontWeight: "600",
  }),
  multiValueRemove: (provided) => ({
    ...provided,
    borderRadius: "0px 12px 12px 0px",
  }),
};
