import { unitAction } from "./unit";
import unitApi from "../../api/unit";
import { errorAction } from "../error/error";

export const getUnit = () => {
  return async (dispatch) => {
    try {
      const respose = await unitApi.getUnit();
      if (respose.status) {
        dispatch(unitAction.setUnit(respose.result));
      } else {
        const respose = await unitApi.getUnit();
        if (respose.status) {
          dispatch(unitAction.setUnit(respose.result));
        } else {
          dispatch(errorAction.setErrorMessage(respose.error));
          dispatch(errorAction.openModal());
        }
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateUnitSettings = (settings, isNew) => {
  return async (dispatch) => {
    try {
      let respose;

      if (isNew) {
        respose = await unitApi.setUnitLocation(settings);
      } else {
        respose = await unitApi.updateUnitSettings(settings);
      }

      if (respose.status) {
        dispatch(unitAction.setUnit(respose.result));
      } else {
        dispatch(errorAction.setErrorMessage(respose.error));
        dispatch(errorAction.openModal());
      }
    } catch (err) {
      console.log(err);
    }
  };
};

export const setUnitInEditMode = (value) => {
  return (dispatch) => {
    try {
      dispatch(unitAction.setUnitInEditMode(value));
    } catch (err) {
      console.log(err);
    }
  };
};

export const setLoadingInUnit = (value) => {
  return (dispatch) => {
    try {
      dispatch(unitAction.setLoadingInUnit(value));
    } catch (err) {
      console.log(err);
    }
  };
};
