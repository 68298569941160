import React, { useEffect, useState } from "react";
import Select from "react-select";
import {
  ResponsiveContainer,
  ScatterChart,
  Scatter,
  XAxis,
  YAxis,
  ZAxis,
  Tooltip,
  Rectangle,
} from "recharts";
import analyticsApi from "../../api/analytics";
import MUITooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";
import { SelectCustomStyles } from "../../utils/stylingUtils";
import DashboardChartTooltip from "../DashboardChartTooltip";

const ActionsHeatmap = () => {
  const [heatmapData, setHeatmapData] = useState([]);
  const [selectedYear, setSelectedYear] = useState(2024);

  const months = [
    "January",
    "February",
    "March",
    "April",
    "May",
    "June",
    "July",
    "August",
    "September",
    "October",
    "November",
    "December",
  ];

  const weekdays = [
    "Sunday",
    "Monday",
    "Tuesday",
    "Wednesday",
    "Thursday",
    "Friday",
    "Saturday",
  ];

  useEffect(() => {
    const fetchData = async () => {
      const startDate = new Date(selectedYear, 0, 1, 0, 0, 0, 0);
      const endDate = new Date(selectedYear, 11, 31, 23, 59, 59, 59);

      const response = await analyticsApi.getAverageActionsDetected(
        startDate,
        endDate
      );

      if (response.status) {
        setHeatmapData(response.data);
      }
    };

    fetchData();
  }, [selectedYear]);

  const handleSelectedYearChange = (newYear) => {
    setSelectedYear(newYear.value);
  };

  const colors = [
    {
      fill: "#fff",
      border: "#34d399",
      gradient:
        "linear-gradient(135deg, rgba(255,255,255,0.6), rgba(255,255,255,1))",
      tooltipTitle: "No Activity",
      tooltipContent: "No actions detected in this time slot",
    },
    {
      fill: "#bbf7d0",
      border: "#bbf7d0",
      gradient:
        "linear-gradient(135deg, rgba(187,247,208,0.6), rgba(187,247,208,1))",
      tooltipTitle: "Low Activity",
      tooltipContent: "Relatively few actions detected",
    },
    {
      fill: "#4ade80",
      border: "#4ade80",
      gradient:
        "linear-gradient(135deg, rgba(74,222,128,0.6), rgba(74,222,128,1))",
      tooltipTitle: "Moderate Activity",
      tooltipContent: "Average number of actions detected",
    },
    {
      fill: "#15803d",
      border: "#15803d",
      gradient:
        "linear-gradient(135deg, rgba(21,128,61,0.6), rgba(21,128,61,1))",
      tooltipTitle: "High Activity",
      tooltipContent: "Above average number of actions detected",
    },
    {
      fill: "#052e16",
      border: "#052e16",
      gradient: "linear-gradient(135deg, rgba(5,46,22,0.6), rgba(5,46,22,1))",
      tooltipTitle: "Very High Activity",
      tooltipContent: "Peak number of actions detected",
    },
  ];

  const getColorInfo = (average) => {
    const percentage = (average / heatmapData.maxAverageActionsDetected) * 100;

    if (percentage > 75) return colors[4];
    if (percentage > 50) return colors[3];
    if (percentage > 25) return colors[2];
    if (percentage > 0) return colors[1];
    return colors[0];
  };

  const CustomShape = (props) => {
    const { x, y, averageActionsDetected } = props;
    const colorInfo = getColorInfo(averageActionsDetected);
    const gradientID = `gradient-${averageActionsDetected}`;

    return (
      <g>
        <defs>
          <linearGradient id={gradientID} x1="0%" y1="0%" x2="100%" y2="100%">
            <stop offset="0%" stopColor={colorInfo.fill} stopOpacity={0.6} />
            <stop offset="100%" stopColor={colorInfo.fill} stopOpacity={1} />
          </linearGradient>
        </defs>
        <Rectangle
          x={x - 15}
          y={y - 15}
          width={40}
          height={40}
          radius={12}
          fill={`url(#${gradientID})`}
          stroke={colorInfo.border}
          strokeWidth="1px"
        />
      </g>
    );
  };

  const LegendTooltip = styled(({ className, ...props }) => (
    <MUITooltip {...props} arrow classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.arrow}`]: {
      color: "#232255",
    },
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#232255",
      borderRadius: "6px",
      fontSize: "16px",
      fontWeight: 500,
      boxShadow: theme.shadows[2],
    },
  }));

  const CustomTooltip = ({ active, payload }) => {
    if (active && payload && payload.length) {
      const data = payload[0].payload;
      const text =
        data.averageActionsDetected === 0
          ? "No Actions Detected"
          : `Average Actions Detected: ${data.averageActionsDetected}`;

      return (
        <div className="text-left rounded-md shadow-md bg-neutral-600 pl-3 pr-8 py-1">
          <p className="text-guardis-300">{`${data.weekday}s in ${data.month}`}</p>
          <p className="text-neutral-100 font-medium">{`${text}`}</p>
        </div>
      );
    }
    return null;
  };

  return (
    <div className="card grid grid-cols-12 my-4 w-full p-6">
      <div className="col-span-12 flex justify-center m-2">
        <h2>Yearly Heatmap of Actions</h2>
        <DashboardChartTooltip
          className="pl-2"
          tooltipTitle="Heatmap of Selected Year"
          tooltipContent="For each weekday in a month, the average of all actions detected is calculated."
        />
      </div>

      <div className="col-span-12 flex justify-between px-9 py-4">
        <div className="flex items-center">
          <h3 className="pr-5">Year</h3>
          <Select
            className="w-28 text-center rounded-lg"
            styles={SelectCustomStyles}
            options={[
              { value: 2024, label: "2024" },
              { value: 2023, label: "2023" },
              { value: 2022, label: "2022" },
            ]}
            defaultValue={{ value: 2024, label: "2024" }}
            placeholder="Select Year"
            name="actionTypes"
            isSearchable={false}
            isClearable={false}
            onChange={handleSelectedYearChange}
          />
        </div>

        <div className="flex items-center">
          <h3 className="text-center w-28 mr-4">Less Actions Detected</h3>
          {colors.map((item, index) => (
            <LegendTooltip
              key={index}
              title={
                <div className="w-72 p-1">
                  <p className="text-[#C7D5FF]">{`${item.tooltipTitle}`}</p>
                  <p className="text-white font-medium">{`${item.tooltipContent}`}</p>
                </div>
              }
            >
              <div
                key={index}
                style={{
                  background: item.gradient,
                  borderWidth: "1px",
                  borderColor: item.border,
                }}
                className="w-10 h-10 rounded-xl mx-1"
              ></div>
            </LegendTooltip>
          ))}
          <h3 className="text-center w-28 ml-4">More Actions Detected</h3>
        </div>
      </div>

      <div className="col-span-12 pb-10" align="center">
        <ResponsiveContainer width="95%" height={400}>
          <ScatterChart
            margin={{ top: 20, right: 20, bottom: 20, left: 80 }}
            data={heatmapData.scatterPoints}
          >
            <XAxis
              type="category"
              dataKey="month"
              name="month"
              interval={0}
              stroke="#4b5563"
              tick={{ fontWeight: "500", fontSize: "16px" }}
              tickFormatter={(str) => str.slice(0, 3)}
              tickLine={false}
              tickMargin={14}
              ticks={months}
              orientation="top"
              axisLine={false}
              allowDuplicatedCategory={false}
            />
            <YAxis
              reversed
              type="category"
              dataKey="weekday"
              name="day"
              interval={0}
              stroke="#4b5563"
              tick={{ fontWeight: "400", fontSize: "16px" }}
              tickFormatter={(str) => str + "s"}
              tickLine={false}
              tickMargin={8}
              ticks={weekdays}
              axisLine={false}
              allowDuplicatedCategory={false}
            />
            <ZAxis dataKey="averageActionsDetected" />
            <Tooltip cursor={false} content={<CustomTooltip />} />
            <Scatter dataKey="averageActionsDetected" shape={<CustomShape />} />
          </ScatterChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default ActionsHeatmap;
