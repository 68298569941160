import React, { useState, useRef } from "react";
import { DatePicker } from "antd";
import dayjs from "dayjs";

import BadgesGraph from "../components/BadgesGraph";
import SalesFunnel from "../components/FunnelGraph";
import ActionsBarChart from "../components/ActionsBarChart";
import CameraActivitySummary from "../components/CameraActivitySummary";
import MostActiveHourChart from "../components/MostActiveHourChart/MostActiveHourChart";
import PeakHoursChart from "../components/PeakHoursChart/PeakHoursChart";
import ActionsChangeChart from "../components/ActionsChangeChart";
import ActionsHeatmap from "../components/ActionsHeatmap";

export default function Analytics() {
  const [startDate, setStartDate] = useState(new Date());
  const badgesGraphControl = useRef();

  return (
    <div className="page-container flex flex-col gap-6">
      <div className="flex justify-between">
        <h1 className="page-header">Dashboard</h1>
        <DatePicker
          className="w-52"
          label="Date"
          size="large"
          format="MMMM DD, YYYY"
          value={dayjs(startDate)}
          onChange={(newStartDate) => {
            setStartDate(newStartDate.toDate());
            badgesGraphControl.current.updateChart(newStartDate.toDate());
          }}
          disabledDate={(date) => {
            return date > dayjs().endOf("day");
          }}
        />
      </div>

      <BadgesGraph ref={badgesGraphControl} />
      <SalesFunnel />
      <div className="flex flex-row flex-wrap gap-6">
        <ActionsBarChart selectedDate={startDate} />
        <CameraActivitySummary />
      </div>
      <MostActiveHourChart selectedDate={startDate} />
      <PeakHoursChart selectedDate={startDate} />
      <ActionsChangeChart selectedDate={startDate} />
      <ActionsHeatmap selectedDate={startDate} />
    </div>
  );
}
