import InfoRoundedIcon from "@mui/icons-material/InfoRounded";
import Tooltip, { tooltipClasses } from "@mui/material/Tooltip";
import { styled } from "@mui/material/styles";

const StyledTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} arrow classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.arrow}`]: {
    color: "#232255",
  },
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#232255",
    borderRadius: "6px",
    fontSize: "16px",
    fontWeight: 500,
    boxShadow: theme.shadows[2],
  },
}));

const DashboardChartTooltip = ({
  className = "",
  tooltipTitle = "",
  tooltipContent = "",
}) => {
  return (
    <StyledTooltip
      placement="right"
      arrow
      title={
        <div className="w-72 p-1">
          <p className="text-[#C7D5FF]">{`${tooltipTitle}`}</p>
          <p className="text-white font-medium">{`${tooltipContent}`}</p>
        </div>
      }
    >
      <div className={`flex justify-center content-center ${className}`}>
        <InfoRoundedIcon className="icon" />
      </div>
    </StyledTooltip>
  );
};

export default DashboardChartTooltip;
