import axios from "../extension/axios";
import authService from "../services/authService";
import moment from "moment";

const notificationApi = {
  getNotificationTypes: async () => {
    const request = axios
      .get(`${authService.getUnitUrl()}actions/types`)
      .then(function (response) {
        let actionTypes = response.data.map((actionType) => {
          return {
            id: actionType.id,
            name: actionType.name,
            intent: actionType.intent,
          };
        });

        actionTypes = actionTypes.sort((a, b) => {
          if (a.name && b.name) {
            return a.name.localeCompare(b.name);
          }
          return a;
        });

        return { actionTypes: actionTypes, status: true };
      })
      .catch(function (error) {
        console.log(error);
        const message =
          "There was an error, please see the browser console for more details!";
        return { status: false, error: message };
      });

    return await request;
  },
  getNotificationsByfilter: async (model) => {
    const request = axios
      .post(`${authService.getUnitUrl()}video_captions/filter`, model)
      .then(function (response) {
        // if(response.data.length ===0){
        //   response.data = eventData.getMockEventData();
        // }

        let notification_list = response.data.map((notification) => {
          const actionsDetected = notification.action_events
            .map((action) => action.action_type.name)
            .join(", ");

          const intentDetected = [
            ...new Set(
              notification.action_events.map(
                (action) => action.action_type.intent
              )
            ),
          ].join(", ");

          const actionEvents = notification.action_events.map((action) => {
            return {
              id: action.action_type.id,
              name: action.action_type.name,
              intent: action.action_type.intent,
            };
          });

          const description = notification.description
            ? notification.description.replace(/<\/s>/g, "")
            : notification.description;

          return {
            camera_id: notification.camera_id,
            clip_id: notification.clip_id,
            bookmark: notification.bookmark,
            description: description,
            feedback_description: notification.feedback_description,
            sent_date: moment(notification.timestamp).format(
              "MM/DD/yyyy, h:mm:ss A"
            ),
            user_feedback: notification.user_feedback,
            selectedEvent: false,
            actionEvents: actionEvents,
            actionsDetected: actionsDetected,
            intentDetected: intentDetected,
            totalOfActionsDetected: notification.action_events.length,
          };
        });

        notification_list = notification_list.sort(
          (a, b) => a.sent_date - b.sent_date
        );

        return { notification_list: notification_list, status: true };
      })
      .catch(function (error) {
        console.log(error);
        const message =
          "There was an error, please see the browser console for more details!";
        return { notification_list: [], status: false, error: message };
      });

    return await request;
  },
  deleteNotification: async (clip_id) => {
    var request = axios
      .delete(`${authService.getUnitUrl()}video_captions/${clip_id}`, null)
      .then(function (response) {
        return { clip_id, status: true };
      })
      .catch(function (error) {
        console.log(error);
        const message =
          error.response && error.response.data
            ? error.response.data
            : "There was a error, please see the browser console for more details!";
        return { clip_id: 0, status: false, error: message };
      });

    return await request;
  },
  saveUserFeedback: async (notification, wasgood) => {
    var response = axios
      .put(
        `${authService.getUnitUrl()}video_captions/${notification.clip_id}`,
        {
          action_events: notification.actionEvents,
          bookmark: notification.bookmark,
          camera_id: notification.camera_id,
          clip_id: notification.clip_id,
          description: notification.description,
          feedback_description: notification.feedback_description,
          user_feedback: wasgood,
        }
      )
      .then(function (response) {
        return { result: response.data, status: true };
      })
      .catch(function (error) {
        console.log(error);
        const message =
          error.response && error.response.data
            ? error.response.data
            : "There was a error, please see the browser console for more details!";
        return { result: null, status: false, error: message };
      });

    return await response;
  },
  updateBookmark: async (bookmark, isBookmark) => {
    let request = {
      clip_id: bookmark.clip_id,
      camera_id: bookmark.camera_id,
      description: bookmark.description,
      user_feedback: bookmark.user_feedback,
      feedback_description: bookmark.feedback_description,
      bookmark: isBookmark,
    };

    try {
      let response = await axios.put(
        `${authService.getUnitUrl()}video_captions/${bookmark.clip_id}`,
        request
      );
    } catch (error) {
      console.log(error);
      const message = "There was an error modifying a video clip bookmark.";
      return { status: false, error: message };
    }

    return { status: true, clip_id: bookmark.clip_id };
  },
  getBookmarksByFilter: async (requestData = {}) => {
    const request = {
      bookmark: true,
    };

    if ("startTime" in requestData) {
      request.start_time = moment(requestData.startTime).format(
        "YYYY-MM-DD HH:mm:ss"
      );
    }

    if ("endTime" in requestData) {
      request.end_time = moment(requestData.endTime).format(
        "YYYY-MM-DD HH:mm:ss"
      );
    }

    if (
      "action_type_ids" in requestData &&
      requestData.action_type_ids.length > 0
    ) {
      request.action_type_ids = requestData.action_type_ids;
    }

    if ("intent" in requestData) {
      request.intent = requestData.intent;
    }

    let response;

    try {
      response = await axios.post(
        `${authService.getUnitUrl()}video_captions/filter`,
        request
      );
    } catch (error) {
      console.error("Error in getBookmarksByFilter API call", error);
      const message = "There was an error getting filtered bookmarks data.";
      return { status: false, error: message, bookmarks: [] };
    }

    let bookmarks = response.data.map((bookmark) => {
      const actionsDetected = bookmark.action_events
        .map((action) => action.action_type.name)
        .join(", ");

      const intentDetected = [
        ...new Set(
          bookmark.action_events.map((action) => action.action_type.intent)
        ),
      ].join(", ");

      const actionEvents = bookmark.action_events.map((action) => ({
        id: action.action_type.id,
        name: action.action_type.name,
        intent: action.action_type.intent,
      }));

      const description = bookmark.description
        ? bookmark.description.replace(/<\/s>/g, "")
        : bookmark.description;

      return {
        camera_id: bookmark.camera_id,
        clip_id: bookmark.clip_id,
        description,
        feedback_description: bookmark.feedback_description,
        timestamp: moment(bookmark.timestamp).format("MM/DD/yyyy, h:mm:ss A"),
        user_feedback: bookmark.user_feedback,
        actionEvents,
        actionsDetected,
        intentDetected,
      };
    });

    bookmarks = bookmarks.sort((a, b) => a.timestamp - b.timestamp);

    return { status: true, bookmarks };
  },
};

export default notificationApi;
