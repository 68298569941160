import React, {
  Fragment,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import AddCircleOutlineIcon from "@mui/icons-material/AddCircleOutline";
import CancelIcon from "@mui/icons-material/CancelOutlined";

const CameraCredentialsModal = forwardRef((props, ref) => {
  const [isOpen, setIsOpen] = useState(false);
  const [userName, setUsername] = useState("");
  const [password, setPassword] = useState("");
  const cancelButtonRef = useRef(null);
  const dialogRef = useRef(null);

  const closeModal = (isOk = false) => {
        
    const result = {
      isOk,
      password,
      username: userName
    };

    props.AddNewCamera(result);

    setIsOpen(false);
  };

  const openModal = () => {
    setIsOpen(true);
  };

  useImperativeHandle(ref, () => ({
    openModal,
  }));

  const onUsernameChange = (e) => {
    setUsername(e.target.value);
  };

  const onPasswordChange = (e) => {
    setPassword(e.target.value);
  };

  return (
    <React.Fragment>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
          ref={dialogRef}
          as="div"
          className="relative z-10 your-dialog-content-class"
          initialFocus={cancelButtonRef}
          onClose={closeModal}
          static={true}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white rounded-b-lg rounded-t-lg">
                    <div className="sm:flex sm:items-start sm:p-2 sm:pb-2  bg-light-navy rounded-t-lg ">
                      <div className="w-[50%]">
                        <Dialog.Title
                          as="h3"
                          className="pt-0 text-base font-semibold leading-6 text-white"
                        >
                          Camera Credentials
                        </Dialog.Title>
                      </div>
                      <div className="w-[50%] text-white flex justify-end  px-2">
                        <button
                          onClick={() => closeModal(false)}
                          className="font-semibold text-md hover:text-gray-200  "
                        >
                          X
                        </button>
                      </div>
                    </div>
                    <div className="grid  justify-items-center">
                      <div className=" mx-4 my-4 space-y-1">
                        <label className="flex flex-col w-full">
                          Username:
                        </label>
                        <div className="flex place-items-center border-solid border-2 rounded border-gray-200 pr-1">
                          <input                            
                            type="text"
                            placeholder="Username"
                            className="text-black pl-1"
                            value={userName}
                            onChange={onUsernameChange}
                          />
                        </div>
                        <label className="flex flex-col w-full">
                          Password:
                        </label>
                        <div className="flex place-items-center border-solid border-2 rounded border-gray-200 pr-1">
                          <input                          
                            type="password"
                            placeholder="Password"
                            className="text-black pl-1"
                            value={password}
                            onChange={onPasswordChange}
                          />
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                      <button
                        type="button"
                        className=" inline-flex w-full rounded-md  text-white bg-gai-green  px-2 py-2 text-sm font-semibold shadow-sm ring-1 ring-gray-300 hover:bg-emerald-600 sm:w-auto mr-2 border border-gai-green"
                        onClick={() => closeModal(true)}
                      >
                        <AddCircleOutlineIcon
                          sx={{ fontSize: 20 }}
                        ></AddCircleOutlineIcon>
                        &nbsp;Add
                      </button>
                      &nbsp;
                      <button
                        type="button"
                        className="inline-flex w-full rounded-md bg-white px-2 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-gray-300 hover:bg-gray-200 sm:w-auto mr-2 border border-red-500"
                        onClick={() => closeModal(false)}
                        ref={cancelButtonRef}
                      >
                        <CancelIcon
                          className="text-red-600"
                          sx={{ fontSize: 20 }}
                        ></CancelIcon>
                        &nbsp;Cancel
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </React.Fragment>
  );
});

export default CameraCredentialsModal;
