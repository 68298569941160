import {
  CognitoUserPool,
  CognitoUser,
  AuthenticationDetails,
  CognitoRefreshToken,
} from "amazon-cognito-identity-js";
import authApi from "../api/auth";

const userPool = new CognitoUserPool({
  UserPoolId: process.env.REACT_APP_USER_POOL_ID,
  ClientId: process.env.REACT_APP_CLIENT_ID,
});

const authService = {
  signIn: async (email, password) => {
    const userProfile = await authApi.loginUser(email, password);

    const authenticationDetails = new AuthenticationDetails({
      Username: email,
      Password: password,
    });

    const userData = {
      Username: email,
      Pool: userPool,
    };

    const cognitoUser = new CognitoUser(userData);

    const response = new Promise((resolve, reject) => {
      cognitoUser.authenticateUser(authenticationDetails, {
        onSuccess: (session) => {
          resolve(session);
        },
        onFailure: (error) => {
          reject(error);
        },
      });
    });

    await response;

    localStorage.setItem("cfUrl", userProfile.url);
   
    return typeof(userProfile.url) !== 'undefined';
  },
  signOut: () => {
    const currentUser = userPool.getCurrentUser();
    if (currentUser) {
      currentUser.signOut();
    }
  },
  signUp: async (user) => {
    const result = await authApi.createNewUser(user);
    return result;
  },
  isUserAuthenticated: () => {
    let result = false;

    const currentUser = userPool.getCurrentUser();

    if (currentUser) {
      currentUser.getSession((err, session) => {
        if (err) {
          console.error("Error getting user session:", err);
          return result;
        }
        // Check if the session is valid
        if (session.isValid()) {
          result = true;
        }
      });
    }
     return  result;
  },
  refreshToken: (refreshToken) => {
    const refreshTokenSession = new CognitoRefreshToken({
      RefreshToken: refreshToken,
    });
    return new Promise((resolve, reject) => {
      CognitoUser.refreshSession(refreshTokenSession, (err, session) => {
        if (err) {
          reject(err);
        } else {
          resolve(session);
        }
      });
    });
  },
  getAccessToken: () => {
    const currentUser = userPool.getCurrentUser();
    if (currentUser) {
      return new Promise((resolve, reject) => {
        currentUser.getSession((err, session) => {
          if (err || !session.isValid()) {
            reject(err || new Error("Invalid session"));
          } else {
            resolve( {
              token : session.getAccessToken().getJwtToken(), 
              custonToken:session.getIdToken().getJwtToken()});
          }
        });
      });
    } else {
      return Promise.reject(new Error("User not signed in"));
    }
  },
  getUnitUrl: () => {   
     return  `${localStorage.getItem("cfUrl")}/`;
  },
};

export default authService;
