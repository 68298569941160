import React, {
  Fragment,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
  useEffect 
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import { useDispatch, useSelector } from "react-redux";
import CancelIcon from "@mui/icons-material/CancelOutlined";
import PuffLoader from "react-spinners/PuffLoader";
import { scanNetwork } from "../../store/camera/cameraAction";
import ReportProblemIcon from "@mui/icons-material/ReportProblem";

const ScanModal = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const [isOpen, setIsOpen] = useState(false);
  const cancelButtonRef = useRef(null);
  const dialogRef = useRef(null);
  const scanCameraList = useSelector((state) => state.camera.scanCameraList);

  useEffect(() => {

    if(scanCameraList?.length > 0 && isOpen){
      closeModal(true);
    }
  });

  const closeModal = (foundNewCameras = false) => {
    setIsOpen(false);
    props.ScanResultCallback(foundNewCameras);
  };

  const openModal = () => {
    setIsOpen(true);
    satartScanNetwork();
  };

  useImperativeHandle(ref, () => ({
    openModal,
  }));

  const satartScanNetwork = () => {
    dispatch(scanNetwork());
  };

  return (
    <React.Fragment>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
          ref={dialogRef}
          as="div"
          className="relative z-10 your-dialog-content-class"
          initialFocus={cancelButtonRef}
          onClose={closeModal}
          static={true}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                  <div className="bg-white rounded-b-lg rounded-t-lg">
                    <div className="sm:flex sm:items-start sm:p-2 sm:pb-2 bg-light-navy rounded-t-lg ">
                     <div className="w-[50%]">
                     <Dialog.Title
                        as="h3"
                        className="pt-0 text-base font-semibold leading-6 text-white text-lg"
                      >
                        Scanned Devices
                      </Dialog.Title>
                     </div>                     
                       <div className="w-[50%] text-white flex justify-end  px-2">
                        <button
                          onClick={() => closeModal(false)}
                          className="font-semibold text-md hover:text-gray-200  "
                        >
                          X
                        </button>
                      </div>
                    </div>
                    <div className="py-4 grid  justify-items-center">
                      {!scanCameraList ? (
                        <React.Fragment>
                          <div className="space-x-1">
                            <h2 className="text-emerald-700">
                              Scanning The Network
                            </h2>
                          </div>
                          <div className="mt-4">
                            <PuffLoader
                              size={100}
                              loading={!scanCameraList}
                              aria-label="Loading Spinner"
                              color={"#30ac64"}
                              className="mt-1"
                            ></PuffLoader>
                          </div>
                        </React.Fragment>
                      ) : (
                        <React.Fragment>
                          {scanCameraList?.length === 0 && (
                            <div className="flex">
                              <div className="mx-auto flex flex-shrink-0 items-center justify-center rounded-full bg-gray-300 sm:mx-0 sm:h-9 sm:w-9">
                                <ReportProblemIcon
                                  className="h-6 w-6 text-red-600"
                                  aria-hidden="true"
                                />
                              </div>
                              <h1 className=" mx-2 my-1">No devices found!</h1>
                            </div>
                          )}
                        </React.Fragment>
                      )}
                    </div>
                    <div className="bg-gray-50  mx-1 px-2 py-2 sm:flex sm:flex-row-reverse sm:px-2">
                      <button
                        type="button"
                        className="inline-flex w-full justify-center rounded-md bg-white px-2 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-200 sm:mt-0 sm:w-auto border border-red-500"
                        onClick={() => closeModal(false)}
                        ref={cancelButtonRef}
                      >
                        <CancelIcon
                          className="text-red-600"
                          sx={{ fontSize: 20 }}
                        ></CancelIcon>
                        Cancel
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </React.Fragment>
  );
});

export default ScanModal;
