import React from "react";

const NotFound = () => {
  return (
    <div className="h-full flex flex-col xl:flex-row space-y-2 p-3 overflow-auto pt-8 mt-8">
      <div className="xl:grow pr-2 flex flex-col sticky top-0 pt-8 mt-8">
        <div className="flex items-center justify-center pt-8 mt-8">
          <h1 className="text-2xl md:text-3xl">
            The page you're looking for does not exist.
          </h1>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
