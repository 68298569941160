import React, {
  useState,
  useEffect,
  forwardRef,
  useRef,
  useImperativeHandle,
} from "react";
import { DateTimePicker } from "@mui/x-date-pickers/DateTimePicker";
import ImageSearchRoundedIcon from "@mui/icons-material/ImageSearchRounded";
import InsightsRoundedIcon from "@mui/icons-material/InsightsRounded";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import BookmarkBorderRoundedIcon from "@mui/icons-material/BookmarkBorderRounded";
import BookmarkRoundedIcon from "@mui/icons-material/BookmarkRounded";
import RadioButtonUncheckedRoundedIcon from "@mui/icons-material/RadioButtonUncheckedRounded";
import RadioButtonCheckedRoundedIcon from "@mui/icons-material/RadioButtonCheckedRounded";
import IconButton from "@mui/material/IconButton";
import SearchIcon from "@mui/icons-material/Search";
import ArrowForwardIosRoundedIcon from "@mui/icons-material/ArrowForwardIosRounded";
import numeral from "numeral";
import Select from "react-select";
import moment from "moment";
import CircularProgress from "@mui/material/CircularProgress";
import { useLocation } from "react-router-dom";
import ErrorMessageModal from "../../components/ErrorMessageModal";
import { useSelector, useDispatch } from "react-redux";
import {
  getNotificationsByfilter,
  loadMoreNotificatios,
  getNextNotificatios,
  setSelectedNotification,
  updateBookmark,
} from "../../store/notification/notificationAction";
import { getNotificationTypes } from "../../store/lookup/lookupAction";
import AlertMessageModal from "../../components/AlertMessageModal";
import { Button } from "@mui/material";

const EventList = forwardRef((props, ref) => {
  const actionTypes = useSelector((state) => state.lookup.actionTypes);
  const intentList = useSelector((state) => state.lookup.intentList);

  const eventsToLoadedForPage = useSelector(
    (state) => state.notification.eventsToLoadedForPage
  );
  const currentEventsLoded = useSelector(
    (state) => state.notification.currentEventsLoded
  );
  const notificationsFromServer = useSelector(
    (state) => state.notification.allNotifications
  );
  const totalOfNotification = useSelector(
    (state) => state.notification.totalOfNotification
  );
  const notifications = useSelector(
    (state) => state.notification.notifications
  );
  const loadingNotification = useSelector(
    (state) => state.notification.loadingNotification
  );

  const dispatch = useDispatch();
  const cameraList = useSelector((state) => state.camera.cameraList);
  const [startDate, setStartDate] = useState(null);
  const [endDate, setEndDate] = useState(null);
  const [actionEvents, setActionEvents] = useState();
  const [intent, setIntent] = useState(null);
  const [camera, setCamera] = useState(null);
  const { state } = useLocation();
  const firstTime = useRef(true);
  const errorMessageModal = useRef();
  const alertMessageModal = useRef();

  useImperativeHandle(ref, () => ({
    getNextNotification,
  }));

  useEffect(() => {
    if (actionTypes.length === 0) {
      dispatch(getNotificationTypes());
    }

    let model = {};
    let startDate = null;
    let endDate = null;

    if (state) {
      model = state.filterModel;

      if (model.start_time && model.end_time) {
        startDate = new Date(model.start_time);
        endDate = new Date(model.end_time);
      }

      if (model.camera_id && cameraList != null) {
        const camara = cameraList.find(
          (option) => option.mac === model.camera_id
        );
        setCamera(camara);
      }

      if (model.intent) {
        setSelectedIntent(model.intent);
      }

      if (model.action_type_ids) {
        const actionEvents = actionTypes.filter((option) =>
          model.action_type_ids.includes(option.id)
        );

        setActionEvents(actionEvents);
      }

      if (props.setMainNotification) {
        const mainNotification = state.notification;

        if (mainNotification) {
          dispatch(setSelectedNotification(mainNotification.clip_id));
          props.setMainNotification(mainNotification);
        } else {
          dispatch(getNotificationsByfilter(model)).then((result) => {
            if (result.length > 0 && props.setMainNotification) {
              const mainNotification = result.filter((not) => {
                return not.user_feedback === null;
              })[0];

              if (mainNotification) {
                dispatch(setSelectedNotification(mainNotification.clip_id));
                dispatch(setSelectedNotification(mainNotification.clip_id));
                mainNotification.cameraname = getCameraName(
                  mainNotification.camera_id
                );
                props.setMainNotification(mainNotification);
              } else {
                props.setMainNotification(null);
              }
            }
          });
        }
      }
    } else {
      endDate = Date.now();
      startDate = new Date(endDate - 86400 * 1000);
      model = {
        start_time: moment(startDate).format("YYYY-MM-DD HH:mm:ss"),
        end_time: moment(endDate).format("YYYY-MM-DD HH:mm:ss"),
      };

      if (firstTime.current) {
        if (props.defaultIntent) {
          setSelectedIntent(props.defaultIntent);
          model.intent = props.defaultIntent;
        }

        dispatch(getNotificationsByfilter(model)).then((result) => {
          if (result.length > 0 && props.setMainNotification) {
            let mainNotification = result.filter((not) => {
              return not.user_feedback === null;
            })[0];

            mainNotification = mainNotification ? mainNotification : result[0];

            if (mainNotification) {
              dispatch(setSelectedNotification(mainNotification.clip_id));
              dispatch(setSelectedNotification(mainNotification.clip_id));
              mainNotification.cameraname = getCameraName(
                mainNotification.camera_id
              );
              props.setMainNotification(mainNotification);
            } else {
              props.setMainNotification(null);
            }
          }
        });
        firstTime.current = false;
      }
    }

    setStartDate(startDate);
    setEndDate(endDate);
  }, [state, cameraList, dispatch, actionTypes]);

  const getCameraName = (camera_id) => {
    const camera = cameraList.find((camera) => {
      return camera.mac === camera_id;
    });

    return camera ? camera.name : "Generic";
  };

  const getIntentLabel = (value) => {
    const intent = intentList.find((option) => option.value === value);

    return intent ? intent.label : value;
  };

  const getIntentLabelColor = (value) => {
    const intent = intentList.find((option) => option.value === value);

    return intent ? intent.color : "#FFFFFF";
  };

  const handleNotificationTypeSelectChange = (selectedOption) => {
    setActionEvents(selectedOption);
  };

  const handleIntentSelectChange = (selectedOption) => {
    setIntent(selectedOption);
  };

  const setSelectedIntent = (intent) => {
    let selectedOption = {};
    selectedOption = intentList.find(
      (option) => option.value.toUpperCase() === intent.toUpperCase()
    );
    setIntent(selectedOption);
  };

  const handleCameraSelectChange = (selectedOption) => {
    setCamera(selectedOption);
  };

  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      minHeight: "36px",
      height: "36px",
    }),
    input: (provided, state) => ({
      ...provided,
      margin: "0px",
    }),
    option: (provided, state) => ({
      ...provided,
      color: state.isSelected ? "#14532d" : "#000",
      backgroundColor: state.isSelected ? "#bbf7d0" : "#fff",
      "&:hover": { backgroundColor: state.isSelected ? "#bbf7d0" : "#f0fdf4" },
    }),
    indicatorsContainer: (provided, state) => ({
      ...provided,
      height: "36px",
    }),
    placeholder: (provided) => ({
      ...provided,
      fontWeight: 400,
    }),
  };

  const getCurrentFilterModel = () => {
    let model = {};

    if (camera) {
      model.camera_id = camera.mac;
    }

    if (intent || actionEvents) {
      model.action_type_ids = [];

      if (actionEvents) {
        model.action_type_ids = actionEvents.map((action) => {
          return action.id;
        });
      }

      if (intent) {
        model.intent = intent.value;
      }
    }

    if (startDate && endDate) {
      model = {
        ...model,
        start_time: moment(startDate).format("YYYY-MM-DD HH:mm:ss"),
        end_time: moment(endDate).format("YYYY-MM-DD HH:mm:ss"),
      };
    }

    return model;
  };

  const getNextNotification = () => {
    const notification = notifications.filter((not) => {
      return not.user_feedback === null;
    })[0];

    if (notification) {
      dispatch(setSelectedNotification(notification.clip_id));
    }
    return notification;
  };

  const searchHandle = async () => {
    if (startDate >= endDate) {
      openErrorModal(
        "The start date cannot be after or equal to the end date!"
      );
    } else {
      const model = getCurrentFilterModel();
      dispatch(getNotificationsByfilter(model)).then((result) => {
        if (result.length > 0 && props.setMainNotification) {
          let mainNotification = result.filter((not) => {
            return not.user_feedback === null;
          })[0];

          mainNotification = mainNotification ? mainNotification : result[0];

          if (mainNotification) {
            dispatch(setSelectedNotification(mainNotification.clip_id));
            mainNotification.cameraname = getCameraName(
              mainNotification.camera_id
            );
            props.setMainNotification(mainNotification);
          }
        }

        if (result.length === 0) {
          if (props.setMainNotification) {
            props.setMainNotification(null);
          }

          openAlertModal(
            "No events found with selected action, intent, and camera filters."
          );
        }
      });
    }
  };

  const loadMoreEvents = async () => {
    if (currentEventsLoded >= notificationsFromServer.length) {
      const model = getCurrentFilterModel();
      dispatch(loadMoreNotificatios(model));
    } else {
      dispatch(getNextNotificatios());
    }
  };

  function openErrorModal(message) {
    if (errorMessageModal.current) {
      errorMessageModal.current.open(message);
    }
  }

  const openAlertModal = (message) => {
    if (alertMessageModal.current) {
      alertMessageModal.current.open(message, "No Results Found!");
    }
  };

  return (
    <div className="flex flex-col h-full min-w-96 xl:w-1/3 md:w-1/2 shadow-md rounded-b-md">
      {/* Filter Search Box */}
      <Accordion
        defaultExpanded
        className="flex flex-col text-sm bg-white dark:bg-neutral-700 shadow-none border-b dark:border-neutral-600"
        sx={{
          "&.Mui-expanded": {
            margin: 0,
          },
        }}
      >
        <AccordionSummary expandIcon={<ExpandMoreIcon />}>
          <div className="flex">
            <p className="text-guardis-500">
              <strong>Events Loaded: </strong>
              {numeral(notifications.length).format("0,0")}
            </p>
            {notifications.length >= eventsToLoadedForPage && (
              <p>
                <strong>From: </strong>
                {numeral(totalOfNotification).format("0,0")}
              </p>
            )}
          </div>
        </AccordionSummary>
        <div className="flex flex-col px-4 pb-4">
          <div className="w-full flex flex-col">
            <h3 className="text-sm mb-1">Start Date</h3>
            <DateTimePicker
              value={startDate}
              onChange={(newStart) => setStartDate(newStart)}
              slotProps={{
                textField: {
                  size: "small",
                  variant: "outlined",
                  margin: "none",
                  InputProps: {
                    style: { fontSize: 13 },
                  },
                  InputLabelProps: {
                    style: { fontSize: 13 },
                  },
                  sx: {
                    backgroundColor: "white",
                    borderRadius: 1,
                    fontSize: 12,
                  },
                },
                openPickerIcon: {
                  sx: {
                    fontSize: 20,
                  },
                },
              }}
            />
          </div>
          <div className="flex flex-col mt-2">
            <h3 className="mb-1 text-sm">End Date</h3>
            <DateTimePicker
              value={endDate}
              onChange={(newEnd) => setEndDate(newEnd)}
              slotProps={{
                textField: {
                  size: "small",
                  variant: "outlined",
                  margin: "none",
                  InputProps: {
                    style: { fontSize: 13 },
                  },
                  InputLabelProps: {
                    style: { fontSize: 13 },
                  },
                  sx: {
                    backgroundColor: "white",
                    borderRadius: 1,
                    fontSize: 12,
                  },
                },
                openPickerIcon: {
                  sx: {
                    fontSize: 20,
                  },
                },
              }}
            />
          </div>
          <div className="flex flex-col mt-2">
            <h3 className="mb-1 text-sm">Actions</h3>
            <Select
              getOptionValue={(option) => option.id}
              getOptionLabel={(option) => option.name}
              styles={customStyles}
              name={"actionTypes"}
              placeholder={"Select an Action"}
              className="rounded-lg"
              onChange={handleNotificationTypeSelectChange}
              options={actionTypes}
              value={actionEvents}
              isSearchable
              isClearable
              isMulti
            />
          </div>
          <div className="flex flex-col w-full mt-2">
            <h3 className="mb-1 text-sm">Intent</h3>
            <Select
              id="intent"
              isClearable
              styles={customStyles}
              value={intent}
              getOptionLabel={(option) => option.label}
              onChange={handleIntentSelectChange}
              options={intentList}
              placeholder={"Select an Intent"}
            />
          </div>
          <div className="flex flex-col w-full mt-2">
            <h3 className="mb-1 text-sm">Camera</h3>
            <Select
              styles={customStyles}
              getOptionValue={(option) => option.uuid}
              getOptionLabel={(option) => option.name}
              name={"camera"}
              placeholder={"Select a Camera"}
              onChange={handleCameraSelectChange}
              options={cameraList}
              value={camera}
              isSearchable={true}
              isClearable={true}
            />
          </div>
          <div className="flex w-full mt-6 justify-between">
            <Button
              className="w-5/12"
              size="small"
              color="success"
              variant="outlined"
              disableElevation
              onClick={searchHandle}
              startIcon={<SearchIcon />}
            >
              Search
            </Button>
            {notifications.length >= eventsToLoadedForPage && (
              <Button
                className="w-5/12"
                size="small"
                color="success"
                variant="outlined"
                disableElevation
                onClick={() => loadMoreEvents()}
                endIcon={<ArrowForwardIosRoundedIcon />}
              >
                Next {eventsToLoadedForPage}
              </Button>
            )}
          </div>
        </div>
      </Accordion>

      {/* Events */}
      <div className="flex-grow bg-neutral-50 dark:bg-neutral-900 rounded-b-md overflow-auto p-6 shadow-sm space-y-4 h-1/2">
        {loadingNotification ? (
          <div className="flex justify-center">
            <svg width={0} height={0}>
              <defs>
                <linearGradient
                  id="circularProgressGradient"
                  x1="0%"
                  y1="0%"
                  x2="0%"
                  y2="100%"
                >
                  <stop offset="0%" stopColor="#84cc16" />
                  <stop offset="100%" stopColor="#22c55e" />
                </linearGradient>
              </defs>
            </svg>
            <CircularProgress
              variant="indeterminate"
              disableShrink
              sx={{
                "svg circle": { stroke: "url(#circularProgressGradient)" },
                animationDuration: "800ms",
              }}
              size={40}
              thickness={4}
            />
          </div>
        ) : notifications.length > 0 ? (
          notifications.map((event, index) => {
            return (
              <div
                className="flex flex-row p-2 shadow-sm border-l-4 border-stone-500 bg-white text-stone-600"
                key={index}
              >
                <div>
                  <IconButton
                    onClick={() => {
                      dispatch(setSelectedNotification(event.clip_id));
                      const clonEdevent = { ...event };
                      clonEdevent.selectedEvent = true;

                      props.handleNotificationClick(
                        clonEdevent,
                        getCurrentFilterModel()
                      );
                    }}
                  >
                    {event.selectedEvent ? (
                      <RadioButtonCheckedRoundedIcon
                        style={{ fontSize: "20px" }}
                      />
                    ) : (
                      <RadioButtonUncheckedRoundedIcon
                        style={{ fontSize: "20px" }}
                      />
                    )}
                  </IconButton>
                </div>

                {/* Event Information */}
                <div className=" text-sm py-2 w-full">
                  <div className="flex flex-row">
                    <strong>Camera:&nbsp;</strong>
                    {getCameraName(event.camera_id)}
                  </div>
                  <div className="flex flex-row">
                    <strong>Date:&nbsp;</strong>
                    {event.sent_date}
                  </div>
                  <div className="flex flex-col pb-4">
                    <strong>Actions Detected:&nbsp;</strong>
                    {event.actionsDetected}
                  </div>

                  {/* Intents */}
                  <div className="flex mt-0-important justify-between content-center">
                    <span className="flex">
                      {event.intentDetected
                        .split(", ")
                        .map((item, index, array) => {
                          const label =
                            index < array.length - 1
                              ? `${getIntentLabel(item)}`
                              : getIntentLabel(item);

                          if (label) {
                            const intentColor = getIntentLabelColor(item);
                            const icon =
                              label === "Analytic" ? (
                                <InsightsRoundedIcon fontSize="inherit" />
                              ) : (
                                <ImageSearchRoundedIcon fontSize="inherit" />
                              );

                            const result = (
                              <div
                                key={"intentsColors_" + index}
                                className="flex justify-center items-center bg mr-2 rounded-xl px-2 py-1"
                                style={{
                                  borderColor: `${intentColor}`,
                                  borderWidth: 1,
                                  fontSize: "12px",
                                  fontWeight: 600,
                                }}
                              >
                                <div
                                  style={{
                                    color: `${intentColor}`,
                                    fontSize: "18px",
                                  }}
                                >
                                  {icon}
                                </div>
                                <p
                                  key={"intents_" + index}
                                  style={{ color: `${intentColor}` }}
                                >
                                  {label}
                                </p>
                              </div>
                            );
                            return result;
                          }
                          return null;
                        })}
                    </span>
                  </div>
                </div>

                <div>
                  {/* Bookmark Button */}
                  <IconButton
                    onClick={() => {
                      dispatch(updateBookmark(event, !event.bookmark));
                      const eventCopy = { ...event };
                      eventCopy.bookmark = !event.bookmark;
                    }}
                  >
                    {event.bookmark ? (
                      <BookmarkRoundedIcon />
                    ) : (
                      <BookmarkBorderRoundedIcon />
                    )}
                  </IconButton>
                </div>
              </div>
            );
          })
        ) : (
          <div>
            <div className="flex w-full p-4 bg-blue-100 border-l-4 border-blue-500 shadow-sm">
              <span className="w-full text-center font-semibold text-blue-500">
                Select filters to search for events
              </span>
            </div>
          </div>
        )}
        <AlertMessageModal id="alertMessageModal" ref={alertMessageModal} />
        <ErrorMessageModal
          id="errorMessageModal"
          ref={errorMessageModal}
          Title={"Oops! Something Went Wrong!"}
        />
      </div>
    </div>
  );
});

export default EventList;
