import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import moment from "moment";

import { Table, Tabs, Empty } from "antd";

import notificationApi from "../api/notification";

export default function Bookmarks() {
  const cameraList = useSelector((state) => state.camera.cameraList).map(
    (camera) => ({
      camera_id: camera.mac,
      camera_name: camera.name,
    })
  );
  const [bookmarks, setBookmarks] = useState({});
  const [getBookmarks, setGetBookmarks] = useState(true);
  const [selectedCamera, setSelectedCamera] = useState(
    cameraList[0]?.camera_id
  );

  useEffect(() => {
    const fetchBookmarks = async () => {
      if (getBookmarks) {
        setGetBookmarks(false);

        const response = await notificationApi.getBookmarksByFilter();

        if (response.status) {
          let filteredBookmarks = {};

          cameraList.forEach((camera) => {
            filteredBookmarks[camera.camera_id] = [];
          });

          response.bookmarks.forEach((bookmark) => {
            let camera_id = bookmark.camera_id;

            if (camera_id in filteredBookmarks) {
              filteredBookmarks[camera_id].push(bookmark);
            }
          });

          setBookmarks(filteredBookmarks);
        }
      }
    };

    fetchBookmarks();
  }, [cameraList, getBookmarks]);

  const columns = [
    {
      title: "Timestamp",
      dataIndex: "timestamp",
      key: "timestamp",
      render: (value) =>
        moment(value, "MM/DD/YYYY HH:mm:ss").format("MMMM Do YYYY, h:mm A"),
    },
    {
      title: "Actions Detected",
      dataIndex: "actionsDetected",
      key: "actionsDetected",
    },
    {
      title: "Intent",
      dataIndex: "intentDetected",
      key: "intentDetected",
    },
    {
      title: "Description",
      dataIndex: "description",
      key: "description",
    },
  ];

  return (
    <div className="h-full w-full flex flex-col gap-10 px-6 py-8">
      <h1 className="page-header">Bookmarks</h1>

      <Tabs
        type="card"
        size="large"
        tabBarStyle={{ margin: 0, marginBottom: -1 }}
        items={cameraList.map((camera) => {
          const id = camera.camera_id;

          return {
            label: camera.camera_name,
            key: id,
            children: (
              <Table
                className="tables"
                columns={columns}
                rowKey="clip_id"
                pagination={false}
                dataSource={bookmarks[id] ? bookmarks[id] : null}
                locale={{
                  emptyText: (
                    <Empty
                      image={Empty.PRESENTED_IMAGE_SIMPLE}
                      description={`No bookmarks found for ${
                        cameraList.find(
                          (camera) => camera.camera_id === selectedCamera
                        ).camera_name
                      }.`}
                    />
                  ),
                }}
              />
            ),
          };
        })}
        activeKey={selectedCamera}
        onChange={(camera) => setSelectedCamera(camera)}
      />
    </div>
  );
}
