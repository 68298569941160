import React, {
  useState,
  useEffect,
  useRef,
  useImperativeHandle,
  forwardRef,
} from "react";
import { useDispatch } from "react-redux";

import MUITooltip, { tooltipClasses } from "@mui/material/Tooltip";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/material/styles";
import InfoIcon from "@mui/icons-material/Info";
import TransferWithinAStationOutlinedIcon from "@mui/icons-material/TransferWithinAStationOutlined";
import PointOfSaleOutlinedIcon from "@mui/icons-material/PointOfSaleOutlined";
import AddShoppingCartOutlinedIcon from "@mui/icons-material/AddShoppingCartOutlined";
import { ResponsiveContainer, Area, AreaChart } from "recharts";

import analyticsApi from "../../api/analytics";
import { openModal, setErrorMessage } from "../../store/error/errorAction";

const BadgesGraph = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const [aggregateData, setAggregateData] = useState([]);
  const [hiddenPercentChange, setHiddenPercentChange] = useState(false);
  const firstTime = useRef(true);
  const [currentDate, setcurrentDate] = useState(new Date());

  useEffect(() => {
    const createChart = async () => {
      const defaultStartDate = new Date();
      getChartData(defaultStartDate);
      setcurrentDate(defaultStartDate);
    };

    if (firstTime.current) {
      createChart();
      firstTime.current = false;
    }
  });

  useImperativeHandle(ref, () => ({
    updateChart,
  }));

  const updateChart = (startDate) => {
    getChartData(startDate);
    setcurrentDate(startDate);
  };

  const getChartData = async (startDate) => {
    let endDate;

    startDate.setHours(0, 0, 0, 0);

    if (isToday(startDate)) {
      endDate = new Date();
      endDate.setHours(endDate.getHours() + 1, 0, 0, 0);
      setHiddenPercentChange(true);
    } else {
      endDate = new Date(startDate);
      endDate.setDate(startDate.getDate() + 1);
      endDate.setHours(0, 0, 0, 0);
      setHiddenPercentChange(false);
    }

    var data = await analyticsApi.getBadgesGraphData(startDate, endDate);
    if (data.status) {
      setAggregateData(data.result);
    } else {
      openErrorModal(data.error);
    }
  };

  const openErrorModal = (message) => {
    dispatch(setErrorMessage(message));
    dispatch(openModal());
  };

  const getAverage = (data, key) => {
    const result = getSum(data, key) / data.length;

    return result.toFixed(2);
  };

  const getSum = (data, key) => {
    return data.reduce((acc, item) => acc + item[key], 0);
  };

  const getPercentageChange = (data, key) => {
    if (data.length === 0) return 0;

    const previusHour = data[data.length - 2][key];
    const lastHour = data[data.length - 1][key];

    if (previusHour === 0) return 0;

    return ((lastHour - previusHour) / previusHour) * 100;
  };

  const getPercentChangeElement = (data, key) => {
    const change = getPercentageChange(data, key);

    if (change > 0) {
      return (
        <div className="px-1 bg-transparent">
          <p className="text-xs font-semibold text-white">{`↑ ${change.toFixed(
            2
          )}%`}</p>
        </div>
      );
    } else if (change < 0) {
      return (
        <div className="px-1 bg-transparent">
          <p className="text-xs font-semibold text-white">{`↓ ${Math.abs(
            change
          ).toFixed(2)}%`}</p>
        </div>
      );
    } else {
      return (
        <div className="px-1 bg-transparent">
          <p className="text-xs font-semibold text-white">0%</p>
        </div>
      );
    }
  };

  const isToday = (date) => {
    const today = new Date();
    return (
      date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear()
    );
  };

  const HtmlTooltip = styled(({ className, ...props }) => (
    <MUITooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#525252",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
    },
  }));

  return (
    <div className="flex flex-row justify-between">
      <div className="w-full px-4 py-1 mr-6 bg-[linear-gradient(30deg,#a8e063,#56ab2f)] shadow-lg rounded-xl space-y-2 text-center">
        <div className="flex justify-between">
          <p className="text-left text-white text-sm">
            Date: {currentDate.toDateString()}
          </p>
          <HtmlTooltip
            title={
              <React.Fragment>
                <Typography color="white">Foot Traffic</Typography>
                <span className="text-white">
                  Displays the total number of people who have entered and
                  exited the location for the day with a graph of hourly counts.
                  <br />
                  Foot Traffic is the estimated number of people who enter and
                  exit this location. This is calculated by taking the greater
                  of the counts of entries and exits.
                </span>
              </React.Fragment>
            }
            placement="right"
          >
            <InfoIcon className="text-white dark:text-neutral-200" />
          </HtmlTooltip>
        </div>
        <h3 className="font-semibold text-white">
          <TransferWithinAStationOutlinedIcon
            sx={{ fontSize: 35 }}
            className="mx-1 shadow-lg rounded-2xl border-solid border-2 p-1"
          ></TransferWithinAStationOutlinedIcon>
          Foot Traffic
        </h3>
        <p className="font-semibold text-3xl text-white">
          {getSum(aggregateData, "Foot Traffic")}
        </p>
        <div className="w-full h-10">
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart width={300} height={100} data={aggregateData}>
              <Area
                type="monotone"
                dataKey="Foot Traffic"
                // dataKey="Pocketing"
                stroke="#16a34a"
                fill="#22c55e"
                strokeWidth={2}
                dot={false}
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
        {hiddenPercentChange && (
          <div className="flex flex-row w-full justify-end">
            {getPercentChangeElement(aggregateData, "Foot Traffic")}
          </div>
        )}
      </div>

      <div className="w-full px-4 py-1 mx-6 bg-[linear-gradient(30deg,#ff7f00,#ff0000)] shadow-lg rounded-xl space-y-2 text-center">
        <div className="flex justify-between">
          <p className="text-left text-white text-sm">
            Date: {currentDate.toDateString()}
          </p>
          <HtmlTooltip
            title={
              <React.Fragment>
                <Typography color="white"> Item Selection Rate</Typography>
                <span className="text-white">
                  Displays the average rate of item selection at this location
                  for the day.
                  <br />
                  Item selection rate is the estimated rate of item selection at
                  this location. This is calculated by dividing the count of
                  item selection events by the count of customer browsing
                  events. Defaults to 0 for no customer browsing events.
                </span>
              </React.Fragment>
            }
            placement="right"
          >
            <InfoIcon className="text-white dark:text-neutral-200" />
          </HtmlTooltip>
        </div>
        <h3 className="font-semibold text-white">
          <AddShoppingCartOutlinedIcon
            sx={{ fontSize: 35 }}
            className="mx-1 shadow-lg rounded-2xl border-solid border-2 p-1"
          ></AddShoppingCartOutlinedIcon>
          Item Selection Rate
        </h3>
        <p className="font-semibold text-3xl text-white">
          {getAverage(aggregateData, "Item Selection Rate")}%
        </p>
        <div className="w-full h-10">
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart width={800} height={400} data={aggregateData}>
              <Area
                type="monotone"
                dataKey="Item Selection Rate"
                stroke="#16a34a"
                fill="#22c55e"
                strokeWidth={2}
                dot={false}
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
        {hiddenPercentChange && (
          <div className="flex flex-row w-full justify-end">
            {getPercentChangeElement(aggregateData, "Item Selection Rate")}
          </div>
        )}
      </div>

      <div className="w-full px-4 py-1 ml-6 bg-[linear-gradient(30deg,#56ccf2,#2b5876)] shadow-lg rounded-xl space-y-2 text-center">
        <div className="flex justify-between">
          <p className="text-left text-white text-sm">
            Date: {currentDate.toDateString()}
          </p>
          <HtmlTooltip
            title={
              <React.Fragment>
                <Typography color="white">Sales Rate</Typography>
                <span className="text-white">
                  Displays the average rate of sales at this location for the
                  day with a graph of hourly rates.
                  <br />
                  Sales rate is the estimated rate of sales at this location.
                  This is calculated by dividing the count of sale events by
                  foot traffic. Defaults to 0 for no foot traffic.
                </span>
              </React.Fragment>
            }
            placement="right"
          >
            <InfoIcon className="text-white dark:text-neutral-200" />
          </HtmlTooltip>
        </div>
        <h3 className="font-semibold text-white">
          <PointOfSaleOutlinedIcon
            sx={{ fontSize: 35 }}
            className="mx-1 shadow-lg rounded-2xl border-solid border-2 p-1"
          ></PointOfSaleOutlinedIcon>
          Sales Rate
        </h3>
        <p className="font-semibold text-3xl text-white pb-1">
          {getAverage(aggregateData, "Sales Rate")}%
        </p>
        <div className="w-full h-10">
          <ResponsiveContainer width="100%" height="100%">
            <AreaChart width={300} height={100} data={aggregateData}>
              <Area
                type="monotone"
                dataKey="Sales Rate"
                stroke="#16a34a"
                fill="#22c55e"
                strokeWidth={2}
                dot={false}
              />
            </AreaChart>
          </ResponsiveContainer>
        </div>
        {hiddenPercentChange && (
          <div className="flex flex-row w-full justify-end">
            {getPercentChangeElement(aggregateData, "Sales Rate")}
          </div>
        )}
      </div>
    </div>
  );
});

export default BadgesGraph;
