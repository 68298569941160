import { Modal } from "antd";
import { WarningFilled } from "@ant-design/icons";

export default function WarningMessageModal({ message, open, onOk, onCancel }) {
  const modalTitle = (
    <div className="flex flex-row gap-2 text-red-400">
      <WarningFilled className="text-xl" />
      <div className="modal-title">Caution</div>
    </div>
  );

  return (
    <Modal
      classNames={{
        mask: "modal-mask",
        header: "modal-header",
        content: "modal-content",
        footer: "modal-footer",
      }}
      width={480}
      title={modalTitle}
      open={open}
      onOk={onOk}
      onCancel={onCancel}
      okText="Delete"
      okButtonProps={{
        block: true,
        danger: true,
        className: "m-0",
      }}
      cancelButtonProps={{ block: true }}
      destroyOnClose
      centered
      keyboard
    >
      <div className="flex px-8 pb-10 ">{message}</div>
    </Modal>
  );
}
