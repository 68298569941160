import React, {
  Fragment,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import CancelIcon from "@mui/icons-material/CancelOutlined";
import { useDispatch, useSelector } from "react-redux";
import Select from "react-select";
import { updateCamera } from "../../store/camera/cameraAction";
import DataSaverOnIcon from "@mui/icons-material/DataSaverOn";
import ClipLoader from "react-spinners/ClipLoader";
import {
  openModal as openErrorModal,
  setErrorMessage,
} from "../../store/error/errorAction";
import LiveVideoPlayer from "../LivePlayer";
import authService from "../../services/authService";

const PrevEditCameraModal = forwardRef((props, ref) => {
  const dispatch = useDispatch();
  const actionTypes = useSelector((state) => state.lookup.actionTypes);
  const cameraRoles = useSelector((state) => state.lookup.cameraRoles);
  const intentList = useSelector((state) => state.lookup.intentList);
  const [isOpen, setIsOpen] = useState(false);
  const [camera, setCamera] = useState();
  const [saving, setSavingCamera] = useState(false);
  const [areNewActionTypes, setAreNewActionTypes] = useState(false);
  const cancelButtonRef = useRef(null);
  const dialogRef = useRef(null);

  const closeModal = (close) => {
    if (close) {
      setIsOpen(false);
    }
  };

  const saveCamera = () => {
    if (validateCamera(camera)) {
      setSavingCamera(true);
      dispatch(updateCamera(camera, areNewActionTypes)).then((result) => {
        setSavingCamera(false);
        if (result) {
          setIsOpen(false);
        }
      });
    }
  };

  const validateCamera = (currentCamera) => {
    let errorMessage = "";

    if (!currentCamera.name) {
      errorMessage += "Name is required\n";
    }

    if (!currentCamera.role) {
      errorMessage += "Role is required\n";
    }

    if (!currentCamera.additional_context) {
      errorMessage += "Additional Context is required\n";
    }

    if (
      !currentCamera.action_types ||
      currentCamera?.action_types?.length === 0
    ) {
      errorMessage += "At least one action must be selected\n";
    }

    const result = errorMessage.length > 0;

    if (result) {
      showErrorModal(errorMessage);
    }

    return !result;
  };

  const openModal = (camera) => {
    setAreNewActionTypes(camera?.action_types?.length === 0);
    setCamera(camera);
    setIsOpen(true);
  };

  useImperativeHandle(ref, () => ({
    openModal,
  }));

  const showErrorModal = (message) => {
    dispatch(setErrorMessage(message));
    dispatch(openErrorModal());
  };

  const handleCameraRoleChange = (currentCamera, role) => {
    let updateCamera = { ...currentCamera };
    updateCamera.role = role;
    setCamera(updateCamera);
  };

  const handleCameraNameChange = (currentCamera, name) => {
    let updateCamera = { ...currentCamera };
    updateCamera.name = name;
    setCamera(updateCamera);
  };

  const handleAdditionalContextChange = (currentCamera, additional_context) => {
    let updateCamera = { ...currentCamera };
    updateCamera.additional_context = additional_context;
    setCamera(updateCamera);
  };

  const handleNotificationTypeSelectChange = (currentCamera, action_types) => {
    let updateCamera = { ...currentCamera };
    updateCamera.action_types = action_types;
    setCamera(updateCamera);
  };

  const customStyles = {
    placeholder: (provided) => ({
      ...provided,
      color: "black",
    }),
  };

  const handleIntentSelectChange = (action, intent) => {
    let updateCamera = { ...camera };

    const updateActions = updateCamera.action_types.map((currentAction) => {
      if (currentAction.name === action.name) {
        let updateAction = { ...currentAction };
        updateAction.intent = intent;

        return updateAction;
      }
      return currentAction;
    });

    updateCamera.action_types = updateActions;
    setCamera(updateCamera);
  };

  const getIntentLabelColor = (value) => {
    const intent = intentList.find((option) => option.value === value);

    return intent ? intent.color : "#FFFFFF";
  };

  return (
    <React.Fragment>
      <Transition.Root show={isOpen} as={Fragment}>
        <Dialog
          ref={dialogRef}
          as="div"
          className="relative z-10 your-dialog-content-class"
          initialFocus={cancelButtonRef}
          onClose={() => closeModal(false)}
          static={true}
        >
          <Transition.Child
            as={Fragment}
            enter="ease-out duration-300"
            enterFrom="opacity-0"
            enterTo="opacity-100"
            leave="ease-in duration-200"
            leaveFrom="opacity-100"
            leaveTo="opacity-0"
          >
            <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
          </Transition.Child>
          <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
            <div className="flex items-end justify-center p-4 text-center sm:items-center sm:p-0">
              <Transition.Child
                as={Fragment}
                enter="ease-out duration-300"
                enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
                enterTo="opacity-100 translate-y-0 sm:scale-100"
                leave="ease-in duration-200"
                leaveFrom="opacity-100 translate-y-0 sm:scale-100"
                leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              >
                <Dialog.Panel className="relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:w-full sm:max-w-3xl ">
                  <div className="bg-white rounded-b-lg rounded-t-lg">
                    <div className="sm:flex sm:items-start sm:p-2 sm:pb-2  bg-light-navy rounded-t-lg ">
                      <div className="w-[50%]">
                        <Dialog.Title
                          as="h3"
                          className="pt-0 text-base font-semibold leading-6 text-white"
                        >
                          Edit Camera
                        </Dialog.Title>
                      </div>
                      <div className="w-[50%] text-white flex justify-end  px-2">
                        <button
                          onClick={() => closeModal(false)}
                          className="font-semibold text-md hover:text-gray-200  "
                        >
                          X
                        </button>
                      </div>
                    </div>
                    <div id="body" className="m-1 p-1">
                      <div className="grid grid-cols-9 ">
                        <div className="col-span-3 p-2 m-1">
                          <label className="flex justify-end">Name: </label>
                        </div>
                        <div className="flex col-span-5 justify-start">
                          <input
                            maxLength={50}
                            type="text"
                            className="text-black p-1 border-gray-200 w-9/12 border border-solid border-1 mt-2 pb-2 "
                            defaultValue={camera?.name}
                            onChange={(e) =>
                              handleCameraNameChange(camera, e.target.value)
                            }
                          ></input>
                        </div>
                        <div className="col-span-3 p-2 m-1">
                          <label className="flex justify-end">Role: </label>
                        </div>
                        <div className="flex col-span-5 justify-start">
                          <select
                            value={camera?.role}
                            onChange={(e) =>
                              handleCameraRoleChange(camera, e.target.value)
                            }
                            className="text-black p-1 mt-1 mb-1 border border-solid border-1 border-gray-200 w-9/12"
                          >
                            <option value="" disabled>
                              Select One...
                            </option>
                            {cameraRoles.map((option) => (
                              <option key={option.value} value={option.value}>
                                {option.label}
                              </option>
                            ))}
                          </select>
                        </div>
                        <div className="col-span-3 p-2 m-1">
                          <label className="flex justify-end">
                            Additional Context:
                          </label>
                        </div>
                        <div className="flex col-span-5 justify-start">
                          <textarea
                            maxLength={100}
                            className="rounded text-justify border border-gray-200 px-2 py-2 w-9/12"
                            value={camera?.additional_context}
                            onChange={(e) =>
                              handleAdditionalContextChange(
                                camera,
                                e.target.value
                              )
                            }
                            rows={3}
                            cols={50}
                          />
                        </div>
                        <div className="col-span-3 p-2 m-1">
                          <label className="flex justify-end">Actions: </label>
                        </div>
                        <div className="flex col-span-6 justify-start">
                          <Select
                            getOptionValue={(option) => option.id}
                            getOptionLabel={(option) => option.name}
                            name={"selectActionTypes"}
                            placeholder={"Select an option"}
                            className="text-sm rounded mt-2 w-10/12 border border-gray-200 "
                            onChange={(e) => {
                              handleNotificationTypeSelectChange(camera, e);
                            }}
                            options={actionTypes}
                            value={camera?.action_types}
                            isSearchable={true}
                            styles={customStyles}
                            isMulti={true}
                          />
                        </div>
                        <div className="col-start-2 col-span-7 justify-start pb-2">
                          <div className="flex flex-row mt-2 text-white font-bold  border-black  bg-navy w-full">
                            <div className="basis-1/2 font-semibold  py-1  text-center">
                              Actions
                            </div>
                            <div className="basis-1/2 font-semibold  py-1  text-center">
                              Intent
                            </div>
                          </div>
                          {camera?.action_types?.length > 0 ? (
                            <div className="border">
                              {camera?.action_types?.map((action, i) => {
                                return (
                                  <div
                                    className={`${
                                      i % 2 === 0 ? "bg-gray-200" : "bg-white"
                                    } border-none border-black flex flex-row`}
                                    key={i}
                                  >
                                    <div className="text-start p-1 basis-1/2 ">
                                      {action.name}
                                    </div>
                                    <div className="text-start p-1 basis-1/2">
                                      <select
                                        className=" w-full border-gray-300 border py-2 pl-3 rounded-lg text-sm text-gray-900"
                                        value={action.intent}
                                        onChange={(e) => {
                                          handleIntentSelectChange(
                                            action,
                                            e.target.value
                                          );
                                        }}
                                        style={{
                                          color: getIntentLabelColor(
                                            action?.intent
                                          ),
                                        }}
                                      >
                                        <option
                                          value=""
                                          style={{ color: "hsl(0,0%,20%)" }}
                                        >
                                          Select an option
                                        </option>
                                        {intentList.map((option) => (
                                          <option
                                            key={option.value}
                                            value={option.value}
                                            style={{ color: option.color }}
                                          >
                                            {option.label}
                                          </option>
                                        ))}
                                      </select>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          ) : (
                            <div className="bg-gray-200 border-none border-black flex flex-row">
                              <div className="text-start p-1 basis-1/2">
                                &nbsp;
                              </div>
                              <div className="text-start p-1 basis-1/2">
                                &nbsp;
                              </div>
                            </div>
                          )}
                        </div>
                        <div className="col-start-4 col-span-3 items-center justify-center flex">
                          <div className=" ">
                            <LiveVideoPlayer
                              playerId={`player_${camera?.mac}`}
                              videoUrrl={`${authService.getUnitUrl()}media/live/${
                                camera?.mac
                              }/output.m3u8`}
                              cameraName={camera?.name}
                            />
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="bg-gray-50 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                      <button
                        type="button"
                        className=" inline-flex w-full rounded-md  text-white bg-gai-green  px-2 py-2 text-sm font-semibold shadow-sm ring-1 ring-gray-300 hover:bg-emerald-600 sm:w-auto mr-2 border border-gai-green"
                        onClick={() => saveCamera()}
                      >
                        {saving ? (
                          <ClipLoader
                            color={"#fff"}
                            loading={saving}
                            size={20}
                            aria-label="Loading Spinner"
                          />
                        ) : (
                          <DataSaverOnIcon
                            sx={{ fontSize: 20 }}
                          ></DataSaverOnIcon>
                        )}
                        &nbsp;Save
                      </button>
                      &nbsp;
                      <button
                        type="button"
                        className="inline-flex w-full rounded-md bg-white px-2 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-gray-300 hover:bg-gray-200 sm:w-auto mr-2 border border-red-500"
                        onClick={() => closeModal(true)}
                        ref={cancelButtonRef}
                      >
                        <CancelIcon
                          className="text-red-600"
                          sx={{ fontSize: 20 }}
                        ></CancelIcon>
                        &nbsp;Cancel
                      </button>
                    </div>
                  </div>
                </Dialog.Panel>
              </Transition.Child>
            </div>
          </div>
        </Dialog>
      </Transition.Root>
    </React.Fragment>
  );
});

export default PrevEditCameraModal;
