import { useEffect, useState, useCallback } from "react";
import { useSelector } from "react-redux";
import analyticsApi from "../../api/analytics";
import {
  SelectTimePeriodOptions,
  getAPIParameters,
} from "../../utils/analyticsDateUtils";
import {
  XAxis,
  YAxis,
  Line,
  Tooltip,
  ResponsiveContainer,
  LineChart,
  CartesianGrid,
  ReferenceLine,
  Legend,
  Customized,
} from "recharts";
import Select from "react-select";
import DashboardChartTooltip from "../DashboardChartTooltip";

const ActionsChangeChart = ({ selectedDate = new Date() }) => {
  const [chartData, setChartData] = useState([]);
  const [selectedActions, setSelectedActions] = useState([]);
  const [timePeriod, setTimePeriod] = useState(null);

  const [fetchChartData, setFetchChartData] = useState(false);
  const [actionsInChartData, setActionsInChartData] = useState([]);

  const actionTypes = useSelector((state) => state.lookup.actionTypes);

  const getAPIParametersCallback = useCallback(
    (period) => getAPIParameters(selectedDate)(period),
    [selectedDate]
  );

  useEffect(() => {
    const setHour = () => {
      selectedDate.setHours(23, 59, 59, 999);
    };

    const fetchData = async () => {
      if (fetchChartData && timePeriod) {
        setFetchChartData(false);
        setActionsInChartData(selectedActions.map((action) => action.label));

        const actionTypesKeys = selectedActions.map((action) => action.value);

        const params = getAPIParametersCallback(timePeriod.value);
        let response = await analyticsApi.getActionsChange(
          params.startDate,
          params.endDate,
          actionTypesKeys,
          params.timePeriod
        );

        if (response.status) {
          setChartData(response.data);
        }
      }
    };

    setHour();
    fetchData();
  }, [
    selectedDate,
    timePeriod,
    selectedActions,
    fetchChartData,
    getAPIParametersCallback,
  ]);

  const transformActionTypes = (originalObject) => {
    return originalObject.map((action) => ({
      value: action.id,
      label: action.name,
      intent: action.intent,
    }));
  };

  const handleActionTypesChange = (actions) => {
    // determine if new chart data is needed
    const newActions = actions.map((action) => action.label);
    const missingNewActionsData = newActions.some(
      (action) => !actionsInChartData.includes(action)
    );

    if (missingNewActionsData) {
      setFetchChartData(true);
    }

    // update selected actions
    setSelectedActions(actions);
  };

  const handleTimePeriodChange = (selectedTimePeriod) => {
    setTimePeriod(selectedTimePeriod);
  };

  const SelectCustomStyles = {
    control: (provided) => ({
      ...provided,
      cursor: "pointer",
      fontSize: "14px",
    }),
    multiValue: (provided, { data }) => ({
      ...provided,
      borderRadius: "12px",
      backgroundColor: getActionTypeColor(actionTypeMapping[data.label]),
    }),
    multiValueLabel: (provided) => ({
      ...provided,
      fontSize: "14px",
      fontWeight: "600",
      color: "#fff",
    }),
    option: (provided, state) => ({
      ...provided,
      cursor: "pointer",
      fontSize: "14px",
      fontWeight: "400",
      color: "#000",
      backgroundColor: state.isSelected ? "#86efac" : "#fff",
      "&:hover": { backgroundColor: state.isSelected ? "#86efac" : "#dcfce7" },
    }),
    multiValueRemove: (provided) => ({
      ...provided,
      borderRadius: "0px 12px 12px 0px",
    }),
  };

  const actionTypeMapping = {
    "Activity After Hours": "activityAfterHours",
    Bagging: "bagging",
    "Cash Theft": "cashTheft",
    "Check/Document Handling": "checkDocumentHandling",
    "Customer Browsing": "customerBrowsing",
    "Enter Store": "enterStore",
    Idle: "idle",
    "Item Picking": "itemPicking",
    "Leave Store": "leaveStore",
    "Mishandling Documents": "mishandlingDocuments",
    "Money Handling": "moneyHandling",
    "No Action": "noAction",
    Normal: "normal",
    "Pay/Checkout": "payCheckout",
    "Phone Engagement": "phoneEngagement",
    Pocketing: "pocketing",
    Shoplift: "shoplift",
    "Stocking Shelves": "stockingShelves",
    Undecided: "undecided",
  };

  const getActionTypeColor = (type) => {
    const colorMap = {
      pocketing: "#f87171", // red-400
      cashTheft: "#fb7185", // rose-400
      mishandlingDocuments: "#fbbf24", // amber-400
      shoplift: "#f472b6", // pink-400
      activityAfterHours: "#60a5fa", // blue-400
      bagging: "#34d399", // emerald-400
      checkDocumentHandling: "#a78bfa", // violet-400
      customerBrowsing: "#a3e635", // lime-400
      enterStore: "#facc15", // yellow-400
      idle: "#94a3b8", // slate-400
      itemPicking: "#fb923c", // orange-400
      leaveStore: "#38bdf8", // sky-400
      moneyHandling: "#22d3ee", // cyan-400
      noAction: "#d4d4d4", // neutral-300
      normal: "#9ca3af", // gray-400
      payCheckout: "#4ade80", // green-400
      phoneEngagement: "#c084fc", // purple-400
      stockingShelves: "#2dd4bf", // teal-400
      undecided: "#e879f9", // fuchsia-400
    };

    return colorMap[type] || "#000000";
  };

  const CustomTooltip = ({ active, payload, label }) => {
    if (active && payload && payload.length) {
      return (
        <div className="text-left rounded-md shadow-md bg-neutral-600 pl-3 pr-8 py-1">
          <p className="text-guardis-300">{`${label}`}</p>
          {payload.map((type) => (
            <p
              className="text-neutral-100 font-medium"
              key={`actionTypeLine-${type.dataKey}`}
            >{`${type.name}:  ${type.value > 0 ? "+" : ""}${type.value}%`}</p>
          ))}
        </div>
      );
    }

    return null;
  };

  const EmptyStateMessage = () => (
    <g>
      <text
        x="52%"
        y="40%"
        textAnchor="middle"
        dominantBaseline="middle"
        fill="#B0B0B0"
        fontSize="18px"
        fontWeight={300}
      >
        Please select actions and a time period.
      </text>
    </g>
  );

  return (
    <div className="card grid grid-cols-8 w-full pt-6 px-6 pb-10">
      <div className="col-span-8 flex justify-center m-2">
        <h2>Actions Comparison</h2>
        <DashboardChartTooltip
          className="pl-2"
          tooltipTitle="Compare Relative Changes of Selected Actions"
          tooltipContent="Percentages are calculated by comparing actions detected in the beginning of the selected time period to the actions detected in the rest of the time period."
        />
      </div>

      <div className="col-span-8 flex justify-start p-4">
        <div className="flex flex-grow items-center">
          <h3 className="px-5">Actions</h3>
          <Select
            className="w-full"
            styles={SelectCustomStyles}
            value={selectedActions}
            placeholder="Select Actions"
            options={transformActionTypes(actionTypes)}
            onChange={handleActionTypesChange}
            name="actionTypes"
            isSearchable={false}
            isMulti
          />
        </div>
        <div className="flex flex-none items-center w-80">
          <h3 className="px-5">Period</h3>
          <Select
            className="w-full pr-5"
            styles={SelectCustomStyles}
            value={timePeriod}
            placeholder="Select Time Period"
            options={SelectTimePeriodOptions}
            onChange={handleTimePeriodChange}
            name="timePeriod"
            isSearchable={false}
            isClearable={false}
          />
        </div>
      </div>

      <div className="col-span-8" align="center">
        <ResponsiveContainer height={400}>
          <LineChart
            data={chartData}
            margin={{ top: 10, bottom: 30, left: 55, right: 40 }}
          >
            <CartesianGrid strokeDasharray="4" strokeWidth={1} />

            {chartData.length === 0 && (
              <Customized component={<EmptyStateMessage />} />
            )}

            <XAxis
              axisLine={false}
              dataKey="time"
              tickMargin={20}
              tickLine={{ stroke: "#B0B0B0" }}
              interval="preserveStartEnd"
            />

            <YAxis
              axisLine={false}
              tickLine={{ stroke: "#B0B0B0" }}
              tickFormatter={(num) => (num > 0 ? `+${num}%` : `${num}%`)}
              tickMargin={20}
            />

            {chartData.length > 0 && (
              <Tooltip
                cursor={{
                  strokeWidth: 2,
                }}
                content={<CustomTooltip />}
              />
            )}

            <ReferenceLine y={0} stroke="#000" strokeWidth={2} />

            {selectedActions.map((type, index) => (
              <Line
                key={`line-${index}`}
                type="monotone"
                dataKey={actionTypeMapping[type.label]}
                name={type.label}
                stroke={getActionTypeColor(actionTypeMapping[type.label])}
                strokeWidth={2}
              />
            ))}

            <Legend
              wrapperStyle={{ paddingTop: 30 }}
              formatter={(value) => {
                return <span style={{ fontWeight: 500 }}>{value}</span>;
              }}
            />
          </LineChart>
        </ResponsiveContainer>
      </div>
    </div>
  );
};

export default ActionsChangeChart;
