import {
  Fragment,
  useRef,
  useState,
  forwardRef,
  useImperativeHandle,
} from "react";
import { Dialog, Transition } from "@headlessui/react";
import { InformationCircleIcon } from "@heroicons/react/24/outline";
import Select from "react-select";
import { useSelector } from "react-redux";

const UserFeedbackModal = forwardRef((props, ref) => {
  const actionTypes = useSelector((state) => state.lookup.actionTypes);

  const [isOpen, setIsOpen] = useState(false);
  const [actions, setActions] = useState();
  const [feedbackDescription, setFeedbackDescription] = useState();
  const [detectedActionEvents, setDetectedActionEvents] = useState();
  const [detectedDescription, setDetectedDescription] = useState();
  const cancelButtonRef = useRef(null);
  const [showValiation, setShowValiation] = useState(false);

  const dialogRef = useRef(null);

  const closeModal = (result) => {
    if (result) {
      let actionSelectedByUser = actions.filter((action) => {
        return !detectedActionEvents.find(
          (actionDetected) => action.name === actionDetected.name
        );
      });

      if (
        detectedDescription === feedbackDescription &&
        actionSelectedByUser.length === 0
      ) {
        setShowValiation(true);
        return;
      } else {
        setShowValiation(false);

        props.SaveFeedbackCallback(result, actions, feedbackDescription);
      }
    }
    setIsOpen(false);
  };

  const openModal = (event) => {
    if (event) {
      const detectedActionEvents = event.actionEvents;
      setDetectedActionEvents(detectedActionEvents);
      setShowValiation(false);
      setFeedbackDescription(event.description);
      setDetectedDescription(event.description);

      setIsOpen(true);
      setActions(detectedActionEvents);
    }
  };

  useImperativeHandle(ref, () => ({
    openModal,
  }));

  const handleNotificationTypeSelectChange = (selectedOption) => {
    setActions(selectedOption);
  };

  const getOptionTextColor = (option, state) => {
    // Set text color based on the group property
    if (option.label === "CRITICAL") {
      return "#FF0000";
    }

    if (option.label === "WARNING") {
      return "#FF7518";
    }

    if (option.label === "INFORMATION") {
      return "#30ac64";
    }
    // Default text color for other options
    return "black";
  };

  const customStyles = {
    placeholder: (provided) => ({
      ...provided,
      color: "black",
    }),
    groupHeading: (provided, state) => ({
      ...provided,
      color: getOptionTextColor(state.data),
      fontSize: "12px",
      textTransform: "capitalize!import",
    }),
  };

  const handleChange = (event) => {
    setFeedbackDescription(event.target.value);
  };

  return (
    <Transition.Root show={isOpen} as={Fragment}>
      <Dialog
        ref={dialogRef}
        as="div"
        className="relative z-10 your-dialog-content-class"
        initialFocus={cancelButtonRef}
        onClose={closeModal}
        static={true}
      >
        <Transition.Child
          as={Fragment}
          enter="ease-out duration-300"
          enterFrom="opacity-0"
          enterTo="opacity-100"
          leave="ease-in duration-200"
          leaveFrom="opacity-100"
          leaveTo="opacity-0"
        >
          <div className="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity" />
        </Transition.Child>
        <div className="fixed inset-0 z-10 w-screen overflow-y-auto">
          <div className="flex min-h-full items-end justify-center p-4 text-center sm:items-center sm:p-0">
            <Transition.Child
              as={Fragment}
              enter="ease-out duration-300"
              enterFrom="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
              enterTo="opacity-100 translate-y-0 sm:scale-100"
              leave="ease-in duration-200"
              leaveFrom="opacity-100 translate-y-0 sm:scale-100"
              leaveTo="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95"
            >
              <Dialog.Panel className="relative transform rounded-lg bg-white text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-lg">
                <div className="bg-white m-1 sm:p-6 sm:pb-4">
                  <div className="sm:flex sm:items-start">
                    <div className="mx-auto flex flex-shrink-0 items-center justify-center rounded-full bg-gray-300 sm:mx-0 sm:h-8 sm:w-8">
                      <InformationCircleIcon
                        className="h-6 w-6 text-red-600"
                        aria-hidden="true"
                      />
                    </div>
                    <div className="text-center sm:ml-2 sm:mt-0 sm:text-left">
                      <Dialog.Title
                        as="h3"
                        className="pt-0 text-base font-semibold leading-6 text-gray-900"
                      >
                        Help us to improve it!
                      </Dialog.Title>
                    </div>
                  </div>
                  <div className="mt-3">
                    <p className="text-sm text-gray-500">
                      What did you see in the event?
                    </p>
                    <div className="inset-0 items-center justify-center">
                      <div className="p-2">
                        <Select
                          getOptionValue={(option) => option.id}
                          getOptionLabel={(option) => option.name}
                          name={"actionTypes"}
                          placeholder={"Select an option"}
                          className="text-sm  rounded-lg"
                          onChange={handleNotificationTypeSelectChange}
                          options={actionTypes}
                          value={actions}
                          isSearchable={true}
                          styles={customStyles}
                          isMulti={true}
                        />
                      </div>
                      <div className="flex mt-6 space-x-2 space-y-2 justify-center">
                        <textarea
                          className="rounded text-justify border border-green px-2 py-2"
                          value={feedbackDescription}
                          onChange={handleChange}
                          rows={4}
                          cols={53}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {showValiation && (
                  <div className="bg-gray-50 ">
                    <span
                      className="mx-12 mt-4 text-sm  bg-red-100 border-l-4 border-red-500 text-red-700 p-2"
                      role="alert"
                    >
                      Please select different values than values detected
                      previously!
                    </span>
                  </div>
                )}
                <div className="bg-gray-50 my-3 mx-1 px-4 py-3 sm:flex sm:flex-row-reverse sm:px-6">
                  <button
                    type="button"
                    className="inline-flex w-full justify-center rounded-md bg-navy px-3 py-2 text-sm font-semibold text-white shadow-sm hover:bg-light-navy sm:ml-3 sm:w-auto"
                    onClick={() => closeModal(true)}
                  >
                    OK
                  </button>
                  <button
                    type="button"
                    className="mt-3 inline-flex w-full justify-center rounded-md bg-white px-3 py-2 text-sm font-semibold text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 hover:bg-gray-50 sm:mt-0 sm:w-auto"
                    onClick={() => closeModal(false)}
                    ref={cancelButtonRef}
                  >
                    Cancel
                  </button>
                </div>
              </Dialog.Panel>
            </Transition.Child>
          </div>
        </div>
      </Dialog>
    </Transition.Root>
  );
});

export default UserFeedbackModal;
