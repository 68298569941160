import { useNavigate } from "react-router-dom";
import { useEffect,useCallback } from "react";
import { useDispatch } from "react-redux";
import authService from "../services/authService";

export default function SignOut() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const resetState =useCallback( () => {
    dispatch({ type: "RESET" }); //calls the root reducer with RESET as action!
  },[dispatch]);

  useEffect(() => {
    resetState();
    authService.signOut();
    localStorage.clear();
    return navigate("/log-in");
  }, [navigate, resetState]);

  return <div>Sign out successful</div>;
}
